import { useState } from "react";
import dateOfBirthDateParser from "utils/dateOfBirthDateParser";
import utils from "modules/commons/utils";
import * as labelPrinting from "services/label-printing";

function usePrintLabel({ requisitionNumber, form }) {
  const [isLoading, setIsLoading] = useState(false);

  const printLabel = async () => {
    try {
      setIsLoading(true);
      const model = utils.form.formToModel(form);
      await print(requisitionNumber, model, model.test_type_name);
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    printLabel,
  };
}

async function print(requisitionNumber, model, testTypeName) {
  try {
    const printer = labelPrinting.factory();

    await printer.printModel({
      requisitionNumber,
      city: model.patient_city,
      state: model.patient_state,
      date: dateOfBirthDateParser({ date: model.patient_dob }),
      firstName: model.patient_first_name,
      lastName: model.patient_last_name,
      info: "MDR-NYC",
      testTypeName: testTypeName,
    });
  } catch (error) {
    alert(error);
  }
}

export default usePrintLabel;
