import React, { useState } from 'react';

import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiText,
} from '@elastic/eui';

import {
    useAxios
} from 'modules/commons/hooks';

import {
    Show,
    ScanTextField,
} from 'modules/commons/components';

function SubmitModal({
    locationId,
    requisitionNumber,
    isVisible = false,
    onClose = () => { },
    onSuccess = () => { },
}) {
    const [_requisitionNumber, _setRequisitionNumber] = useState('');

    const [isAddOrderLoading, addOrder] = useAxios({
        onSuccess: () => {
            onClose();
            onSuccess();
        },
        onFailure: (error) => alert(error),
    });

    const addOrderRequest = (value) => {

        if (_requisitionNumber === requisitionNumber) {
            addOrder({
                method: 'post',
                url: `/batch/current/${locationId}`,
                data: { requisitionNumber },
            });
        } else {
            alert("Invalid Requisition Number");
        }

    };

    return (
        <div>
            <Show when={isVisible}>
                <EuiOverlayMask onClick={onClose}>
                    <EuiModal onClose={onClose}>
                        <EuiModalHeader>
                            <EuiModalHeaderTitle>Order Validation</EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiText>
                                        Please give the test kit to the swabber. After the swabber completes the test they should return the tube to you and scan the barcode.
                                    </EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>

                            <EuiFlexGroup alignItems="flexEnd">
                                <EuiFlexItem>
                                    <EuiFormRow fullWidth label="Requistion Number">
                                        <ScanTextField
                                            isLoading={isAddOrderLoading}
                                            onPressEnter={addOrderRequest}
                                            value={_requisitionNumber}
                                            setValue={_setRequisitionNumber}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiModalBody>

                        <EuiModalFooter>
                            <EuiButton
                                onClick={onClose}
                            >
                                <span>Close</span>
                            </EuiButton>
                            <EuiButton
                                fill
                                onClick={addOrderRequest}
                                isLoading={isAddOrderLoading}
                                disabled={!requisitionNumber || !locationId}
                            >
                                <span>Submit</span>
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiModal>
                </EuiOverlayMask>
            </Show>
        </div>
    );
}

export default SubmitModal;