import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { EuiIcon, EuiSideNav } from "@elastic/eui";

import {
  faCog,
  faPowerOff,
  faVial,
  faClipboardCheck,
  faSyringe,
  faHome,
  faPills,
} from "@fortawesome/free-solid-svg-icons";

import { useAuth0 } from "@auth0/auth0-react";
import { useAuthorize } from "modules/commons/hooks";
import { MenuContext } from "./MenuContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideNav = () => {
  const history = useHistory();
  const [context, setContext] = useContext(MenuContext);

  const [selectedItemName, setSelectedItem] = useState("");

  const { logout } = useAuth0();

  const auth = useAuthorize();

  const logoutWithRedirect = () => {
    return logout({
      returnTo: window.location.origin,
    });
  };

  const toggleOpenOnMobile = () => {
    setContext((context) => {
      return {
        ...context,
        isOpen: context.isOpen,
        isOpenOnMobile: !context.isOpenOnMobile,
        isDocked: true,
      };
    });
  };

  const selectItem = (name, to) => {
    setSelectedItem(name);
    // Push the route to the history.
    history.push(to);
  };

  const createItem = (name, data = {}) => {
    const key = data.key ?? name;
    return {
      id: key,
      name,
      isSelected: selectedItemName === key,
      onClick: () => selectItem(key, data.to),
      ...data,
    };
  };

  const ordersSubMenu = [
    createItem("New Order", {
      key: "orders",
      icon: <EuiIcon type="plus" size="m" />,
      to: "/orders",
    }),
  ];

  if (auth.isAtHomeTesting) {
    ordersSubMenu.push(
      createItem("At Home Testing", {
        icon: <FontAwesomeIcon icon={faVial} />,
        to: "/home-testing",
      })
    );
  }

  if (auth.isBatchSubmitToLab || auth.isBatchFinalize) {
    ordersSubMenu.push(
      createItem("Batch", {
        icon: <FontAwesomeIcon icon={faClipboardCheck} />,
        to: "/batch",
      })
    );
  }

  if (auth.isRapidResults) {
    ordersSubMenu.push(
      createItem("Rapid Results", {
        icon: <EuiIcon type="beaker" size="m" />,
        to: "/rapid-results",
      })
    );
  }

  if (auth.isReporter) {
    ordersSubMenu.push(
      createItem("Reports", {
        key: "orders-reports",
        icon: <EuiIcon type="reportingApp" size="m" />,
        to: "/orders-reports",
      })
    );
  }

  const sideNav = [
    createItem("Dashboard", {
      icon: <FontAwesomeIcon icon={faHome} />,
      to: "/",
    }),
    createItem("Orders", {
      key: "orders",
      icon: <FontAwesomeIcon icon={faVial} />,
      to: "/orders",
      items: ordersSubMenu,
    }),
  ];

  if (auth.isWritePrescriptions) {
    sideNav.push(
      createItem("Prescriptions", {
        icon: <FontAwesomeIcon icon={faPills} />,
        to: "/prescriptions",
      })
    );
  }

  if (auth.canBulkPrint) {
    sideNav.push(
      createItem("Bulk Print Labels", {
        key: "bulk-print-labels",
        icon: <EuiIcon type="plus" size="m" />,
        to: "/bulk-print-labels",
      })
    );
  }

  if (auth.isVaccine) {
    sideNav.push(
      createItem("Vaccine", {
        icon: <FontAwesomeIcon icon={faSyringe} />,
        to: "/vaccine",
        items: [
          createItem("Appointments", {
            icon: <EuiIcon type="recentlyViewedApp" size="m" />,
            to: "/appointments",
          }),
          createItem("Vaccine Administration", {
            icon: <FontAwesomeIcon icon={faSyringe} />,
            to: "/vaccine",
          }),
          createItem("Reports", {
            icon: <EuiIcon type="reportingApp" size="m" />,
            to: "/vaccines-reports",
          }),
        ],
      })
    );
  }

  sideNav.push(
    createItem("Settings", {
      icon: <FontAwesomeIcon icon={faCog} />,
      to: "/settings",
    })
  );

  sideNav.push(
    createItem("Log Out", {
      icon: <FontAwesomeIcon icon={faPowerOff} />,
      onClick: logoutWithRedirect,
    })
  );

  return (
    <EuiSideNav
      mobileTitle={<>Menu</>}
      toggleOpenOnMobile={toggleOpenOnMobile}
      isOpenOnMobile={context.isOpenOnMobile}
      items={sideNav}></EuiSideNav>
  );
};

export default SideNav;
