import { useEffect } from "react";
import { DateTime } from "luxon";

import useOrders from "modules/orders-reports/useOrders";
import odataFilter from "modules/orders-reports/odataFilter";
import { usePagination } from "modules/commons/hooks";

function useOrdersWithPreregistration({ preregistrationId }) {
  const now = DateTime.now().setZone("America/New_York");
  const { pagination } = usePagination();
  const odata = odataFilter({
    preregistrationId: preregistrationId,
    testTimeFrom: now.startOf("day").toFormat("MM/dd/yyyy"),
    testTimeTo: now.plus({ days: 1 }).toFormat("MM/dd/yyyy"),
  });

  const { state, loadData } = useOrders({
    pagination,
    odata,
  });

  useEffect(() => {
    if (preregistrationId > 0) loadData({ pageIndex: 0, pageSize: 10 });
  }, [preregistrationId]);

  return {
    ordersForPreRegistration: state,
    hasOrderForToday: state.items?.length > 0,
  };
}

export default useOrdersWithPreregistration;
