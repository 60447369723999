import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";
import api from "networking";
StylesManager.applyTheme("defaultV2");

const uploadFiles = async (survey, options) => {
  const response = await api.Files.uploadFiles("survey", options.files);

  options.callback(
    "success",
    options.files.map((file) => {
      return {
        file: file,
        content: response.data.files[file.name],
      };
    })
  );
};

const downloadFile = async (survey, options) => {
  var resposne = await api.Files.downloadFile(options.content);

  const file = new File([resposne.data], options.fileValue.name, {
    type: options.fileValue.type,
  });

  var reader = new FileReader();
  reader.onload = function (e) {
    options.callback("success", e.target.result);
  };

  reader.readAsDataURL(file);
};

function SurveyJsQuestionnaireField({
  state = {},
  questionnaire = [],
  onChange = () => {},
  mode = "edit",
}) {
  if (!questionnaire) return null;

  const survey = new Model(questionnaire);
  survey.data = state.value;

  if (state.value) {
    if (state.value.metadata_current_page_number) {
      survey.currentPageNo = state.value?.metadata_current_page_number;
    }

    if (
      state.value.metadata_completed === true ||
      (state.value.metadata_completed === undefined &&
        state.value.metadata_current_page_number === undefined)
    ) {
      mode = "display";
    }
  }

  const sendDataToServer = (survey) => {
    if (mode != "edit") return;

    const data = {
      ...survey.data,
      metadata_current_page_number: survey.currentPageNo,
    };
    onChange(data);
  };

  const sendCompletedDataToServer = (survey) => {
    const data = {
      ...survey.data,
      metadata_completed: true,
      metadata_current_page_number: survey.currentPageNo,
    };
    onChange(data);
  };

  return (
    <div className="Questionnaire">
      <Survey
        model={survey}
        mode={mode}
        onUploadFiles={uploadFiles}
        onDownloadFile={downloadFile}
        onComplete={sendCompletedDataToServer}
        onCurrentPageChanged={sendDataToServer}
      />
    </div>
  );
}

export default SurveyJsQuestionnaireField;
