import { useState } from "react";

const PRINTER_KEY = "SETTINGS";

export type SettingMap = {
  tenant?: string;
  printerType?: "dymo" | "qz";
  printerLabel: string;
  printerName?: string;
};

export const getCachedSettings = () => {
  const str = localStorage.getItem(PRINTER_KEY);
  return (JSON.parse(str) || {}) as SettingMap;
};

export function useSettings() {
  const [_settings, _setSettings] = useState(() => getCachedSettings());

  const setSetting = (key: keyof SettingMap, value: string) => {
    setSettings({ ..._settings, [key]: value });
  };

  const setSettings = (settings: SettingMap) => {
    const delta = { ..._settings, ...settings };
    _setSettings(delta);

    const json = JSON.stringify(delta);
    localStorage.setItem(PRINTER_KEY, json);
  };

  return { settings: _settings, setSetting };
}
