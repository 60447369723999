import OData from 'utils/odataQuery';

function odataFilter({
    firstName,
    lastName,
    //
    phone,
    locationId,
    locationType,

    dob,
    firstDoseDate,
    secondDoseDate,
    scheduledSecondDoseDate,

    orderBy = [],
}) {

    const odata = (pagination) => {
        const odata = new OData();

        const query = odata
            .orderBy(orderBy)
            .pagination({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize
            })
            .contains({
                name: 'firstName',
                value: firstName,
            })
            .contains({
                name: 'lastName',
                value: lastName,
            })
            .contains({
                name: 'phone',
                value: phone,
            })
            .equals({
                name: 'location/id',
                value: locationId,
            })
            .equals({
                name: 'location/locationType',
                value: locationType,
            })
            .dateEquals({
                name: "dob",
                value: dob,
            })
            .dateEquals({
                name: "firstDoseDate",
                value: firstDoseDate,
            })
            .dateEquals({
                name: "secondDoseDate",
                value: secondDoseDate,
            })
            .dateEquals({
                name: "scheduledSecondDoseDate",
                value: scheduledSecondDoseDate,
            })
            .build();

        return query;
    }

    return odata

}

export default odataFilter;