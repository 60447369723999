import axios from "utils/axios";

export async function ScheduleUpcomingAppointments({
  locationId,
  appointmentTime,
  requisitionNumber,
}) {
  const response = await axios({
    method: "POST",
    url: `/UpcomingAppointments/Schedule`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      appointmentTime,
      locationId,
      requisitionNumber,
    },
  });

  return response;
}
