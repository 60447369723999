import { useState } from 'react';

function useShowModal(value = false) {
    const [isVisible, setIsVisible] = useState(value);
    const toggleVisable = () => setIsVisible(!isVisible);
    const closeModal = () => setIsVisible(false);
    const openModal = () => setIsVisible(true);

    return {
        isVisible,
        toggleVisable,
        closeModal,
        openModal,
    }
}

export default useShowModal;