class PubSub {
    constructor() {
        if (PubSub.instance) {
            return PubSub.instance;
        }
        this.listeners = {};
        Object.freeze(this);
        PubSub.instance = this;
    }

    subscribe(type, callback) {
        if (!this.listeners[type]) {
            this.listeners[type] = [];
        }

        const index = this.listenerIndex(type, callback);

        if (index > 0) {
            return;
        }

        this.listeners[type].push(callback);

        return this;
    }

    publish(type, ...args) {
        if (!this.listeners[type]) {
            return;
        }
        this.listeners[type].forEach((callback) => callback(...args));
    }

    unsubcribe(type, callback) {
        if (!this.listeners[type]) {
            return;
        }

        const index = this.listenerIndex(type, callback);
        this.listeners[type].splice(index, 1);
    }

    listenerIndex(type, callback) {
        const index = this.listeners[type].findIndex((listener) => listener === callback);

        return index;
    }
}

export default new PubSub();
