import {
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
  EuiTab,
  EuiTabs,
} from "@elastic/eui";
import { useState } from "react";

import {
  Patient,
  Address,
  General,
  Payment,
  Guardian,
  AdditionalInformation,
} from "./form";

function Form({ selectedTabId }) {
  switch (selectedTabId) {
    case "1":
      return <Patient />;
    case "2":
      return <Address />;
    case "3":
      return <Payment />;
    case "4":
      return <AdditionalInformation />;
    default:
      return (
        <>
          <Patient />
          <EuiHorizontalRule />
          <Address />
          <EuiHorizontalRule />
          <Guardian />
          <EuiHorizontalRule />
          <Payment />
          <EuiHorizontalRule />
          <AdditionalInformation />
        </>
      );
  }
}
const tabs = [
  {
    id: "0",
    name: "All",
  },
  {
    id: "1",
    name: "Patient",
  },
  {
    id: "2",
    name: "Address",
  },
  {
    id: "3",
    name: "Payment (Insurance...)",
  },
  {
    id: "4",
    name: "Additional Information",
  },
];
function OrderForm({ testTypes }) {
  const [selectedTabId, setSelectedTabId] = useState("0");
  const renderTabs = tabs.map((tab, index) => (
    <EuiTab
      onClick={() => setSelectedTabId(tab.id)}
      isSelected={tab.id === selectedTabId}
      key={index}>
      {tab.name}
    </EuiTab>
  ));

  return (
    <>
      <EuiPanel>
        <General testTypes={testTypes} />
      </EuiPanel>
      <EuiSpacer size="l" />

      <EuiTabs bottomBorder={true}>{renderTabs}</EuiTabs>
      <EuiSpacer size="l" />
      <Form selectedTabId={selectedTabId} />
    </>
  );
}

export default OrderForm;
