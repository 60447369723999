import axios from "axios";

import { getCachedSettings } from "modules/commons/hooks/useSettings";

export default async function getLabelContent() {
  const settings = getCachedSettings();
  let printerLabel = settings.printerLabel;

  if (!printerLabel) printerLabel = "dymo";

  const { default: label } = await import(`./label-${printerLabel}.zpl`);

  const response = await axios.get(label);

  return response.data;
}
