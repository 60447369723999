import { EuiFieldText } from "@elastic/eui";

function ScanTextField({
  inputRef,
  isLoading = false,
  value = "",
  setValue = () => {},
  onPressEnter = () => {},
}) {
  const onKeyPress = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    setValue("");
    onPressEnter(value);
  };

  const onChange = (e) => setValue(e.target.value);

  return (
    <EuiFieldText
      inputRef={inputRef}
      fullWidth={true}
      placeholder="Scan requisition number"
      prepend="QR Code"
      isLoading={isLoading}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  );
}

export default ScanTextField;
