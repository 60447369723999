import { FhirJsonForm, FhirJsonResp } from "fhirformjs";
import Form from "@rjsf/core";

import './FhirQuestionnaireField.css';

function FhirQuestionnaireField({ questionnaire }) {
  if (!questionnaire) return null;
  const resp = FhirJsonForm(questionnaire);
  console.log(JSON.stringify(resp));
  let formData = {};
  let respData = {};

  function handleSubmit(data) {
    respData = FhirJsonResp(resp.model, data, resp.schema);
    console.log(JSON.stringify(respData));
  }

  return (
    <div className="Questionnaire">
      <header className="Questionnaire-header">
        <Form
          schema={resp.schema}
          uiSchema={resp.uiSchema}
          formData={formData}
          onSubmit={(e) => handleSubmit(e.formData)}>
          <div></div>
        </Form>
      </header>
    </div>
  );
}

export default FhirQuestionnaireField;
