import React, { useContext, useEffect, useState } from 'react';

import {
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiButton,
    EuiTitle,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonEmpty,
} from '@elastic/eui';

import {
    Show,
} from 'modules/commons/components';

import {
    useShowModal,
} from 'modules/commons/hooks';

import utils from 'modules/commons/utils';

import dateParser from 'utils/dateParser';

import Form from './form';
import FormContext from './form/FormContext';
import useCreateVaccine from './useCreateVaccine';

export function VaccineDetailModal({ title = "", vaccine = null }) {
    const form = useContext(FormContext);
    const { closeModal, openModal, isVisible } = useShowModal();

    const _openModal = () => {
        utils.form.resetFields(form);
        openModal();
    };

    const _closeModal = () => {
        utils.form.resetFields(form);
        closeModal();
    };

    return (
        <>
            <EuiButtonEmpty
                onClick={_openModal}
            >
                <span>{title}</span>
            </EuiButtonEmpty>
            <Show when={isVisible}>
                <VaccineModal
                    vaccine={vaccine}
                    closeModal={_closeModal}
                />
            </Show>

        </>
    );
};

export function NewVaccineModal({ disabled = true }) {
    const form = useContext(FormContext);
    const { closeModal, openModal, isVisible } = useShowModal();

    const _openModal = () => {
        utils.form.resetFields(form);
        openModal();
    };

    const _closeModal = () => {
        utils.form.resetFields(form);
        closeModal();
    };

    return (
        <>
            <EuiButton
                fill
                disabled={disabled}
                onClick={_openModal}
            >
                <span>New Vaccine</span>
            </EuiButton>
            <Show when={isVisible}>
                <VaccineModal
                    closeModal={_closeModal}
                />
            </Show>
        </>
    );
};

function VaccineModal({ vaccine = null, closeModal }) {
    const form = useContext(FormContext);
    const [location] = form.location;
    const [isDetail, setIsDetail] = useState(false);

    const {
        state: createState,
        createVaccine,
    } = useCreateVaccine({
        form,
        location,
        onSuccess: () => {
            setIsDetail(true);
            utils.form.disableFields(form);
        },
    });

    useEffect(() => {
        if (!vaccine?.id) { return; }
        setIsDetail(true);
        vaccineToForm({ vaccine, form });
    }, [vaccine]);

    return (
        <EuiFlyout
            ownFocus
            onClose={closeModal}
            aria-labelledby="flyoutTitle">

            <EuiFlyoutHeader hasBorder>
                <EuiFlexGroup alignItems="center">
                    <EuiFlexItem>
                        <EuiTitle size="m">
                            <h2 id="flyoutTitle">{isDetail ? 'Vaccine Detail' : 'New Vaccine'}</h2>
                        </EuiTitle>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlyoutHeader>

            <EuiFlyoutBody>
                <Form />
            </EuiFlyoutBody>

            <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent="flexEnd">

                    <Show when={!isDetail}>
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                fill
                                isLoading={createState.isLoading}
                                onClick={createVaccine}
                                color="primary"
                            >
                                <span>Save</span>
                            </EuiButton>
                        </EuiFlexItem>
                    </Show>

                </EuiFlexGroup>
            </EuiFlyoutFooter>
        </EuiFlyout>
    );
}

function vaccineToForm({ vaccine, form }) {

    const dob = dateParser({ date: vaccine.dob });
    const firstDoseDate = dateParser({ date: vaccine.firstDoseDate });
    const secondDoseDate = dateParser({ date: vaccine.secondDoseDate });
    const vaccineExpirationDate = dateParser({ date: vaccine.vaccineExpirationDate });
    const scheduledSecondDoseDate = dateParser({ date: vaccine.scheduledSecondDoseDate });

    /* ================================ General ================================ */
    form.firstDoseDate.onChange(firstDoseDate);
    form.secondDoseDate.onChange(secondDoseDate);
    form.scheduledSecondDoseDate.onChange(scheduledSecondDoseDate);
    /* ================================  vaccine ================================ */
    form.vaccineExpirationDate.onChange(vaccineExpirationDate);
    form.vaccineLotNumber.onChange(vaccine.vaccineLotNumber);
    form.vaccineAdministeringSite.onChange(vaccine.vaccineAdministeringSite);
    /* ================================  insurance ================================ */
    form.policyNumber.onChange(vaccine.policyNumber);
    form.insuranceCompany.onChange(vaccine.insuranceCompany);
    /* ================================ Patient ================================ */
    form.firstName.onChange(vaccine.firstName);
    form.lastName.onChange(vaccine.lastName);
    form.dob.onChange(dob);
    form.gender.onChange(vaccine.gender);
    form.ethnicity.onChange(vaccine.ethnicity);
    form.race.onChange(vaccine.race);
    /* ================================ Contact ================================ */
    form.phone.onChange(vaccine.phone);
    form.phoneType.onChange(vaccine.phoneType);
    form.email.onChange(vaccine.email);
    /* ================================ Address ================================ */
    form.buildingNumber.onChange(vaccine.buildingNumber);
    form.street.onChange(vaccine.street);
    form.apt.onChange(vaccine.apt);
    form.city.onChange(vaccine.city);
    form.state.onChange(vaccine.state);
    form.zip.onChange(vaccine.zip);

    utils.form.disableFields(form);
}