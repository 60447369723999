import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from "@elastic/eui";

import { TextField, SelectField } from "modules/commons/components";

import options from "modules/commons/options";

import FormContext from "./FormContext";

function Guardian() {
  const context = useContext(FormContext);
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Guardian</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.guardian_first} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField {...context.guardian_last} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <SelectField
            {...context.guardian_relationship}
            options={options.relationships}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default Guardian;
