import React, { useState, useRef } from "react";

import {
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButton,
  EuiHorizontalRule,
} from "@elastic/eui";

import { ScanTextField } from "modules/commons/components";

import Form from "./components/Form";
import SubmitResult from "./components/SubmitResult";

import useOrder from "./hooks/useOrder";

const Page = () => {
  const ref = useRef();
  const [order, setOrder] = useState(null);
  const [requisitionNumber, setRequisitionNumber] = useState("");

  const { isLoading, fetchOrder } = useOrder((order) => {
    setOrder(order);
  });

  const cleanUp = () => {
    ref?.current.focus();
    setOrder(null);
    setRequisitionNumber("");
  };

  return (
    <div>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>Rapid Results</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule />

        <EuiFlexGroup>
          <EuiFlexItem>
            <ScanTextField
              inputRef={ref}
              value={requisitionNumber}
              setValue={setRequisitionNumber}
              onPressEnter={() => fetchOrder(requisitionNumber)}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              isLoading={isLoading}
              onClick={() => fetchOrder(requisitionNumber)}>
              Search
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>

      <EuiSpacer />

      <Form order={order} />

      <EuiSpacer />

      <SubmitResult order={order} onSuccess={cleanUp} />
    </div>
  );
};

export default Page;
