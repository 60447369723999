import dateParser from "utils/dateParser";
import {
  EuiButton,
  EuiCallOut,
  EuiCopy,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
} from "@elastic/eui";

import { usePrintLabel } from "modules/commons/hooks";

export function OrdersTodayWithPreregistration({ orders = null }) {
  const { printLabels } = usePrintLabel();
  const onClickPrintLabels = (order) => printLabels([order]);

  if (orders?.length == 0) return null;
  return (
    <>
      <EuiCallOut
        title="There are other orders today for this pre-registration."
        color="danger"
        iconType="alert">
        <EuiDescriptionList
          listItems={orders.map((x) => {
            return {
              title: (
                <>
                  <EuiIcon type="beaker" />{" "}
                  <EuiCopy textToCopy={x.requisitionNumber}>
                    {(copy) => (
                      <p onClick={copy}>
                        {x.requisitionNumber} ({x.testTypeName})
                      </p>
                    )}
                  </EuiCopy>{" "}
                  {dateParser({
                    date: x.testTime,
                    format: "ccc h:mm a",
                  })}
                </>
              ),
              description: (
                <>
                  <EuiFlexGroup
                    responsive={false}
                    wrap
                    gutterSize="s"
                    alignItems="center">
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        fill
                        iconType="document"
                        onClick={(e) => onClickPrintLabels(x)}>
                        Print Label
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              ),
            };
          })}
        />
      </EuiCallOut>
    </>
  );
}

export default { OrdersTodayWithPreregistration };
