import { useState } from "react";
import dateOfBirthDateParser from "utils/dateOfBirthDateParser";
import * as labelPrinting from "services/label-printing";
import axios from "utils/axios";

function usePrintLabel() {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const printLabels = async (orders = []) => {
    setIsLoading(true);
    setErrors({});

    let errors = {};

    for (let order of orders) {
      const response = await axios(`/Order/${order.id}`);
      const orderDetail = response.data;

      try {
        const _errors = validateOrder(orderDetail);

        if (_errors.length > 0) {
          errors[order.requisitionNumber] = _errors;
        } else {
          const formatted = formatOrder(orderDetail);
          const printer = labelPrinting.factory();
          await printer.printModel(formatted);
        }
      } catch (error) {
        errors[order.requisitionNumber] = [error.message];
      }
    }

    console.log(errors);

    setIsLoading(false);
    setErrors(errors);
  };

  return {
    errors,
    isLoading,
    printLabels,
  };
}

const validateOrder = (order) => {
  const keys = [
    "requisitionNumber",
    "city",
    "state",
    "dob",
    "firstName",
    "lastName",
  ];

  return keys
    .map((key) => {
      return !order[key] ? key : null;
    })
    .filter((key) => key);
};

export const formatOrder = (order) => {
  return {
    ...order,
    date: dateOfBirthDateParser({ date: order.dob }),
    info: "MDR-NYC",
  };
};

export default usePrintLabel;
