import React, { useEffect, useState } from "react";

import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiButtonEmpty,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiFlyoutFooter,
} from "@elastic/eui";

import { useParams } from "react-router-dom";

import { DateOfBirthLabel, Show, ConfirmationModal } from "modules/commons/components";
import { useAxios } from "modules/commons/hooks";

import "./OrderDetail.css";

const OrderConfirmation = ({ order, onSuccess = () => {} }) => {
  const { id: locationId } = useParams();

  const request = {
    method: "post",
    url: `/batch/current/${locationId}`,
    data: {
      requisitionNumber: order.requisitionNumber,
    },
  };

  return (
    <ConfirmationModal
      modalTitle="Send"
      title="Confirmation"
      onSuccess={onSuccess}
      message={`Are you sure you want to close order: ${order.requisitionNumber}?`}
      request={request}
    />
  );
};

const OrderDetail = ({ order, onSuccess = () => {} }) => {
  const [orderDetail, setOrderDetail] = useState({});
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const [isLoading, fetchOrder] = useAxios({
    onSuccess: (response) => {
      setOrderDetail(response.data);
      setIsFlyoutVisible(true);
    },
    onFailure: (error) => {
      alert(error);
      setIsFlyoutVisible(false);
    },
  });

  const _onSuccess = () => {
    setIsFlyoutVisible(false);
    onSuccess();
  };

  const onClickOpen = () => {
    fetchOrder({
      method: "get",
      url: `/Order/${order.id}`,
    });
  };

  return (
    <>
      <EuiButtonEmpty isLoading={isLoading} onClick={onClickOpen}>
        <span>{order.requisitionNumber}</span>
      </EuiButtonEmpty>
      <Show when={isFlyoutVisible}>
        <EuiFlyout
          ownFocus
          onClose={() => setIsFlyoutVisible(false)}
          aria-labelledby="flyoutTitle"
        >
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size="m">
              <h2 id="flyoutTitle">Order - {orderDetail.requisitionNumber}</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow fullWidth label="Name">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={`${orderDetail.firstName} ${orderDetail.lastName}`}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow fullWidth label="Date of Birth">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={DateOfBirthLabel({
                      date: orderDetail.dob
                    })}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow fullWidth label="Ethnicity">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={orderDetail.ethnicity}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow fullWidth label="Phone">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={orderDetail.phone}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFormRow fullWidth label="Email">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={orderDetail.email}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow fullWidth label="Street">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={orderDetail.street}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow fullWidth label="City">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={orderDetail.city}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFormRow fullWidth label="State">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={orderDetail.state}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFormRow fullWidth label="Zip Code">
                  <EuiFieldText
                    className="OrderDetailInput"
                    fullWidth
                    disabled
                    value={orderDetail.zip}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutBody>

          <EuiFlyoutFooter>
            <EuiFlexGroup>
              <EuiFlexItem />
              <EuiFlexItem grow={false}>
                <OrderConfirmation onSuccess={_onSuccess} order={order} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </Show>
    </>
  );
};

export default OrderDetail;
