import { getCachedSettings } from "modules/commons/hooks/useSettings";

import { dymoLabelPrinter } from "../dymo-label-printing";
import { qzLabelPrinter } from "../qz-label-printing";
import { qzDocumentPrinter } from "../qz-document-printing";

export interface LabelPrinter {
  printModel(model: any): Promise<void>;
  previewModel(model: any): Promise<void>;
}

export interface DocumentPrinter {
  printPdf(model: { content: any }): Promise<void>;
}

export function factory(): LabelPrinter {
  const settings = getCachedSettings();
  const provider =
    settings["printerType"] === "qz" ? qzLabelPrinter : dymoLabelPrinter;
  return provider;
}

export function documentPrinterFactory(): DocumentPrinter {
  return qzDocumentPrinter;
}
