import axios from 'utils/axios';

export async function fetchLocations() {    
    const response = await axios({
        method: 'GET',
        url: '/Location',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
}