import React, { useState } from "react";
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiCodeBlock,
  EuiSpacer,
  EuiFlexItem,
  EuiFlexGroup,
  EuiHorizontalRule,
} from "@elastic/eui";
import Schedule from ".";
import { useFormField, useLocations } from "modules/commons/hooks";
import { LocationSelect, Show } from "modules/commons/components";
import api from "networking";

export default ({ order }) => {
  const [location, setLocation] = useState<any>(order.location);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);
  const { locations, isLoading: isFetchLocationsLoading } = useLocations();
  const appointmentTimeField = useFormField({
    title: "Appointment Time",
    value: null,
  });

  const createAppointment = async () => {
    await api.PatientRegistration.ScheduleUpcomingAppointments({
      locationId: location.id,
      requisitionNumber: order.requisitionNumber,
      appointmentTime: appointmentTimeField.value,
    });

    alert("Appointment Created.");

    closeModal();
  };

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Appointment Scheduler</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          Appointment Scheduler: {order.firstName} {order.lastName}
          <EuiSpacer />
          <EuiFlexGroup alignItems="flexEnd">
            <EuiFlexItem grow={7}>
              <LocationSelect
                onChange={setLocation}
                options={locations}
                isLoading={isFetchLocationsLoading}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <Show when={location != null}>
            <EuiHorizontalRule />
            <EuiFlexGroup alignItems="flexEnd">
              <EuiFlexItem grow={7}>
                Location: {location.orgTestingCode}
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup alignItems="flexEnd">
              <EuiFlexItem grow={7}>
                <Schedule
                  locationId={location?.id}
                  appointmentTime={appointmentTimeField}></Schedule>
              </EuiFlexItem>
            </EuiFlexGroup>
            <Show when={location?.id > 0 && appointmentTimeField.value != null}>
              <EuiFlexGroup alignItems="flexEnd">
                <EuiFlexItem grow={7}>
                  <EuiButton onClick={createAppointment}>
                    Add Appointment
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </Show>
          </Show>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <div>
      <EuiButton onClick={showModal}>Schedule upcoming appointment</EuiButton>
      {modal}
    </div>
  );
};
