import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from "@elastic/eui";

import { HideWhen, TextField } from "modules/commons/components";

import FormContext from "./FormContext";

function AdditionalInformation() {
  const context = useContext(FormContext);
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Additional Information</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <HideWhen when={(settings) => settings.HideOrderPassportNumberInput}>
          <EuiFlexItem>
            <TextField {...context.patient_passport_number} />
          </EuiFlexItem>
        </HideWhen>
        <HideWhen when={(settings) => settings.HideOrderPublicNoteInput}>
          <EuiFlexItem>
            <TextField {...context.patient_public_note} />
          </EuiFlexItem>
        </HideWhen>
      </EuiFlexGroup>
    </>
  );
}

export default AdditionalInformation;
