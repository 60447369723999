import React, { useContext, useEffect, useRef } from 'react';

import {
    EuiPanel,
    EuiBasicTable,
    EuiTitle,
    EuiHorizontalRule,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
} from '@elastic/eui';

import {
    Show,
    TextField,
    DateField,
    Pagination,
    LocationSelect,
} from 'modules/commons/components';

import {
    usePagination,
    useLocations,
    useFormField,
} from 'modules/commons/hooks';

import FormContext, { FormProvider } from './form/FormContext';
import { NewVaccineModal } from './VaccineModal';
import PhoneTypeModal from './PhoneTypeModal';
import odataFilter from './odataFilters';
import useVaccines from './useVaccines';

import columns from './columns';

function Page() {
    const firstRef = useRef(true);
    const context = useContext(FormContext);
    const [location, setLocation] = context.location;

    const {
        pagination,
        onChange: onTableChange,
    } = usePagination();

    const {
        isLoading: isLocationLoading,
        locations,
    } = useLocations({
        locationType: 'Vaccine',
    });

    const filters = {
        firstName: useFormField({
            title: 'First Name',
        }),
        lastName: useFormField({
            title: 'Last Name',
        }),
        firstDoseDate: useFormField({
            title: 'First Dose Date',
            placeholder: "MM/DD/YYYY"
        }),
    };

    const _filters = valuesFromFilters(filters);

    const odata = odataFilter({
        locationId: location?.id,
        ..._filters,
    })

    const { state, loadData } = useVaccines({
        locationId: location?.id,
        odata,
    });

    useEffect(() => {
        if (firstRef.current) {
            firstRef.current = false;
            return;
        }
        loadData({ pageIndex: 0, pageSize: 10 });
    }, [location?.id]);

    useEffect(() => {
        loadData(pagination);
    }, [pagination?.pageSize, pagination?.pageIndex]);

    const _columns = columns({ loadData });

    return (
        <>
            <PhoneTypeModal />
            <EuiPanel>
                <EuiFlexGroup alignItems="center">

                    <EuiFlexItem grow={false}>
                        <EuiTitle>
                            <h1>Vaccines</h1>
                        </EuiTitle>
                    </EuiFlexItem>

                    <EuiFlexItem />

                    <EuiFlexItem grow={false}>
                        <NewVaccineModal
                            disabled={!location.id}
                            refreshTable={loadData}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>

                <EuiHorizontalRule />

                <EuiFlexGroup alignItems="flexEnd">

                    <EuiFlexItem>
                        <LocationSelect
                            options={locations}
                            onChange={setLocation}
                            isLoading={isLocationLoading}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                        <TextField {...filters.firstName} />
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                        <TextField {...filters.lastName} />
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                        <DateField {...filters.firstDoseDate} />
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                        <EuiButton
                            aria-label="Refresh"
                            title="Refresh"
                            iconType="refresh"
                            disabled={!location.id}
                            onClick={loadData}
                            isLoading={state.isLoading}
                        >
                            <span>Refresh</span>
                        </EuiButton>
                    </EuiFlexItem>

                </EuiFlexGroup>

                <Show when={location.id}>
                    <EuiHorizontalRule />
                    <EuiBasicTable
                        loading={state.isLoading}
                        items={state.items}
                        columns={_columns}
                        onChange={onTableChange}
                    />
                    <Pagination
                        totalItems={state.itemsTotal}
                        pagination={pagination}
                        onChange={onTableChange}
                    />
                </Show>
            </EuiPanel>
        </>
    );
}

function Vaccine() {
    return (
        <FormProvider>
            <Page />
        </FormProvider>
    );
};

function valuesFromFilters(filters = {}) {
    return {
        firstName: filters?.firstName?.value,
        lastName: filters?.lastName?.value,
        firstDoseDate: filters?.firstDoseDate?.value,
    };
}

export default Vaccine;