import { useCallback, useState } from "react";
import axios from "utils/axios";

interface Order {
  orderId: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  phone: string;
  email: string;
}

const usePathOrder = () => {
  const [isLoading, setIsLoading] = useState(false);

  const pathOrder = useCallback(async (order: Order) => {
    setIsLoading(true);

    try {
      const { status } = await axios.patch("/order", order);

      if (status === 200) {
        alert("Successful update");
        return;
      }

      throw new Error("Unexpected error");
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, pathOrder };
};

export default usePathOrder;
