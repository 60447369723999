import { useEffect } from "react";

import { EuiPanel, EuiTitle, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";

import dateOfBirthDateParser from "utils/dateOfBirthDateParser";

import { Show, TextField, DateField } from "modules/commons/components";

import useFormField from "modules/commons/hooks/useFormField";

function Form({ order }) {
  const firstNameField = useFormField({
    title: "First Name",
    value: "",
    disabled: true,
  });
  const lastNameField = useFormField({
    title: "Last Name",
    value: "",
    disabled: true,
  });
  const dobField = useFormField({
    title: "Day of birth",
    value: "",
    disabled: true,
  });
  const phoneField = useFormField({
    title: "Phone",
    value: "",
    disabled: true,
  });
  const emailField = useFormField({
    title: "Email",
    value: "",
    disabled: true,
  });
  useEffect(() => {
    if (!order) return;

    const {
      dob = "",
      firstName = "",
      lastName = "",
      phone = "",
      email = "",
    } = order;

    const _dob = dateOfBirthDateParser({ date: dob });
    dobField.onChange(_dob);

    firstNameField.onChange(firstName);
    lastNameField.onChange(lastName);
    phoneField.onChange(phone);
    emailField.onChange(email);
  }, [order?.requisitionNumber]);

  return (
    <Show when={order}>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>{order?.requisitionNumber}</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
        
        <EuiSpacer size="m"/>

        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...firstNameField} />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField {...lastNameField} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="m"/>

        <EuiFlexGroup>
          <EuiFlexItem>
            <DateField {...dobField} />
          </EuiFlexItem>

          <EuiFlexItem>
            <TextField {...phoneField} />
          </EuiFlexItem>

          <EuiFlexItem>
            <TextField {...emailField} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </Show>
  );
}

export default Form;
