import { useCallback, useReducer } from "react";
import dateOfBirthDateParser from "utils/dateOfBirthDateParser";
import utils from "modules/commons/utils";
import * as labelPrinting from "services/label-printing";
import api from "networking";
import validate from "./form/validate";

const initState = {
  isLoading: false,
  error: null,
};

function useCreateOrder({
  form,
  location,
  preregistrationId,
  onSuccess = () => {},
}) {
  const [state, dispatch] = useReducer(reducer, initState);
  const onLoadingState = () => dispatch({ type: "onLoading" });
  const onSuccessState = (payload) => dispatch({ type: "onSuccess", payload });
  const onErrorState = (error) => dispatch({ type: "onError", payload: error });

  const createOrder = useCallback(async () => {
    try {
      onLoadingState();

      const validations = validate({
        form,
        locationType: location.locationType,
        preregistrationId,
      });

      if (validations?.length > 0) {
        throw new Error("Invalid form fields");
      }

      const model = utils.form.formToModel(form);

      const response = await api.Orders.saveOrder({
        ...mapFormToOrder(model),
        locationId: location?.id,
        preregistrationId,
      });

      const {
        paymentDeclined,
        paymentHasError,
        paymentError,
        paymentErrorCode,
        results,
      } = response.data;

      if (paymentDeclined)
        throw new Error(
          `Payment Declined: ${paymentError} (${paymentErrorCode})`
        );
      if (paymentHasError)
        throw new Error(`Payment Error: ${paymentError} (${paymentErrorCode})`);

      for (const order of results) {
        await print(order.requisitionNumber, order.testItemName, model);
      }

      onSuccess(results[0].requisitionNumber, results[0].shouldBatch);
      onSuccessState();
    } catch (error) {
      onErrorState(error);
      let errorMessage = error;
      if (error?.response?.data) errorMessage = error.response.data?.detail;
      alert(errorMessage);
    }
  }, [JSON.stringify(form), location?.id, preregistrationId, onSuccess]);

  return {
    state,
    createOrder,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case "onLoading":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "onSuccess":
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case "onError":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      throw new Error();
  }
}

async function print(requisitionNumber, testTypeName, model) {
  try {
    const printer = labelPrinting.factory();
    await printer.printModel({
      requisitionNumber,
      city: model.patient_city,
      state: model.patient_state,
      date: dateOfBirthDateParser({ date: model.patient_dob }),
      firstName: model.patient_first_name,
      lastName: model.patient_last_name,
      info: "MDR-NYC",
      testTypeName: testTypeName,
    });
  } catch (error) {
    alert(error);
  }
}

function mapFormToOrder(form) {
  return {
    IdType: form.id_type,
    StudentStaffId: form.student_staff_id,
    PreferredLanguage: form.preferred_language,

    ProductId: Number(form.product_id),
    QuestionnaireResponse: form.questionnaire_response,
    FirstName: form.patient_first_name,
    LastName: form.patient_last_name,
    Dob: form.patient_dob,
    Gender: form.patient_gender,
    Race: form.patient_race,
    Ethnicity: form.patient_ethnicity,

    Street: form.patient_address1,
    Apt: form.patient_address2,
    City: form.patient_city,
    State: form.patient_state,
    Zip: form.patient_zip,

    GuardianFirst: form.guardian_first,
    GuardianLast: form.guardian_last,
    Phone: form.guardian_phone,
    Email: form.guardian_email,
    GuardianRelationship: form.guardian_relationship,

    TestTime: form.test_time,

    insuranceProvider: form.insuranceProvider,
    insuranceMemberName: form.insuranceMemberName,
    insuranceGroupNumber: form.insuranceGroupNumber,
    insuranceMemberNumber: form.insuranceMemberNumber,

    paymentType: form.paymentType,
    creditCardId: form.creditCardId ? form.creditCardId : null,
    chargeCreditCard: form.paymentType === "creditcard",
  };
}

export default useCreateOrder;
