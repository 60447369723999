import axios from "utils/axios";

export async function get({ id }) {
  const response = await axios({
    method: "GET",
    url: `/CreditCard/${id}`,
  });

  return response;
}
