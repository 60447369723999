import { EuiPanel, EuiFlexGroup, EuiFlexItem, EuiButton } from "@elastic/eui";
import _isEmpty from "lodash/isEmpty";

import { Show, SelectField } from "modules/commons/components";

import useFormField from "modules/commons/hooks/useFormField";
import useSubmitResult from "./hooks/useSubmitResult";

import testResults from "modules/commons/options/testResults";

function SubmitResult({ order, onSuccess = () => {} }) {
  const result = useFormField({
    title: "Test Result",
  });

  const { isLoading, submitOrder } = useSubmitResult(onSuccess);

  const isButtonDisabled = _isEmpty(result.state.value) || isLoading;

  return (
    <Show when={order}>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <SelectField {...result} options={testResults} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem />
          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              isLoading={isLoading}
              disabled={isButtonDisabled}
              onClick={() =>
                submitOrder({
                  requisitionNumber: order.requisitionNumber,
                  result: result.state.value,
                })
              }
            >
              Submit Results
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </Show>
  );
}

export default SubmitResult;
