import { useState, useCallback, useMemo } from "react";

function useSort(defaultColumns = []) {
  const [columns, setColumns] = useState(defaultColumns);

  const onSort = useCallback(
    (columns) => {
      setColumns(columns);
    },
    [setColumns]
  );

  const values = useMemo(() => {
    return columns.map((column) => `${column.id} ${column.direction}`);
  }, [JSON.stringify(columns)]);

  return {
    values,
    columns,
    onSort,
  };
}

export default useSort;
