import OData from 'utils/odataQuery';

function odataFilters({
    requisitionNumber,
    testTime,
}) {

    const open = ({
        pageIndex = 0, 
        pageSize = 10,
        locationId = ""
    } = {}) => {
        const odata = new OData();

        const query = odata
            .pagination({
                pageIndex,
                pageSize,
            })
            .contains({
                name: 'requisitionNumber',
                value: requisitionNumber
            })
            .dateEquals({
                name: 'testTime',
                value: testTime,
            })
            .equals({
                name: 'batch/Id',
                value: null,
            })
            .equals({
                name: 'location/Id',
                value: locationId,
            })
            .build();

        return query;
    };

    const batch = ({
        pageIndex = 0,
        pageSize = 10
    } = {}) => {
        const odata = new OData();

        const query = odata
            .pagination({
                pageIndex,
                pageSize,
            })
            .contains({
                name: 'requisitionNumber',
                value: requisitionNumber
            })
            .dateEquals({
                name: 'testTime',
                value: testTime,
            })
            .build();

        return query;
    }

    return {
        open,
        batch,
    }

}

export default odataFilters;