import { useCallback, useEffect, useState } from "react";
import { useSettings } from "modules/commons/hooks";
import axios from "utils/axios";

interface Option {
  value: string;
  text: string;
}

interface Tenant {
  tenantName: string;
  displayName: string;
}

interface Response {
  currentTenant: Tenant;
  tenants: Tenant[];
}

const useTenant = () => {
  const { settings, setSetting } = useSettings();
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);

  const onChange = useCallback((tenant?: string) => {
    setSetting("tenant", tenant);
  }, []);

  const fetchOptions = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await axios.get<Response>("/userTenants");

      onChange(data?.currentTenant?.tenantName);

      const options = data?.tenants?.map<Option>((tenant) => {
        return { value: tenant.tenantName, text: tenant.displayName };
      });

      setOptions(options);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return { loading, selected: settings.tenant, options, onChange };
};

export default useTenant;
