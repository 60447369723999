import qz from "qz-tray";
import api from "networking";

export class QZPrintProvider {
  private static instance: QZPrintProvider;

  private constructor() {}

  static getInstance() {
    if (!QZPrintProvider.instance) {
      QZPrintProvider.instance = new QZPrintProvider();
    }

    return QZPrintProvider.instance;
  }

  async initialize() {
    qz.security.setCertificatePromise((resolve: any) => {
      api.QzPrint.cert()
        .then(({ data: cert = "" }) => resolve(cert))
        .catch(console.error);
    });

    qz.security.setSignatureAlgorithm("SHA512");

    qz.security.setSignaturePromise((toSign: any) => {
      return async (resolve: any, reject: any) => {
        try {
          const { data } = await api.QzPrint.sign({
            request: toSign,
          });

          resolve(data.signedRequest);
        } catch (ex) {
          reject(ex);
        }
      };
    });

    qz.websocket.setClosedCallbacks(function () {
      // INACTIVE;
    });

    qz.websocket.setErrorCallbacks(function () {
      // ERROR;
    });

    const result = await this.connect({});
    return result;
  }

  async connect(config: any) {
    try {
      if (qz.websocket.isActive()) {
        console.log("An active connection with QZ already exits.");
      } else {
        // WAITING;
        await qz.websocket.connect(config);
        // ACTIVE;
      }
    } catch (e) {
      console.log("Failed to connect", e);
      // ERROR;
    }
  }

  async disconnect() {
    try {
      if (qz.websocket.isActive()) {
        await qz.websocket.disconnect();
      }
    } catch (e) {
      console.log("Failed to disconnect", e);
    }
  }
  async printPixel(
    printDocument: any,
    options: {
      printerName: string;
      config?: any;
      format?: string;
      flavor?: string;
    }
  ) {
    try {
      const config = qz.configs.create(options.printerName, options.config);

      await qz.print(config, [
        {
          type: "pixel",
          format: options.format ?? "pdf",
          flavor: options.format ?? "base64",
          data: printDocument,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  async printRaw(
    printDocument: any,
    options: { printerName: string; config: any; language?: string }
  ) {
    try {
      const config = qz.configs.create(options.printerName, options.config);

      await qz.print(config, [
        {
          type: "raw",
          format: "command",
          flavor: "plain",
          data: printDocument,
          options: { language: options.language ?? "ZPL" },
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  async getListOfPrinters() {
    try {
      const printers = await qz.printers.find();
      return (printers || []) as string[];
    } catch (error) {
      console.log(error);
    }
  }

  async launch() {
    if (!qz.websocket.isActive()) {
      window.location.assign("qz:launch");
      const result = await this.connect({ retries: 10, delay: 1 });
      return result;
    }
  }
}
