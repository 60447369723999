import {
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { RawDateLabel, Show } from "modules/commons/components";
import { useFormField } from "modules/commons/hooks";
import ScheduleTimePickerField from "modules/vaccine-administration/schedule/ScheduleTimePickerField";
import { useState } from "react";
import api from "networking";

const ChangeAppointment = ({ appointment, locationId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const appointmentTimeField = useFormField({
    title: "Appointment Time",
    value: null,
  });

  const updateAppointment = async () => {
    await api.Appointment.UpdateTime({
      id: appointment.id,
      appointmentTime: appointmentTimeField.value,
    });

    alert("Appointment Updated.");

    closeModal();
  };

  return (
    <>
      <EuiButton fill onClick={showModal} color="warning">
        <span>Change Appointment</span>
      </EuiButton>
      <Show when={isModalVisible}>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>
                Change Appointment for:{" "}
                <b>
                  {appointment.patientFirstName} {appointment.patientLastName}
                </b>
              </h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiForm component="form">
              <EuiFormRow>
                <>
                  Old Appointment Time:{" "}
                  <b>
                    <RawDateLabel date={appointment.appointmentTime} />
                  </b>
                </>
              </EuiFormRow>
              <EuiFormRow>
                <ScheduleTimePickerField
                  {...appointmentTimeField}
                  locationId={locationId}
                />
              </EuiFormRow>
            </EuiForm>
          </EuiModalBody>

          <EuiModalFooter>
            <>
              <Show when={appointmentTimeField.value != null}>
                <EuiButton onClick={updateAppointment}>
                  Change Appointment
                </EuiButton>
              </Show>
              <EuiButton onClick={closeModal} fill>
                Close
              </EuiButton>
            </>
          </EuiModalFooter>
        </EuiModal>
      </Show>
    </>
  );
};

export default ChangeAppointment;
