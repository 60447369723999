import Handlebars from "handlebars";
import getLabelContent from "./labelFetcher";

let labelTemplate;
Handlebars.registerHelper("upper", (str) => str && str.toUpperCase());

export default async function prepareLabel(model) {
  const template = await getCachedLabelTemplate();

  return template(model);
}

async function getCachedLabelTemplate() {
  if (!labelTemplate) {
    const labelContent = await getLabelContent();
    labelTemplate = Handlebars.compile(labelContent);
  }

  return labelTemplate;
}
