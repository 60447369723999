import { useState, useContext } from "react";
import moment from "moment";
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiHorizontalRule,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiSpacer,
  EuiTextColor,
} from "@elastic/eui";

import {
  Show,
  DateOfBirthLabel,
  Pagination,
  TextField,
  DateField,
  SelectField,
  HideWhen,
} from "modules/commons/components";

import { useDebounce, usePagination } from "modules/commons/hooks";

import utils from "modules/commons/utils";

import FormContext from "modules/orders/form/FormContext";

import { useFormField } from "modules/commons/hooks";

import useRegistration from "./useRegistration";

const consentOptions = [
  { value: "", text: "All" },
  { value: "true", text: "Consented" },
  { value: "false", text: "Not consented" },
];

const useAreFiltersEmpty = (filters = {}) => {
  const _filters = { ...filters, testingLocation: "" };
  return Object.values(_filters).filter(Boolean).length < 1;
};

const TableEmptyMessage = ({
  areFiltersEmpty = false,
  isLoading = false,
  hasResults = false,
  children = null,
}) => {
  if (isLoading) {
    return <>Loading....</>;
  }

  if (!areFiltersEmpty && !hasResults) {
    return children;
  }

  return <>No items found</>;
};

function Modal({ closeModal, onClickRow }) {
  const context = useContext(FormContext);
  const [defaultLocation] = context.location;

  const [location, setLocation] = useState(defaultLocation.orgTestingCode);
  const [testingDate, setTestingDate] = useState(moment().format("MM/DD/YYYY"));

  const { pagination, onChange: onTableChange } = usePagination();

  const form = {
    patientFirstName: useFormField({
      title: "First Name",
    }),
    patientLastName: useFormField({
      title: "Last Name",
    }),
    patientDob: useFormField({
      title: "Date of Birth",
      placeholder: "MM/DD/YYYY",
    }),
    consent: useFormField({
      title: "Consent",
    }),
  };

  const filters = {
    testingLocation: location,
    patientFirstName: form.patientFirstName.state.value,
    patientLastName: form.patientLastName.state.value,
    patientDob: form.patientDob.state.value,
    consent: form.consent.state.value,
    testingDate: testingDate,
  };

  const debounceFilters = useDebounce(filters, 500);

  const areFiltersEmpty = useAreFiltersEmpty(debounceFilters);

  const { items, itemsTotal, isLoading } = useRegistration({
    filters: debounceFilters,
    pagination,
  });

  const _onClickRow = (row) => {
    onClickRow(row);

    utils.form.resetFields(filters);
    closeModal();
  };

  const onCloseModal = () => {
    utils.form.resetFields(filters);
    closeModal();
  };

  const columns = [
    {
      field: "name",
      name: "Name",
      render: (value, row) => {
        return (
          <EuiButtonEmpty onClick={() => _onClickRow(row)}>
            <span>{value}</span>
          </EuiButtonEmpty>
        );
      },
    },
    {
      field: "patientDob",
      name: "Date of birth",
      render: (value = "") => {
        return <DateOfBirthLabel date={value} />;
      },
    },
    {
      field: "consent",
      name: "Consent",
      render: (value) => {
        return value ? (
          <EuiTextColor color="primary">Consented</EuiTextColor>
        ) : (
          <EuiTextColor color="danger">Not consented</EuiTextColor>
        );
      },
    },
    {
      field: "paymentType",
      name: "Payment Type",
    },
  ];

  const _filters = (
    <>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem grow>
          <DateField {...form.patientDob} />
        </EuiFlexItem>

        <EuiFlexItem grow>
          <TextField {...form.patientFirstName} />
        </EuiFlexItem>

        <EuiFlexItem grow>
          <TextField {...form.patientLastName} />
        </EuiFlexItem>

        <EuiFlexItem grow>
          <SelectField {...form.consent} options={consentOptions} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onCloseModal} maxWidth={1200}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>{location ? defaultLocation.schoolName : "All locations"}</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          {_filters}
          <EuiHorizontalRule />
          <EuiBasicTable
            loading={isLoading}
            rowHeader="name"
            items={items}
            onChange={onTableChange}
            columns={columns}
            noItemsMessage={
              <TableEmptyMessage
                isLoading={isLoading}
                hasResults={items.length > 0}
                areFiltersEmpty={areFiltersEmpty}>
                <HideWhen
                  when={(settings) =>
                    settings.PreregistrationsDisableChangeFilterLocation
                  }>
                  <EuiButtonEmpty onClick={() => setLocation("")}>
                    Search all locations
                  </EuiButtonEmpty>
                </HideWhen>
                <HideWhen
                  when={(settings) =>
                    settings.PreregistrationsDisableChangeFilterDate
                  }>
                  <EuiButtonEmpty onClick={() => setTestingDate("")}>
                    Search all dates
                  </EuiButtonEmpty>
                </HideWhen>
              </TableEmptyMessage>
            }
          />
        </EuiModalBody>

        <EuiModalFooter>
          <Pagination
            totalItems={itemsTotal}
            pagination={pagination}
            onChange={onTableChange}
          />
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  );
}

function RegistrationModal({ disabled = true, onClickRow = () => {} }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  return (
    <div>
      <EuiButton fill disabled={disabled} onClick={showModal}>
        <span>Select from pre register</span>
      </EuiButton>
      <Show when={isModalVisible}>
        <Modal closeModal={closeModal} onClickRow={onClickRow} />
      </Show>
    </div>
  );
}

export default RegistrationModal;
