import React, { useEffect } from 'react';

import {
    EuiTitle,
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiButtonEmpty,
} from '@elastic/eui';

import {
    Table,
    ComboBoxField,
    RefreshButton,
} from 'modules/commons/components';

import {
    useFormField,
    usePagination,
    useExportData,
    useLocationTypes,
    useFetch,
} from 'modules/commons/hooks';

import columns from './columns';
import exportJson from 'utils/exportJson';

function locationExportParser(values) {
    return values.map(value => {
        const location = value?.location;

        let _value = {
            ...value,
            orgTestingCode: location?.orgTestingCode,
            schoolDbn: location?.schoolDbn,
            schoolName: location?.schoolName,
        };

        delete _value.location;

        return _value;
    });
}

function Page() {

    const { fetch, isLoading, payload } = useFetch({
        url: "/report",
    });

    const filters = {
        locationType: useFormField({
            title: 'Location Type',
        }),
    };

    const {
        options: locaitonTypesOptions
    } = useLocationTypes();

    const {
        pagination,
        pageItems,
        onChange: onPaginationChange,
    } = usePagination();

    const state = {
        items: pageItems(payload),
        total: payload?.length || 0,
        isLoading,
        error: null,
    };

    const onClickExport = () => {
        exportJson({ filename: "resume", items: payload });
    }

    useEffect(() => {
        fetch(`?locationType=${filters?.locationType?.value?.label || "All"}`);
    }, [JSON.stringify(filters)]);

    return (
        <EuiPanel>
            <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={false}>
                    <EuiTitle>
                        <h1>Resume Reports</h1>
                    </EuiTitle>
                </EuiFlexItem>

                <EuiFlexItem />

                <EuiFlexItem grow={false}>
                    <p>{state.total}</p>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule />

            <EuiFlexGroup alignItems="flexEnd">
                <EuiFlexItem grow={3}>
                    <ComboBoxField
                        {...filters.locationType}
                        options={locaitonTypesOptions}
                    />
                </EuiFlexItem>

                <EuiFlexItem grow={7} />
            </EuiFlexGroup>

            <EuiHorizontalRule />
            <Table
                state={state}
                columns={columns}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                additionalControls={
                    <>
                        <RefreshButton
                            isLoading={state.isLoading}
                            // loadData={() => loadData(pagination)}
                        />
                        <EuiButtonEmpty
                            size="xs"
                            iconType="download"
                            onClick={onClickExport}
                        >
                            <span>{'Export'}</span>
                        </EuiButtonEmpty>
                    </>
                }
            />
        </EuiPanel>
    );
}

export default Page;