import React, { createContext, useState } from "react";

import moment from "moment";

import { useFormField } from "modules/commons/hooks";

import utils from "modules/commons/utils";

const FormContext = createContext();

export function FormProvider({ children }) {
  const value = {
    // ================ general ================
    id_type: useFormField({
      title: "Student Staff Type *",
      validation: utils.validations.isNotEmpty,
    }),
    requisition_number: useFormField({
      title: "Requisition Number",
      placeholder: "Auto Generated",
      disabled: true,
    }),
    test_time: useFormField({
      title: "Order Date *",
      value: moment(),
    }),
    test_type_id: useFormField({
      title: "Test Type",
      value: "",
      validation: utils.validations.isNotEmpty,
    }),
    test_type_name: useFormField({
      title: "Test Type Name",
      value: "",
    }),

    // ================ product ================
    product_id: useFormField({
      title: "Tests *",
      value: "1",
      validation: utils.validations.isNotEmpty,
    }),

    questionnaire_response: useFormField({
      title: "Questionnaire *",
      value: null,
      validation: utils.validations.isNotEmpty,
    }),

    // ================ patient ================
    patient_first_name: useFormField({
      title: "First Name *",
      validation: utils.validations.isNotEmpty,
    }),
    patient_last_name: useFormField({
      title: "Last Name *",
      validation: utils.validations.isNotEmpty,
    }),
    patient_passport_number: useFormField({
      title: "Passport/Id Number",
    }),
    patient_public_note: useFormField({
      title: "Additional note on test results page",
    }),
    patient_dob: useFormField({
      title: "Date of Birth *",
      placeholder: "MM/DD/YYYY",
      validation: utils.validations.date,
    }),
    patient_gender: useFormField({
      title: "Gender *",
      validation: utils.validations.isNotEmpty,
    }),
    patient_race: useFormField({
      title: "Race *",
      validation: utils.validations.isNotEmpty,
    }),
    patient_ethnicity: useFormField({
      title: "Ethnicity *",
      validation: utils.validations.isNotEmpty,
    }),

    // ================ address ================
    patient_address1: useFormField({
      title: "Address 1 *",
      validation: utils.validations.isNotEmpty,
    }),
    patient_address2: useFormField({
      title: "Address 2",
    }),
    patient_city: useFormField({
      title: "City *",
      validation: utils.validations.isNotEmpty,
    }),
    patient_state: useFormField({
      title: "State *",
      validation: utils.validations.isNotEmpty,
    }),
    patient_zip: useFormField({
      title: "Zip Code *",
      validation: utils.validations.isNotEmpty,
    }),

    // ================ guardian ================
    guardian_first: useFormField({
      title: "First Name",
      validation: utils.validations.isNotEmpty,
    }),
    guardian_last: useFormField({
      title: "Last Name",
      validation: utils.validations.isNotEmpty,
    }),
    guardian_phone: useFormField({
      title: "Phone",
      validation: utils.validations.isPhone,
    }),
    guardian_email: useFormField({
      title: "Email",
      validation: utils.validations.isEmail,
    }),
    guardian_relationship: useFormField({
      title: "Relationship",
      validation: utils.validations.isNotEmpty,
    }),

    // ================ Payment Section ================
    paymentType: useFormField({
      title: "Payment Type",
    }),

    // ================ insurance ==============
    insuranceGroupNumber: useFormField({
      title: "Group Number",
    }),

    insuranceMemberName: useFormField({
      title: "Member Name",
    }),

    insuranceMemberNumber: useFormField({
      title: "Member Number",
    }),

    insuranceProvider: useFormField({
      title: "Provider",
    }),

    insuranceCardPictureFileName: useFormField({
      title: "Insurance Card Picture",
    }),

    // ================ credit card ==============
    creditCardId: useFormField({
      title: "Credit Card ID",
    }),

    location: useState({}),
    registration: useState({}),
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

export default FormContext;
