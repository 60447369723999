import axios from 'utils/axios';

export async function fetchList() {    
    const response = await axios({
        method: 'GET',
        url: '/Product',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
}