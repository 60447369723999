import OData from "utils/odataQuery";

function odataFilters({
  pageIndex = 0,
  pageSize = 10,
  locationId = "",
  testTime,
}) {
  const odata = new OData();

  const query = odata
    .pagination({
      pageIndex,
      pageSize,
    })
    .equals({
      name: "location/Id",
      value: +locationId,
    })
    .dateEquals({
      name: "testTime",
      value: testTime,
    })
    .equals({
      name: "batch/Id",
      value: null,
    })
    .build();

  return query;
}

export default odataFilters;
