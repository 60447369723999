import { EuiPanel, EuiFlexGroup, EuiFlexItem, EuiButton } from "@elastic/eui";
import _isEmpty from "lodash/isEmpty";

import { Show, SelectField } from "modules/commons/components";

import useFormField from "modules/commons/hooks/useFormField";

import { useCallback, useContext } from "react";
import useAddMedicationRequest from "../MedicationRequest/hooks/useAddMedicationRequest";

const medicationOptions = [
  {
    value: "Paxlovid",
    text: "Paxlovid",
  },
];

function NoMedicationRequests({ order, onSuccess = () => {} }) {
  const medicationName = useFormField({
    title: "Medication Name",
    value: "Paxlovid",
  });

  const [isLoading, submit] = useAddMedicationRequest(onSuccess);

  const createNewMedicationRequest = useCallback(
    async function () {
      await submit({
        orderId: order.id,
        medicationName: medicationName.state.value,
      });
    },
    [order, medicationName]
  );

  return (
    <Show when={order}>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <SelectField {...medicationName} options={medicationOptions} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton
              iconType="plus"
              onClick={() => {
                createNewMedicationRequest();
              }}>
              Add
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </Show>
  );
}

export default NoMedicationRequests;
