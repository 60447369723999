import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
    EuiFormRow,
    EuiDatePicker,
} from '@elastic/eui';

const format = "MM/DD/YYYY";

export function DateField({
    state = {},
    onChange = () => { }
}) {

    const [value, setValue] = useState();

    useEffect(() => {
        if (!state?.value) { return; }
        const value = moment(state.value, format);
        setValue(value);
    }, [state?.value]);

    const _onChange = (selected) => {
        if (moment.isMoment(selected)) {
            const value = selected.format(format);
            onChange(value);
        } else {
            onChange(null);
        }

        setValue(selected);
    }

    return (
        <EuiDatePicker
            fullWidth
            selected={value}
            placeholder={state.placeholder}
            disabled={state.disabled}
            isInvalid={!!state.error}
            onChange={_onChange}
        />
    );

}

export default ({
    state = {},
    onChange = () => { }
}) => {

    return (
        <EuiFormRow
            fullWidth
            label={state.title}
            helpText={state?.error?.message || ""}
        >
            <DateField
                state={state}
                onChange={onChange}
            />
        </EuiFormRow>
    );
}
