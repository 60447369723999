import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
  EuiSpacer,
} from "@elastic/eui";

import { useAuth0 } from "@auth0/auth0-react";

import "./Profile.css";

function Profile() {
  const { user } = useAuth0();

  const Avatar = () => {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <img alt="user" src={user.picture} className="Profile-avatar" />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  const Body = () => {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <h1>Name: {user.name}</h1>
          <h2>Email: {user.email}</h2>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  return (
    <div className="Profile">
      <EuiTitle size="l">
        <h1>Settings</h1>
      </EuiTitle>

      <EuiSpacer size="s" />

      <EuiPanel className="Profile-content">
        <Avatar />
        <Body />
      </EuiPanel>
    </div>
  );
}

export default Profile;
