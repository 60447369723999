import races from "./races";
import genders from "./genders";
import usaStates from "./usaStates";

import phoneTypes from "./phoneTypes";
import insurances from "./insurances";
import testResults from "./testResults";
import ethnicities from "./ethnicities";
import occupations from "./occupations";
import vaccineSites from "./vaccineSites";
import relationships from "./relationships";

export default {
  races,
  genders,
  usaStates,
  phoneTypes,
  insurances,
  testResults,
  ethnicities,
  occupations,
  vaccineSites,
  relationships,
};
