export function blobToDataUrl(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export async function blobToBase64(blob): Promise<string> {
  var dataUrl = await blobToDataUrl(blob);
  return dataUrl.substring(dataUrl.indexOf(","));
}
