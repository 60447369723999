import { DateTime } from "luxon";

function parse({
    date = DateTime.now(),
    format = "MM/dd/yyyy",
    zone = "system",
}) {
    if (date == "0001-01-01T00:00:00Z") return "";

    return DateTime.fromISO(date).setZone(zone).toFormat(format);
}

export default parse;