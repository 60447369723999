import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from "@elastic/eui";

import FormContext from "./FormContext";
import { CreditCardPayment } from "modules/credit-card";

function CreditCard() {
  const context = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Credit Card</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <CreditCardPayment creditCardId={context.creditCardId.value} />
    </>
  );
}

export default CreditCard;
