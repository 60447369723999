import React, { useState, useRef, useCallback } from "react";

import {
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButton,
  EuiHorizontalRule,
} from "@elastic/eui";

import { ScanTextField } from "modules/commons/components";

import Form from "./components/Form";
import SubmitResult from "./components/SubmitResult";

import useOrder from "./hooks/useOrder";
import downloadFile from "utils/downloadFile";
import SchedulingModal from "./schedule/SchedulingModal";
import { documentPrinterFactory } from "services/label-printing";
import getRemoteFile from "utils/getRemoteFile";
import { blobToBase64 } from "utils/blobToBase64";

const Page = () => {
  const ref = useRef();
  const [order, setOrder] = useState(null);
  const [requisitionNumber, setRequisitionNumber] = useState("");

  const { isLoading, fetchOrder } = useOrder((order) => {
    setOrder(order);
  });

  const cleanUp = () => {
    ref?.current.focus();
    //setOrder(null);
    //setRequisitionNumber("");
    printVaccineRecord();

    fetchOrder(order.requisitionNumber);
  };

  const printVaccineRecord = useCallback(async () => {
    var pdfBlob = await getRemoteFile({
      url: `VaccineOrderReport/download/${order.requisitionNumber}.pdf`,
      accept: "application/pdf",
      responseType: "blob",
    });

    documentPrinterFactory().printPdf({
      content: await blobToBase64(pdfBlob),
    });
  }, [order]);

  const downloadVaccineRecord = useCallback(async () => {
    await downloadFile({
      url: `VaccineOrderReport/download/${order.requisitionNumber}.pdf`,
      accept: "application/pdf",
      filename: `VaccineOrder_${order.requisitionNumber}.pdf`,
    });
  }, [order]);

  return (
    <div>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>Vaccine Administration</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule />

        <EuiFlexGroup>
          <EuiFlexItem>
            <ScanTextField
              inputRef={ref}
              value={requisitionNumber}
              setValue={setRequisitionNumber}
              onPressEnter={() => fetchOrder(requisitionNumber)}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              isLoading={isLoading}
              onClick={() => fetchOrder(requisitionNumber)}>
              Search
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>

      <EuiSpacer />

      <Form order={order} />

      <EuiSpacer />

      {order?.vaccineLotNumber == null && (
        <SubmitResult order={order} onSuccess={cleanUp} />
      )}

      {order?.vaccineLotNumber && (
        <EuiPanel>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiTitle>
                <h2>Order Completed</h2>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <SchedulingModal order={order} />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton onClick={() => downloadVaccineRecord()}>
                Download Vaccine Record (LOT#{" "}
                {order?.vaccineLotNumber.toUpperCase()})
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton onClick={() => printVaccineRecord()}>Print</EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      )}
    </div>
  );
};

export default Page;
