import buildQuery from "odata-query";
import { DateTime } from "luxon";

export function isValid(value = "", length) {
  return value && value.length > length;
}

export function locations(locationId) {
  return {
    Location: {
      Id: locationId,
    },
  };
}

export function pagination({ pageIndex = 0, pageSize = 10 }) {
  const top = pageSize;
  const skip = pageSize * pageIndex;

  return {
    top,
    skip,
  };
}

class OData {
  constructor() {
    this.params = { filter: [] };
  }

  pagination({ pageIndex = 0, pageSize = 10 }) {
    const top = pageSize;
    const skip = pageSize * pageIndex;
    this.params = { ...this.params, top, skip };

    return this;
  }

  equals({ name, value, minLength = 0, type }) {
    if (isValid("" + value, minLength)) {
      const valueObj = type ? { value, type } : value;
      this.params.filter.push({ [name]: valueObj });
    }

    return this;
  }

  equalsCaseInsensitive({ name, value, minLength = 0 }) {
    if (isValid(value, minLength)) {
      this.params.filter.push({
        [`tolower(${name})`]: value.toLowerCase(),
      });
    }

    return this;
  }

  notEquals({ name, value, minLength = 0 }) {
    if (isValid("" + value, minLength)) {
      this.params.filter.push({ not: { [name]: value } });
    }

    return this;
  }

  contains({ name, value, minLength = 0 }) {
    if (isValid(value, minLength)) {
      this.params.filter.push({
        [`tolower(${name})`]: { contains: value.toLowerCase() },
      });
    }

    return this;
  }

  dateEquals({ name, value, format = "MM/dd/yyyy" }) {
    if (isValid(value, 9)) {
      const date = DateTime.fromFormat(value, format).toISODate();
      this.params.filter.push(`${name} eq ${date}`);
    }

    return this;
  }

  dateTimeEquals({ name, value, format = "MM/dd/yyyy" }) {
    if (isValid(value, 9)) {
      const date = DateTime.fromFormat(value, format).toISO();
      this.params.filter.push(`${name} eq ${date}`);
    }

    return this;
  }

  dateOn({ name, value, format = "MM/dd/yyyy" }) {
    if (isValid(value, 9)) {
      const date = DateTime.fromFormat(value, format);
      this.params.filter.push(
        `${name} ge ${date.toISO()} and ${name} le ${date
          .plus({ days: 1 })
          .toISO()}`
      );
    }

    return this;
  }

  dateBetween({ name, dateFrom, dateTo, format = "MM/dd/yyyy" }) {
    let params = [];

    if (isValid(dateFrom, 9)) {
      const date = DateTime.fromFormat(dateFrom, format).toISO();

      params.push(`${name} ge ${date}`);
    }

    if (isValid(dateTo, 9)) {
      const date = DateTime.fromFormat(dateTo, format).toISO();

      params.push(`${name} le ${date}`);
    }

    if (params.length > 0) {
      let query = params.join(" and ");
      this.params.filter.push(query);
    }

    return this;
  }

  orderBy(fields = []) {
    this.params.orderBy = [fields];
    return this;
  }

  build = () => buildQuery(this.params);
}

export default OData;

export { buildQuery };
