import OData from 'utils/odataQuery';

export function orders({
    locationId,
    pageIndex = 0,
    pageSize = 10,
    createdDate = '',
    closedDate = '',
    closed = '',
}) {

    const odata = new OData();

    if (closed && closed.length > 0) {
        odata.params.filter.push({
            closed: closed === "true"
        });
    }

    const query = odata
        .pagination({ 
            pageIndex, 
            pageSize 
        })
        .equals({
            name: 'Location/Id',
            value: locationId,
        })
        .dateOn({
            name: 'createdDate',
            value: createdDate,
        })
        .dateOn({
            name: 'closedDate',
            value: closedDate,
        })
        .orderBy("createdDate")
        .build();


    return query;
}

