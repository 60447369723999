import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiHorizontalRule,
} from "@elastic/eui";
import _isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";

import {
  Show,
  SelectField,
  TextField,
  DateField,
} from "modules/commons/components";

import useFormField from "modules/commons/hooks/useFormField";
import useAdministerVaccine from "./hooks/useAdministerVaccine";

import vaccineManufacturers from "modules/commons/options/vaccineManufacturers";
import vaccineSites from "modules/commons/options/vaccineSites";
import utils from "modules/commons/utils";
const VACCINATOR_NAME_KEY = "VaccinatorNameSetting";

function AdministerVaccine({ order, onSuccess = () => {} }) {
  const manufacturers = useFormField({
    title: "Manufacturer",
    value: "JYNNEOS",
  });

  const sites = useFormField({
    title: "Administering Site",
  });

  const lotNumber = useFormField({
    title: "Lot # *",
    value: "FDP00002",
    validation: utils.validations.isNotEmpty,
  });

  const ndcCode = useFormField({
    title: "NDC Code #",
    value: "5063200102",
  });

  const vaccinatorName = useFormField({
    title: "Vaccinator Name *",
    value: localStorage.getItem(VACCINATOR_NAME_KEY) ?? "",
    validation: utils.validations.isNotEmpty,
  });

  const dose = useFormField({
    title: "Dosage",
  });

  const expirationDate = useFormField({
    title: "Expiration Date *",
    value: "08/31/2023",
  });

  const { isLoading, submitOrder } = useAdministerVaccine(onSuccess);

  const isButtonDisabled =
    _isEmpty(lotNumber.state.value) ||
    _isEmpty(vaccinatorName.state.value) ||
    isLoading;

  return (
    <Show when={order}>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...lotNumber} />
          </EuiFlexItem>
          <EuiFlexItem>
            <TextField {...ndcCode} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <DateField {...expirationDate} />
          </EuiFlexItem>
          <EuiFlexItem>
            <SelectField {...manufacturers} options={vaccineManufacturers} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem>
            <SelectField {...sites} options={vaccineSites} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiHorizontalRule />
        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...vaccinatorName} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFlexGroup>
          <EuiFlexItem />
          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              isLoading={isLoading}
              disabled={isButtonDisabled}
              onClick={() => {
                localStorage.setItem(
                  VACCINATOR_NAME_KEY,
                  vaccinatorName.state.value
                );

                submitOrder({
                  requisitionNumber: order.requisitionNumber,
                  lotNumber: lotNumber.state.value,
                  manufacturers: manufacturers.state.value,
                  manufacturer: manufacturers.state.value,
                  administeringSite: sites.state.value,
                  dose: dose.state.value,
                  ndcCode: ndcCode.state.value,
                  vaccinatorName: vaccinatorName.state.value,
                  expirationDate: DateTime.fromFormat(
                    expirationDate.state.value,
                    "MM/dd/yyyy"
                  ).toISODate(),
                });
              }}>
              Submit Vaccine Data
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </Show>
  );
}

export default AdministerVaccine;
