import { useState, useRef } from "react";

import {
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButton,
  EuiHorizontalRule,
} from "@elastic/eui";

import { ScanTextField, Show } from "modules/commons/components";

import Form from "./components/Form";
import MedicationRequest from "./components/MedicationRequest";
import NoMedicationRequests from "./components/NoMedicationRequests";

import useOrder from "./hooks/useOrder";
import useMedicalRequests from "./hooks/useMedicalRequests";

const Page = () => {
  const ref = useRef();
  const [order, setOrder] = useState(null);
  const [medicalRequests, setMedicalRequests] = useState(null);
  const [requisitionNumber, setRequisitionNumber] = useState("");

  const [isLoading, fetchOrder] = useOrder((order) => {
    setOrder(null);

    setOrder(order);

    fetchMedicalRequests(order.id);
  });

  const [isLoadingMedicalRequests, fetchMedicalRequests] = useMedicalRequests(
    (medicalRequests) => {
      setMedicalRequests(null);
      setMedicalRequests(medicalRequests);
    }
  );

  const cleanUp = () => {
    setRequisitionNumber("");
    setMedicalRequests(null);
    setOrder(null);
    ref?.current.focus();
  };

  return (
    <div>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>Prescriptions</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule />

        <EuiFlexGroup>
          <EuiFlexItem>
            <ScanTextField
              inputRef={ref}
              value={requisitionNumber}
              setValue={setRequisitionNumber}
              onPressEnter={() => fetchOrder(requisitionNumber)}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              isLoading={isLoading || isLoadingMedicalRequests}
              onClick={() => fetchOrder(requisitionNumber)}>
              Search
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <Show when={order}>
        <EuiSpacer />

        <Form order={order} />

        <EuiSpacer />

        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={true}>
            <EuiButton
              iconType="refresh"
              isLoading={isLoadingMedicalRequests}
              onClick={() => {
                fetchMedicalRequests(order.id);
              }}>
              Reload Medication Requests
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Show>
      <EuiSpacer />

      {order !== null &&
        medicalRequests?.length > 0 &&
        medicalRequests.map((medicalRequest) => (
          <MedicationRequest
            key={medicalRequest.id}
            order={order}
            request={medicalRequest}
          />
        ))}

      {order !== null && medicalRequests?.length === 0 && (
        <NoMedicationRequests
          order={order}
          onSuccess={() => fetchMedicalRequests(order.id)}
        />
      )}
    </div>
  );
};

export default Page;
