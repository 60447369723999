import { useCallback, useState } from "react";
import axios from "utils/axios";

export type MethodType = "email" | "phone";

const useSendResults = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendResults = useCallback(
    async (methodType: MethodType, requisitionNumber: string) => {
      setIsLoading(true);

      const path = methodType === "phone" ? "ResendSms" : "ResendEmail";

      try {
        const { status } = await axios.post(
          `/ResendOrderTestResults/${path}/${requisitionNumber}`
        );

        if (status === 200) {
          alert("Successful update");
          return;
        }

        throw new Error("Unexpected error");
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { isLoading, sendResults };
};

export default useSendResults;
