import { useState } from 'react';
import axios from 'utils/axios';
import exportJson from 'utils/exportJson';

function useExportData({
    pageIndex = 0,
    pageSize = 100,
    url = '',
    filename,
    odata,
    parser = () => { }
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(false);

    const exportData = async () => {

        let items = [];
        let isFetching = true;
        let currentPageIndex = pageIndex;
        setIsLoading(true);
        setProgress('Loading...');

        try {

            while (isFetching) {

                const query = odata({
                    pageSize,
                    pageIndex: currentPageIndex,
                });

                const response = await axios({
                    method: 'GET',
                    url: `${url}${query}`,
                });

                const { results, total } = (response?.data || {});
                const newItems = (results || []);

                if (newItems.length < 1) {
                    isFetching = false;
                } else {
                    const _newItems = parser(newItems) || newItems;
                    items = [...items, ..._newItems];
                    currentPageIndex++;
                    setProgress(`${items.length}/${total}`);
                }
            }

            exportJson({ filename, items });
        } catch (error) {
            console.log(error);
            alert(error);
        } finally {
            setIsLoading(false);
        }

    };

    return {
        isLoading,
        progress,
        exportData,
    }
}

export default useExportData;