import ScheduleTimePickerField from "./ScheduleTimePickerField";

const Schedule = ({ locationId, appointmentTime }) => {
  return (
    <>
      <ScheduleTimePickerField
        {...appointmentTime}
        locationId={locationId}></ScheduleTimePickerField>
    </>
  );
};

export default Schedule;
