import React, { useContext, useMemo } from 'react';

import {
    EuiTitle,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
} from '@elastic/eui';

import FormContext from './FormContext';

import {
    TextField,
    DateField,
    SelectField,
} from 'modules/commons/components';

import InsuranceSelect from './InsuranceSelect';

import {
    useShowModal,
} from 'modules/commons/hooks';

import PhoneTypeModal from 'modules/vaccines/PhoneTypeModal';

import options from 'modules/commons/options';

function Form() {
    const context = useContext(FormContext);

    const { isVisible, openModal, closeModal } = useShowModal();

    const onPhoneTypeChange = (value) => {
        (value === 'mobile') && openModal();
        context.phoneType.onChange(value);
    }

    return (
        <>
            <PhoneTypeModal
                isModalVisible={isVisible}
                closeModal={closeModal}
                onClickCancel={() => {
                    context.phoneType.onChange('other');
                }}
            />
            {/* ================================ General ================================ */}

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="s">
                        <h2>General</h2>
                    </EuiTitle>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <DateField {...context.firstDoseDate} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <DateField {...context.secondDoseDate} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <DateField {...context.scheduledSecondDoseDate} />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule />

            {/* ================================  Vaccine ================================ */}

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="s">
                        <h2>Vaccine</h2>
                    </EuiTitle>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <DateField {...context.vaccineExpirationDate} />
                </EuiFlexItem>

                <EuiFlexItem>
                    <TextField {...context.vaccineLotNumber} />
                </EuiFlexItem>

                <EuiFlexItem>
                    <SelectField
                        {...context.vaccineAdministeringSite}
                        options={options.vaccineSites}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule />

            {/* ================================ Insurance ================================ */}

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="s">
                        <h2>Insurance</h2>
                    </EuiTitle>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <InsuranceSelect {...context.insuranceCompany} />
                </EuiFlexItem>

                <EuiFlexItem>
                    <TextField {...context.policyNumber} />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule />

            {/* ================================ Patient ================================ */}

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="s">
                        <h2>Patient</h2>
                    </EuiTitle>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <TextField {...context.firstName} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <TextField {...context.lastName} />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <DateField {...context.dob} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <SelectField
                        {...context.gender}
                        options={options.genders}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <SelectField 
                        {...context.ethnicity} 
                        options={options.ethnicities}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <SelectField
                        {...context.race}
                        options={options.races}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule />

            {/* ================================ Contact ================================ */}

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="s">
                        <h2>Contact</h2>
                    </EuiTitle>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <TextField {...context.phone} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <SelectField
                        {...context.phoneType}
                        options={options.phoneTypes}
                        onChange={onPhoneTypeChange}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <TextField {...context.email} />
                </EuiFlexItem>

                <EuiFlexItem />
            </EuiFlexGroup>


            <EuiHorizontalRule />

            {/* ================================ Address ================================ */}

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="s">
                        <h2>Address</h2>
                    </EuiTitle>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem grow={2}>
                    <TextField {...context.buildingNumber} />
                </EuiFlexItem>
                <EuiFlexItem grow={6}>
                    <TextField {...context.street} />
                </EuiFlexItem>
                <EuiFlexItem grow={2}>
                    <TextField {...context.apt} />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <TextField {...context.city} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <SelectField
                        {...context.state}
                        options={options.usaStates}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <TextField {...context.zip} />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule />
        </>
    );
}

export default Form;