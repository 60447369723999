import { useState, useMemo, useCallback } from "react";

const usePagination = (itemsTotal) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const pagination = useMemo(() => {
    return {
      pageIndex: pageIndex,
      pageSize: pageSize,
      totalItemCount: itemsTotal,
      pageSizeOptions: [10, 20, 50],
    };
  }, [pageSize, pageIndex, itemsTotal]);

  const onTableChange = useCallback(({ page = {} }) => {
    const { index: pageIndex, size: pageSize } = page;

    setPageIndex(pageIndex);
    setPageSize(pageSize);
  }, []);

  return { pagination, onTableChange };
};

export default usePagination;
