import React, { useEffect } from "react";

import { EuiStat, EuiFlexItem, EuiFlexGroup, EuiPanel } from "@elastic/eui";

import { useFetch } from "modules/commons/hooks";

export default () => {
  const { fetch, payload, isLoading } = useFetch({
    url: "/dashboard/getcommunitycenterbatches",
  });

  useEffect(() => {
    fetch();

    const id = setInterval(() => {
      fetch();
    }, 5000);

    return () => clearInterval(id);
  }, [fetch]);

  return (
    <div>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${payload.batches || 0}`}
              description="Batches"
              isLoading={isLoading}></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${payload.openBatches || 0}`}
              description="Open Batches"
              isLoading={isLoading}></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${payload.closedBatches || 0}`}
              description="Closed Batches"
              isLoading={isLoading}></EuiStat>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem />
      </EuiFlexGroup>
    </div>
  );
};
