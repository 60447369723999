import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { RawDateLabel } from "modules/commons/components";
import AppointmentCancelConfirmation from "./AppointmentCancelConfirmation";
import ChangeAppointment from "./ChangeAppointment";

const ViewAppointment = ({ locationId, appointment, onClose = () => {} }) => {
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>
            {appointment.patientFirstName} {appointment.patientLastName}
          </h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm component="form">
          <EuiFormRow>
            <>
              Appointment Time:{" "}
              <b>
                <RawDateLabel date={appointment.appointmentTime} />
              </b>
            </>
          </EuiFormRow>
          <EuiFormRow>
            <EuiFlexGroup>
              <EuiFlexItem>
                <ChangeAppointment
                  appointment={appointment}
                  locationId={locationId}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <AppointmentCancelConfirmation id={appointment.id} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <>
          <EuiButton onClick={onClose} fill>
            Close
          </EuiButton>
        </>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default ViewAppointment;
