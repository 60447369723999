import { useCallback, useEffect, useState } from 'react';
import OData from 'utils/odataQuery';
import axios from 'utils/axios';

function useItemsTotal({ url = "" }) {

    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchItemsTotal = useCallback(async () => {

        try {
            setIsLoading(true);
            const query = odata();

            const response = await axios({
                url: `${url}${query}`,
                method: 'get',
            });

            setTotal(response?.data?.total);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }, []);

    useEffect(() => {
        fetchItemsTotal();
    }, [fetchItemsTotal]);

    const odata = () => {
        const odata = new OData();

        const query = odata
            .pagination({
                pageIndex: 0,
                pageSize: 1
            })
            .build();

        return query;
    };

    return {
        total,
        isLoading,
        fetchItemsTotal,
    };
}

export default useItemsTotal;