import { ReactElement, useContext } from "react";
import {
  EuiPageTemplate,
  EuiPageTemplateProps,
  EuiPageHeaderProps,
  EuiPageSidebarProps,
} from "@elastic/eui";
import { MenuContext } from "./MenuContext";

export default ({
  button = <></>,
  content = <></>,
  sidebar,
  header,
  panelled,
  bottomBorder = true,
  restrictWidth,
  sidebarSticky,
  offset,
  grow,
}: {
  button: ReactElement;
  content: ReactElement;
  sidebar?: ReactElement;
  header?: EuiPageHeaderProps;
  panelled?: EuiPageTemplateProps["panelled"];
  bottomBorder?: EuiPageTemplateProps["bottomBorder"];
  restrictWidth?: EuiPageTemplateProps["restrictWidth"];

  // For fullscreen only
  sidebarSticky?: EuiPageSidebarProps["sticky"];
  offset?: EuiPageTemplateProps["offset"];
  grow?: EuiPageTemplateProps["grow"];
}) => {
  const [context] = useContext(MenuContext);
  return (
    <EuiPageTemplate
      panelled={panelled}
      restrictWidth={restrictWidth}
      bottomBorder={bottomBorder}
      grow={grow}
      responsive={["xs", "s"]}
      offset={offset}>
      {sidebar && context.isOpen && (
        <EuiPageTemplate.Sidebar sticky={sidebarSticky}>
          {sidebar}
        </EuiPageTemplate.Sidebar>
      )}
      {header && (
        <EuiPageTemplate.Header {...header} rightSideItems={[button]} />
      )}
      <EuiPageTemplate.Section>{content}</EuiPageTemplate.Section>
    </EuiPageTemplate>
  );
};
