import OData from 'utils/odataQuery';

function odataFilter({
    testResult,
    requisitionNumber,
    firstName,
    lastName,
    exactFirstName,
    exactLastName,
    dob,
    //
    testTimeFrom,
    testTimeTo,
    //
    phone,
    locationId,
    locationType,
    batchedClosed,
    resultTime,

    testTypeName,

    preregistrationId,
    
    vaccineLotNumber,

    voided,
    
    orderBy = [],
}) {
    const odata = (pagination) => {
        const odata = new OData();

        if (testResult === "HasResult") {
            odata.notEquals({
                name: 'testResult',
                value: null,
            });
        } else {
            odata.equals({
                name: 'testResult',
                value: testResult === "NoResult" ? null : testResult,
            });
        }
        const query = odata
            .orderBy(orderBy)
            .pagination({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize
            })
            .contains({
                name: 'requisitionNumber',
                value: requisitionNumber,
            })
            .contains({
                name: 'firstName',
                value: firstName,
            })
            .contains({
                name: 'lastName',
                value: lastName,
            })
            .equalsCaseInsensitive({
                name: 'firstName',
                value: exactFirstName,
            })
            .equalsCaseInsensitive({
                name: 'lastName',
                value: exactLastName,
            })
            .dateEquals({
                name: 'dob',
                value: dob,
            })
            .dateBetween({
                name: 'testTime',
                dateFrom: testTimeFrom,
                dateTo: testTimeTo,
            })
            .contains({
                name: 'phone',
                value: phone,
            })
            .equals({
                name: 'location/id',
                value: locationId,
            })
            .equals({
                name: 'location/locationType',
                value: locationType,
            })
            .dateOn({
                name: 'batchedClosedTime',
                value: batchedClosed,
            })
            .dateOn({
                name: 'resultTime',
                value: resultTime,
            })
            .equals({
                name: "testTypeName",
                value: testTypeName,
            })
            .equals({
                name: "preregistration/id",
                value: preregistrationId ,
            })
            .contains({
                name: 'vaccineLotNumber',
                value: vaccineLotNumber,
            })
            .notEquals({
                name: 'voided',
                value: !voided,
            })
            .build();

        return query;
    }

    return odata

}

export default odataFilter;