import { EuiSpacer } from '@elastic/eui';
import Profile from "./Profile";
import { PrinterSettings } from "./PrinterSettings";
import "./Settings.css";

function Settings() {

  return (
    <>
      <Profile />
      <EuiSpacer />
      <PrinterSettings />
    </>
  );
}

export default Settings;
