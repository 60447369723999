import { getCachedSettings } from "modules/commons/hooks";
import { DocumentPrinter } from "services/label-printing";
import { QZPrintProvider } from "services/qz-label-printing";
const qz = QZPrintProvider.getInstance();

export const qzDocumentPrinter: DocumentPrinter = {
  printPdf: async (model) => {
    const settings = getCachedSettings();
    await qz.printPixel(model.content, {
      printerName: settings.printerName,
    });
  },
};
