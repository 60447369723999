import { useCallback } from "react";
import useAxios from "modules/commons/hooks/useAxios";

function useAddMedicationRequest(onSuccess = () => {}) {
  const [isLoading, fetch] = useAxios({
    onSuccess,
    onFailure: () => alert("Error while submitting the medication request"),
  });

  const submitMedicationRequest = useCallback(
    async ({ id, orderId, medicationName, questionnaireResponse, notes }) => {
      if (!orderId) return;
      
      await fetch({
        method: "POST",
        url: `/MedicationRequest/order/${orderId}`,
        data: {
          id,
          medicationName,
          questionnaireResponse,
          notes,
        },
      });
    },
    [onSuccess]
  );

  return [isLoading, submitMedicationRequest];
}

export default useAddMedicationRequest;
