import {
    EuiPopover,
    EuiButton,
    EuiPopoverTitle,
    EuiPopoverFooter,
    EuiSelect,
    EuiFormRow,
} from '@elastic/eui';

import { useShowModal } from 'modules/commons/hooks';

const fields = [
    { value: 'firstName', text: 'First Name' },
    { value: 'lastName', text: 'Last Name' },
    { value: 'requisitionNumber', text: 'Requisition Number' },
];

const directions = [
    { value: 'asc', text: 'Ascent' },
    { value: 'desc', text: 'Descent' },
];

export default ({ value = {}, onChange = () => { } }) => {
    const { closeModal, openModal, isVisible } = useShowModal(false);

    const _onChange = (e) => {
        const { name, value: newValue } = e.target;
        onChange({
            ...value,
            [name]: newValue
        });
    };

    const fieldText = fields.find(field => field.value === value.field)?.text;

    const button = (
        <EuiButton
            iconSide="right"
            iconType={value.direction === "asc" ? "arrowUp" : "arrowDown"}
            onClick={openModal}
        >
            <span>{fieldText}</span>
        </EuiButton>
    );

    return (
        <EuiPopover
            ownFocus
            button={button}
            isOpen={isVisible}
            closePopover={closeModal}
            anchorPosition="upCenter"
        >
            <EuiPopoverTitle>Sort By</EuiPopoverTitle>
            <div style={{ width: '300px' }}>
                <EuiFormRow label="Field">
                    <EuiSelect
                        name="field"
                        value={value.field}
                        options={fields}
                        onChange={_onChange}
                    />
                </EuiFormRow>
                <EuiFormRow label="Direction">
                    <EuiSelect
                        name="direction"
                        value={value.direction}
                        options={directions}
                        onChange={_onChange}
                    />
                </EuiFormRow>
            </div>
            <EuiPopoverFooter>
                <EuiButton fullWidth onClick={closeModal}>
                    <span>Close</span>
                </EuiButton>
            </EuiPopoverFooter>
        </EuiPopover>
    );
};