import Papa from "papaparse";

function exportJson({ filename, items }) {
    if (items.length < 1) {
        throw Error("Empty rows");
    }

    const csv = Papa.unparse(items);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = window.URL.createObjectURL(csvData);

    const tempLink = document.createElement("a");
    tempLink.setAttribute("download", `${filename}.csv`);
    tempLink.href = csvURL;
    tempLink.click();
}

export default exportJson;
