import { EuiFormRow, EuiTextArea } from "@elastic/eui";

function TextareaField({ state = {}, onChange = () => {} }) {
  return (
    <EuiFormRow
      fullWidth
      label={state.title}
      helpText={state?.error?.message || ""}>
      <EuiTextArea
        fullWidth
        value={state.value}
        placeholder={state.placeholder}
        disabled={state.disabled}
        isInvalid={!!state.error}
        onChange={(e) => onChange(e.target.value)}
      />
    </EuiFormRow>
  );
}

export default TextareaField;
