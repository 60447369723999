import React from "react";
import { DateLabel, DateOfBirthLabel } from "modules/commons/components";

const columns = [
  {
    id: "id",
    display: "Id",
    displayAsText: "Id",
  },
  {
    id: "firstName",
    display: "First Name",
    displayAsText: "First Name",
  },
  {
    id: "lastName",
    display: "Last Name ",
    displayAsText: "Last Name ",
  },
  {
    id: "dob",
    display: "Dob",
    displayAsText: "Dob",
    render: (date) => {
      return <DateOfBirthLabel date={date} />;
    },
  },
  {
    id: "gender",
    display: "Gender",
    displayAsText: "Gender",
  },
  {
    id: "email",
    display: "Email",
    displayAsText: "Email",
  },
  {
    id: "phone",
    display: "Phone",
    displayAsText: "Phone",
  },
  {
    id: "phoneType",
    display: "Phone Type",
    displayAsText: "Phone Type",
  },
  {
    id: "ethnicity",
    display: "Ethnicity",
    displayAsText: "Ethnicity",
  },
  {
    id: "race",
    display: "Race",
    displayAsText: "Race",
  },
  {
    id: "firstDoseDate",
    display: "First Dose Date",
    displayAsText: "First Dose Date",
    render: (date) => {
      return <DateLabel date={date} />;
    },
  },
  {
    id: "secondDoseDate",
    display: "Second Dose Date",
    displayAsText: "Second Dose Date",
    render: (date) => {
      return <DateLabel date={date} />;
    },
  },
  {
    id: "scheduledSecondDoseDate",
    display: "Scheduled Second Dose Date",
    displayAsText: "Scheduled Second Dose Date",
    render: (date) => {
      return <DateLabel date={date} />;
    },
  },
  // {
  //     id: "location",
  //     display: "Location",
  //     displayAsText: "Location"
  // },
  {
    id: "policyNumber",
    display: "Policy Number",
    displayAsText: "Policy Number",
  },
  {
    id: "insuranceCompany",
    display: "Insurance Company",
    displayAsText: "Insurance Company",
  },
  {
    id: "state",
    display: "State",
    displayAsText: "State",
  },
  {
    id: "city",
    display: "City",
    displayAsText: "City",
  },
  {
    id: "buildingNumber",
    display: "Building Number",
    displayAsText: "Building Number",
  },
  {
    id: "apt",
    display: "Apt",
    displayAsText: "Apt",
  },
  {
    id: "street",
    display: "Street",
    displayAsText: "Street",
  },
  {
    id: "zip",
    display: "Zip",
    displayAsText: "Zip",
  },
  {
    id: "vaccineAdministeringSite",
    display: "Vaccine Administering Site",
    displayAsText: "Vaccine Administering Site",
  },
  {
    id: "vaccineExpirationDate",
    display: "Vaccine Expiration Date",
    displayAsText: "Vaccine Expiration Date",
    render: (date) => {
      return <DateLabel date={date} />;
    },
  },
  {
    id: "vaccineLotNumber",
    display: "Vaccine Lot Number",
    displayAsText: "Vaccine Lot Number",
  },
];

export default columns;
