import { useCallback, useEffect, useState } from "react";
import {
  EuiSelect,
  EuiTitle,
  EuiPanel,
  EuiFormRow,
  EuiSpacer,
} from "@elastic/eui";
import { Show } from "modules/commons/components";
import { useSettings, SettingMap } from "modules/commons/hooks";
import { QZPrintProvider } from "services/qz-label-printing";

import usePrinterLabel, {
  options as labelOptions,
} from "./hooks/usePrinterLabel";

interface SelectOption {
  value: string;
  text: string;
}

const options: SelectOption[] = [
  { value: "", text: "Select printer" },
  { value: "dymo", text: "Dymo" },
  { value: "qz", text: "QZ Tray" },
];

export function PrinterSettings() {
  const { settings, setSetting } = useSettings();
  const [printers, setPrinters] = useState<SelectOption[]>([]);
  const { label, onChange: onLabelChange } = usePrinterLabel();
  const [isLoading, setIsLoading] = useState(false);

  const fetchPrinters = useCallback(async () => {
    if (settings?.printerType !== "qz") {
      return;
    }

    try {
      setIsLoading(true);
      const qzProvider = QZPrintProvider.getInstance();
      const printers = await qzProvider.getListOfPrinters();

      const options = printers.map((printer) => {
        return { value: printer, text: printer };
      });

      setPrinters(options);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPrinters();
  }, []);

  const onPrinterChange = (key: keyof SettingMap, value: string) => {
    setSetting(key, value);
  };

  return (
    <>
      <EuiTitle>
        <h1>Printer Settings</h1>
      </EuiTitle>

      <EuiSpacer size="s" />

      <EuiPanel>
        <EuiFormRow label="Select printer type">
          <EuiSelect
            options={options}
            value={settings?.printerType || ""}
            onChange={(e) => onPrinterChange("printerType", e.target.value)}
          />
        </EuiFormRow>

        <Show when={settings?.printerType === "qz"}>
          <EuiFormRow label="Select printer name">
            <EuiSelect
              disabled={isLoading}
              isLoading={isLoading}
              options={printers}
              value={settings?.printerName || ""}
              onChange={(e) => onPrinterChange("printerName", e.target.value)}
            />
          </EuiFormRow>

          <EuiFormRow label="Select label type">
            <EuiSelect
              options={labelOptions}
              value={label}
              onChange={(e) => onLabelChange(e.target.value)}
            />
          </EuiFormRow>
        </Show>
      </EuiPanel>
    </>
  );
}
