import utils from "modules/commons/utils";
import * as locationUtils from "utils/location";

const getDefaultFields = () => {
  return [
    "patient_dob",

    "preferred_language",
    "requisition_number",

    "patient_first_name",
    "patient_last_name",
    "patient_gender",

    "patient_address1",
    "patient_state",
    "patient_city",
    "patient_zip",
  ];
};

function validate({ form, locationType, preregistrationId }) {
  const requiredFields = getRequiredFields({
    form,
    locationType,
    preregistrationId,
  });

  const results = utils.form.runValidations({
    form,
    requiredFields,
  });

  return results;
}

function getRequiredFields({ form, locationType, preregistrationId }) {
  const isDeo = locationUtils.isLocationTypeDoe({ locationType });
  const isCommunity = locationUtils.isLocationTypeCommunityCenter({
    locationType,
  });

  const isDoeAndPre = isDeo && preregistrationId;

  const requiredFields = getDefaultFields();

  if (isDeo) {
    requiredFields.push("id_type");
  }

  const phone = form?.guardian_phone?.state?.value;
  const email = form?.guardian_email?.state?.value;
  const idType = form?.id_type?.state?.value;

  if (idType === "Student") {
    requiredFields.push("guardian_first");
    requiredFields.push("guardian_last");
    requiredFields.push("guardian_relationship");
  }

  if (isCommunity) {
    requiredFields.push("product_id");
  }

  if (isDoeAndPre && (phone || email)) {
    if (phone) {
      requiredFields.push("guardian_phone");
    }

    if (email) {
      requiredFields.push("guardian_email");
    }
  } else {
    requiredFields.push("guardian_phone");
    requiredFields.push("guardian_email");
  }

  return requiredFields;
}

export default validate;
