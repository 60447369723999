import { useCallback, useEffect, useState } from 'react';
import api from 'networking';

function useLocations() {
    const [isLoading, setIsLoading] = useState(false);
    const [locations, setLocations] = useState([]);
   
    const fetchLocations = useCallback(async () => {

        if(locations.length > 1) { return; }

        try {
            setIsLoading(true);

            const response = await api.Locations.fetchLocations();
            const locations  = response.data.results;

            setLocations(locations);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }, [locations.length]);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    return {
        fetchLocations,
        isLoading,
        locations,
    };

}

export default useLocations;