import { useState, useRef } from "react";

import {
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiHorizontalRule,
} from "@elastic/eui";

import { TextareaField } from "modules/commons/components";
import useOrder from "../rapid-results/hooks/useOrder";
import { useFormField, usePrintLabel } from "modules/commons/hooks";

const Page = () => {
  const { isLoading: isPrintLabelLoading, printLabels } = usePrintLabel();
  const { isLoading, fetchOrder } = useOrder(async (order) => {
    console.log("printLabel", order);
    await printLabels([order]);
  });

  const fetchOrders = async () => {
    console.log("fetchOrders");
    const reqs = requisitionNumbersField.value.split(/\r?\n/);
    for (let req of reqs) {
      await fetchOrder(req);
      await wait(500);
    }
  };

  function wait(time) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }
  const requisitionNumbersField = useFormField({
    title: "Requisition Numbers",
    value: "",
    disabled: false,
  });

  return (
    <div>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>Bulk Label Print</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule />

        <EuiFlexGroup>
          <EuiFlexItem>
            <TextareaField {...requisitionNumbersField} />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              isLoading={isLoading || isPrintLabelLoading}
              onClick={() => fetchOrders()}>
              Print
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </div>
  );
};

export default Page;
