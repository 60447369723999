import React, { useContext, useEffect, useRef, useState } from 'react';

import moment from 'moment';

import {
    EuiPanel,
    EuiBasicTable,
    EuiTitle,
    EuiHorizontalRule,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiTabs,
    EuiTab,
    EuiSpacer,
} from '@elastic/eui';

import {
    Show,
    TextField,
    DateField,
    Pagination,
    LocationSelect,
} from 'modules/commons/components';

import {
    useFormField,
    usePagination,
} from 'modules/commons/hooks';

import {
    FormProvider,
    FormContext,
} from './form';

import { NewOrderModal } from './OrderModal';
import odataFilter from './odataFilters';
import useOrders from './useOrders';
import useLocations from './useLocations';

import columns from './columns';

function Page() {
    const firstRef = useRef(true);
    const context = useContext(FormContext);
    const [location, setLocation] = context.location;

    const filters = {
        requisitionNumber: useFormField({
            title: 'Requisition Number',
        }),
        testTime: useFormField({
            placeholder: "MM/DD/YYYY",
            title: 'Order Time',
            value: moment().format("MM/DD/YYYY"),
        }),
    };

    const [selectedTab, setSelectedTab] = useState(0);

    const {
        pagination,
        onChange: onTableChange,
    } = usePagination();

    const {
        isLoading: isLocationLoading,
        locations,
    } = useLocations();

    const odata = odataFilter({
        locationId: location?.id,
        requisitionNumber: filters.requisitionNumber.state.value,
        testTime: filters.testTime.state.value,
    })

    const { state, loadData } = useOrders({
        isBatch: selectedTab === 0,
        locationId: location?.id,
        odata,
    });

    useEffect(() => {
        if (firstRef.current) {
            firstRef.current = false;
            return;
        }
        loadData({ pageIndex: 0, pageSize: 10 });
    }, [location?.id, selectedTab]);

    useEffect(() => {
        loadData(pagination);
    }, [pagination?.pageSize, pagination?.pageIndex]);

    const _columns = columns({ loadData });

    const tabs = [
        {
            id: 0,
            name: 'Validated',
            disabled: false,
        },
        {
            id: 1,
            name: 'Open',
            disabled: false,
        },
    ];

    const renderTabs = () => {
        return tabs.map((tab, index) => {
            return (
                <EuiTab
                    key={index}
                    isSelected={tab.id === selectedTab}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                </EuiTab>
            );
        });
    };

    const Header = (
        <EuiFlexGroup alignItems="center">

            <EuiFlexItem grow={false}>
                <EuiTitle>
                    <h1>Orders</h1>
                </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem />

            <EuiFlexItem grow={false}>
                <NewOrderModal
                    disabled={!location.id}
                    refreshTable={loadData}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    );

    const Fitlers = (
        <>
            <EuiFlexGroup alignItems="flexEnd">

                <EuiFlexItem grow={7}>
                    <LocationSelect
                        options={locations}
                        onChange={setLocation}
                        isLoading={isLocationLoading}
                    />
                </EuiFlexItem>

                <EuiFlexItem grow={3}>
                    <TextField
                        {...filters.requisitionNumber}
                    />
                </EuiFlexItem>


                <EuiFlexItem grow={3}>
                    <DateField
                        {...filters.testTime}
                    />
                </EuiFlexItem>


                <EuiFlexItem grow={false}>
                    <EuiButton
                        aria-label="Refresh"
                        title="Refresh"
                        iconType="refresh"
                        disabled={!location.id}
                        onClick={loadData}
                        isLoading={state.isLoading}
                    >
                        <span>Refresh</span>
                    </EuiButton>
                </EuiFlexItem>

            </EuiFlexGroup>
        </>
    );

    const Table = (
        <>
            <EuiBasicTable
                loading={state.isLoading}
                items={state.items}
                columns={_columns}
                onChange={onTableChange}
            />
            <Pagination
                totalItems={state.itemsTotal}
                pagination={pagination}
                onChange={onTableChange}
            />
        </>
    );

    return (
        <EuiPanel>
            {Header}
            <EuiHorizontalRule />
            {Fitlers}
            <Show when={location.id}>
                <EuiHorizontalRule />
                <EuiTabs>{renderTabs()}</EuiTabs>
                <EuiSpacer />
                {Table}
            </Show>
        </EuiPanel>
    );
}

function Orders() {
    return (
        <FormProvider>
            <Page />
        </FormProvider>
    );
};

export default Orders;