import moment from 'moment';
import validator from 'validator';

export function isNotEmpty(value) {
    if (!validator.isEmpty(value)) { return; }

    throw new Error("Empty field");
}

export function date(value) {
    const to = Date.now();
    const from = moment(to).subtract(106, 'years');
    const isBetween = moment(value).isBetween(from, to);
    if (isBetween) { return; }

    throw new Error("Invalid date");
}

export function isEmail(value) {
    if (validator.isEmail(value)) { return; }

    throw new Error("Invalid email address");
}

export function isPhone(value = '') {
    if (validator.isMobilePhone(value)) { return; }

    throw new Error("Invalid Phone number");
}