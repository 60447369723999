import React, { useState, createContext } from "react";

export const MenuContext = createContext();

export function MenuProvider(props) {
  const value = useState({
    isOpen: true,
    isOpenOnMobile: false,
    isDocked: true,
  });

  return (
    <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>
  );
}
