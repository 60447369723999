import { EuiSelect } from "@elastic/eui";
import useTenant from "../hooks/useTenant";

const SelectTenant = () => {
  const { loading, selected, options, onChange } = useTenant();

  return (
    <EuiSelect
      compressed={true}
      isLoading={loading}
      disabled={loading}
      value={selected}
      options={options}
      onChange={(e) => {
        onChange(e.target.value);
        window.location.reload();
      }}
    />
  );
};

export default SelectTenant;
