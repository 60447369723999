import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import _get from "lodash/get";
import jwt from "jsonwebtoken";

function useAuthorize() {
  const auth = useAuth0();
  const { getAccessTokenSilently } = auth;
  const [scopes, setScopes] = useState([]);
  const [isElite, setElite] = useState(false);

  useEffect(() => {
    async function getScopesFromToken() {
      const rawToken = await getAccessTokenSilently();
      const token = jwt.decode(rawToken) || "";
      const scopes = token?.scope?.split(" ");
      setScopes(scopes);
    }
    getScopesFromToken();
  }, []);

  useEffect(() => {
    const pathname = _get(window, "location.href", "");
    const isElite = pathname.includes("emr.elitelabsnyc.com");
    setElite(isElite);
  }, []);

  return {
    isElite,
    isTester: scopes.includes("testers"),
    isVaccine: scopes.includes("read:vaccine"),
    isWritePrescriptions: scopes.includes("write:prescriptions"),
    isReporter: scopes.includes("read:reports"),
    isExportReports: scopes.includes("can:exportreports"),
    isRapidResults: scopes.includes("rapidresults"),
    isBatchFinalize: scopes.includes("can:finalize"),
    isBatchSubmitToLab: scopes.includes("can:submittolab"),
    isAtHomeTesting: scopes.includes("read:athometesting"),
    isExportBilling: scopes.includes("can:exportbilling"),
    canVoidOrder: scopes.includes("can:voidorder"),
    canBulkPrint: scopes.includes("can:bulkprint"),
    canViewDashboard: scopes.includes("dashboard"),
  };
}

export default useAuthorize;
