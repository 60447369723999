import React, { createContext, useState } from 'react';
import moment from 'moment';

import { useFormField } from 'modules/commons/hooks';
import utils from 'modules/commons/utils';

const FormContext = createContext();

export function FormProvider({ children }) {

    const format = "MM/DD/YYYY"
    const today = moment();
    const today21days = moment().add(21, "days");

    const value = {
        firstDoseDate: useFormField({
            title: "First Dose Date",
            value: today.format(format),
            validation: utils.validations.isNotEmpty,
        }),
        secondDoseDate: useFormField({
            title: "Second Dose Date",
            value: today21days.format(format),
            validation: utils.validations.isNotEmpty,
        }),
        scheduledSecondDoseDate: useFormField({
            title: "Scheduled Second Dose Date",
            value: today21days.format(format),
            validation: utils.validations.isNotEmpty,
        }),
        /* ================================ General ================================ */
        policyNumber: useFormField({
            title: "Policy Number",
        }),
        phone: useFormField({
            title: "Phone",
            validation: utils.validations.isPhone,
        }),
        phoneType: useFormField({
            title: "Phone Type",
            validation: utils.validations.isNotEmpty,
        }),
        email: useFormField({
            title: "Email",
            validation: utils.validations.isEmail,
        }),
        /* ================================ Patient ================================ */
        firstName: useFormField({
            title: "First Name",
            validation: utils.validations.isNotEmpty,
        }),
        lastName: useFormField({
            title: "Last Name",
            validation: utils.validations.isNotEmpty,
        }),
        dob: useFormField({
            title: "Date Of Birth",
            validation: utils.validations.isNotEmpty,
        }),
        gender: useFormField({
            title: "Gender",
            validation: utils.validations.isNotEmpty,
        }),
        ethnicity: useFormField({
            title: "Ethnicity",
        }),
        race: useFormField({
            title: "Race",
        }),
        /* ================================ Address ================================ */
        buildingNumber: useFormField({
            title: "House Number",
            validation: utils.validations.isNotEmpty,
        }),
        street: useFormField({
            title: "Street",
            validation: utils.validations.isNotEmpty,
        }),
        apt: useFormField({
            title: "Apt",
            validation: utils.validations.isNotEmpty,
        }),
        city: useFormField({
            title: "City",
            validation: utils.validations.isNotEmpty,
        }),
        state: useFormField({
            title: "State",
            validation: utils.validations.isNotEmpty,
        }),
        zip: useFormField({
            title: "Zip",
            validation: utils.validations.isNotEmpty,
        }),
        /* ================================  Vaccine ================================ */
        insuranceCompany: useFormField({
            title: "Insurance Company",
        }),
        vaccineAdministeringSite: useFormField({
            title: "Vaccine Administering Site",
            validation: utils.validations.isNotEmpty,
        }),
        vaccineExpirationDate: useFormField({
            title: "Vaccine Expiration Date",
            validation: utils.validations.isNotEmpty,
        }),
        vaccineLotNumber: useFormField({
            title: "Vaccine Lot Number",
            validation: utils.validations.isNotEmpty,
        }),

        location: useState({}),
    };

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
}

export default FormContext;