import { useCallback } from "react";
import useAxios from "modules/commons/hooks/useAxios";

function useSubmitResult(onSuccess = () => {}) {
  const [isLoading, fetch] = useAxios({
    onSuccess,
    onFailure: () => alert("Error while submitting the order"),
  });

  const submitOrder = useCallback(({ requisitionNumber, result }) => {
    if (!requisitionNumber) return;

    fetch({
      method: "POST",
      url: `/internalTestResults`,
      data: {
        requisitionNumber,
        results: result,
      },
    });
  }, []);

  return { isLoading, submitOrder };
}

export default useSubmitResult;
