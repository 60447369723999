import ReactDOM from 'react-dom';
import './index.css';
import App from 'modules/app';
import * as serviceWorker from './serviceWorker';

import config from "./utils/auth_config";
import history from './utils/history';
import { Auth0Provider } from '@auth0/auth0-react';

import '@elastic/eui/dist/eui_theme_light.css';

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    clientId={config.clientId}
    audience={config.audience}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    scope="testers read:reports read:vaccine can:finalize can:submittolab read:athometesting rapidresults can:exportreports can:exportbilling write:prescriptions read:prescriptions can:voidorder can:bulkprint dashboard"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
