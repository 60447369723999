import { EuiEmptyPrompt } from "@elastic/eui";

function EmptyPage({ title }) {
  return (
    <EuiEmptyPrompt
      iconType="editorStrike"
      title={<h2>{title}</h2>}
      body={<p>This feature is not yet available</p>}
    />
  );
}

export default EmptyPage;
