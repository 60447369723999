import React, { useContext, } from 'react';

import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';

import {
    TextField,
    DateField,
    SelectField,
} from 'modules/commons/components';

import options from 'modules/commons/options'

import FormContext from './FormContext';

function Patient() {
    const context = useContext(FormContext);
    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiTitle size="s">
                        <h2>Patient</h2>
                    </EuiTitle>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
                <EuiFlexItem>
                    <TextField {...context.patient_first_name} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <TextField {...context.patient_last_name} />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="s" />
            
            <EuiFlexGroup>
                <EuiFlexItem>
                    <DateField {...context.patient_dob} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <SelectField 
                        {...context.patient_gender} 
                        options={options.genders} 
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
}

export default Patient;