import axios from "utils/axios";

export async function downloadFile(fileName) {
  const response = await axios({
    method: "GET",
    url: `/file/download/${fileName}`,
  });

  return response;
}

export async function uploadFiles(type, files) {
  let formData = new FormData();

  files.forEach(function (file) {
    formData.append("files", file, file.name);
  });

  const response = await axios({
    method: "POST",
    url: `/file/upload/${type}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });

  return response;
}
