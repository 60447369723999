import { useState, useCallback } from 'react';

function usePagination() {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const onChange = ({ page = {} }) => {
        const { index: pageIndex, size: pageSize } = page;

        setPageIndex(pageIndex);
        setPageSize(pageSize);
    };

    const pageItems = useCallback((items = []) => {

        try {
            const from = pageIndex * pageSize;
            const to = from + pageSize;

            return items.slice(from, to);
        } catch {
            return [];
        }

    }, [pageIndex, pageSize]);

    const pagination = {
        pageIndex,
        pageSize,
        pageSizeOptions: [10, 20, 50, 100, 200],
    };

    return {
        pagination,
        onChange,
        pageItems,
    };
}

export default usePagination;