import { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'utils/axios';

function useLocationTypes() {
    const [isLoading, setIsLoading] = useState(false);
    const [locationTypes, setLocationTypes] = useState([]);
    const [error, setError] = useState(null);

    const fetch = useCallback(async () => {

        try {
            setIsLoading(true);

            const response = await axios({
                method: 'get',
                url: `/location/getLocationTypes`,
            });

            const results = (response?.data || []);

            setLocationTypes(results);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }

    }, []);

    const options = useMemo(() => {
        return locationTypes.map((label, id) => {
            return { id, label };
        });
    }, [locationTypes?.length]);

    useEffect(() => {
        if (locationTypes.length < 1) {
            fetch();
        }

    }, [fetch]);

    return {
        fetch,
        error,
        isLoading,
        locationTypes,
        options,
    }

}

export default useLocationTypes;