import { useEffect, useRef, useReducer } from "react";
import api from "networking";

const initState = {
  items: [],
  itemsTotal: 0,
  isLoading: false,
  error: null,
};

function usePreregistration({ pagination, odata }) {
  const firstRef = useRef(true);

  const [state, dispatch] = useReducer(reducer, initState);
  const onFetch = () => dispatch({ type: "fetch" });
  const onError = (payload) => dispatch({ type: "error", payload });
  const onPopulate = (payload) => dispatch({ type: "populate", payload });

  const loadData = async (pagination = { pageSize: 10, pageIndex: 0 }) => {
    try {
      onFetch();

      const query = odata(pagination);
      const response = await api.Registration.fetchRegistrations(query);
      const results = mapResults(response?.data?.results);

      onPopulate({ ...response.data, results });
    } catch (error) {
      console.log(error);
      onError(error);
    }
  };

  const mapResults = (rawResults = []) => {
    const results = rawResults.map((result) => {
      return {
        ...result,
      };
    });

    return results;
  };

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
      return;
    }

    loadData({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
    });
  }, [pagination.pageSize, pagination.pageIndex]);

  return {
    state,
    loadData,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case "fetch":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "error":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "populate":
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.total,
        isLoading: false,
        error: null,
      };
    default:
      throw new Error();
  }
}

export default usePreregistration;
