import React, { useEffect, useState } from 'react';

import {
    useParams
} from "react-router-dom";

import {
    Show,
    Pagination,
    ScanTextField,
} from 'modules/commons/components';

import {
    EuiPanel,
    EuiBasicTable,
    EuiTitle,
    EuiHorizontalRule,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiFormRow,
    EuiGlobalToastList,
} from '@elastic/eui';

import {
    useQuery,
    useLocations,
    usePagination,
    useAuthorize,
} from 'modules/commons/hooks';

import * as odata from './odata';
import ConfirmationModal from './ConfirmationModal';

import SortBy from './SortBy';
import Progress from './Progress';
import useFetchOrders from './useFetchOrders';
import useBatch from './useBatch';

function OrdersValidation() {
    const { batchId } = useParams();
    const query = useQuery();
    const locationId = query.get('locationId');

    const auth = useAuthorize();

    const {
        locations,
        isLoading: isLocationsLoading,
    } = useLocations();

    const [toasts, setToasts] = useState([]);
    const [location, setLocation] = useState([]);
    const [requisitionNumber, setRequisitionNumber] = useState("");

    const [sortBy, setSortBy] = useState({
        field: 'firstName',
        direction: 'asc',
    });

    const {
        batch,
        isLoading: isBatchLoading,
        fetchBatch,
    } = useBatch(batchId);

    const {
        orders,
        ordersTotal,
        addOrder,
        fetchOrders,
        fetchOrdersTotal,
        isLoading: isOrdersLoading,
    } = useFetchOrders(locationId, {
        onFailure: (error) => {
            const { title, detail } = error.response.data;
            addToastError({ title, text: detail });
        },
    });

    const {
        pagination,
        onChange: onPaginationChange,
    } = usePagination();

    useEffect(() => {
        if (locations.length < 1) { return; }
        const location = locations.find(location => location.id === +locationId);
        setLocation(location);
    }, [locationId, locations?.length]);

    useEffect(() => {
        if (!location?.id) { return; }
        fetchData();
    }, [location?.id]);

    useEffect(() => {
        if (!location?.id) { return; }
        const orderBy = `${sortBy.field} ${sortBy.direction}`
        const query = odata.orders({ ...pagination, orderBy });
        fetchOrders(query);
    }, [pagination.pageIndex, pagination.pageSize, sortBy.field, sortBy.direction]);

    const fetchData = () => {
        const query = odata.orders({ pageIndex: 0, pageSize: 10 });

        fetchBatch();
        fetchOrdersTotal();
        fetchOrders(query);
    };

    const onPressEnter = (requisitionNumber) => {
        addOrder(requisitionNumber);
        setRequisitionNumber("");
    };

    const addToastError = ({ title = "Error", text = "Unexpected Error" } = {}) => {
        const toast = {
            id: `${Date.now() + toasts.length}`,
            title,
            text,
            color: 'danger'
        };
        setToasts([...toasts, toast]);
    };

    const removeToast = (removedToast) => {
        setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
    };

    const isLoading = isOrdersLoading || isLocationsLoading || isBatchLoading;

    const columns = [
        {
            field: 'requisitionNumber',
            name: 'Requisition Number',
        },
        {
            field: 'firstName',
            name: 'First Name',
        },
        {
            field: 'lastName',
            name: 'Last Name',
        },
        {
            field: 'requisitionNumber',
            render: (requisitionNumber) => {
                return (
                    <ConfirmationModal
                        modalTitle="Delete"
                        title="Delete order"
                        message={`Are you sure you want to delete the order ${requisitionNumber}?`}
                        request={{
                            method: 'delete',
                            url: `/batch/current/${locationId}`,
                            data: { requisitionNumber },
                        }}
                        onSuccess={fetchData}
                    />
                );
            }
        }
    ];

    return (
        <>
            <EuiPanel>
                <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiTitle>
                            {isLoading
                                ? (<h1>Loading...</h1>)
                                : (<h1>Batch Detail - {batch.locationName} ({batch.orgTestingCode})</h1>)
                            }
                        </EuiTitle>
                    </EuiFlexItem>

                    <EuiFlexItem />

                    <Show when={auth.isBatchFinalize && batch?.closed == false && batch?.submitted == false}>
                        <EuiFlexItem grow={false}>
                            <ConfirmationModal
                                modalTitle="Finalize"
                                title="Finalize order validation"
                                message="Are you sure you want to finalize?"
                                request={{
                                    method: 'post',
                                    url: `/batch/current/${locationId}/close`,
                                }}
                                onSuccess={fetchData}
                            />
                        </EuiFlexItem>
                    </Show>

                    <Show when={auth.isBatchSubmitToLab && batch?.closed == true && batch?.submitted == false}>
                        <EuiFlexItem grow={false}>
                            <ConfirmationModal
                                modalTitle="Submit order to lab"
                                title="Submit order to lab"
                                message="Are you sure you want to submit the order to the lab?"
                                request={{
                                    method: 'post',
                                    url: `/batch/${batchId}/submit`,
                                }}
                                onSuccess={fetchData}
                            />
                        </EuiFlexItem>
                    </Show>

                </EuiFlexGroup>

                <EuiHorizontalRule />

                <EuiFlexGroup justifyContent="spaceBetween" alignItems="flexEnd">
                    <EuiFlexItem>
                        <EuiFormRow label="Requisition Number">
                            <ScanTextField
                                onPressEnter={onPressEnter}
                                isLoading={isLoading}
                                value={requisitionNumber}
                                setValue={setRequisitionNumber}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <SortBy
                            value={sortBy}
                            onChange={setSortBy}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem />

                    <EuiFlexItem>
                        <Progress
                            isLoading={isLoading}
                            current={orders?.total}
                            total={ordersTotal}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>

                <EuiHorizontalRule />

                <EuiBasicTable
                    loading={isLoading}
                    columns={columns}
                    items={orders?.results}
                />

                <EuiSpacer />

                <Pagination
                    totalItems={orders?.total}
                    pagination={pagination}
                    onChange={onPaginationChange}
                />

                <EuiGlobalToastList
                    toasts={toasts}
                    dismissToast={removeToast}
                    toastLifeTimeMs={6000}
                />

            </EuiPanel>
        </>
    );
}

export default OrdersValidation;