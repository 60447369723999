import React from 'react';

import {
    EuiButtonEmpty,
} from '@elastic/eui';

function RefreshButton({ loadData }) {
    return (
        <EuiButtonEmpty
            size="xs"
            iconType="refresh"
            onClick={loadData}>
            <span>Refresh</span>
        </EuiButtonEmpty>
    );
}

export default RefreshButton;