import moment from "moment";

import { DateLabel, DateOfBirthLabel } from "modules/commons/components";

const columns = () => [
  {
    id: "requisitionNumber",
    display: "Requisition Number",
    displayAsText: "Requisition Number",
    initialWidth: 160,
  },

  {
    id: "firstName",
    display: "First Name",
    displayAsText: "First Name",
  },
  {
    id: "lastName",
    display: "Last Name",
    displayAsText: "Last Name",
  },
  {
    id: "dob",
    display: "Date Of Birth",
    displayAsText: "Date Of Birth",
    render: (date) => {
      return <DateOfBirthLabel date={date} />;
    },
  },
  {
    id: "testResult",
    display: "Test Result",
    displayAsText: "Test Result",
    render: (value) => value || "N/A",
  },
  {
    id: "labReceived",
    display: "Lab Received",
    displayAsText: "Lab Received",
    render: (date) => {
      const value = moment(date).isAfter("2000", "year");
      return value ? "Yes" : "No";
    },
  },
  {
    id: "phone",
    display: "Phone",
    displayAsText: "Phone",
  },
  {
    id: "locationName",
    display: "Location Name",
    displayAsText: "Location Name",
  },
  {
    id: "locationType",
    display: "Location Type",
    displayAsText: "Location Type",
  },
  {
    id: "testTypeName",
    display: "Test Type",
    displayAsText: "Test Type",
  },
  {
    id: "testTime",
    display: "Test Time",
    displayAsText: "Test Time",
    render: (date) => {
      return <DateLabel date={date} />;
    },
  },
  {
    id: "batchedClosed",
    display: "Batched Closed",
    displayAsText: "Batched Closed",
    render: (date) => {
      return <DateLabel date={date} />;
    },
  },
  {
    id: "resultTime",
    display: "Result Time",
    displayAsText: "Result Time",
    render: (date) => {
      return <DateLabel date={date} />;
    },
  },
  {
    id: "userEnteredOrder",
    display: "User Entered Order",
    displayAsText: "User Entered Order",
  },
  {
    id: "source",
    display: "Order Source",
    displayAsText: "Order Source",
  },
  {
    id: "paymentType",
    display: "Payment Type",
    displayAsText: "Payment Type",
  },
  {
    id: "vaccineLotNumber",
    display: "Vaccine Lot Number",
    displayAsText: "Vaccine Lot Number",
  },
  {
    id: "labConfirmedReceivedTime",
    display: "Lab Confirmed Received",
    displayAsText: "Lab Confirmed Received",
    render: (date) => {
      if (!date) return <>No</>;
      return <DateLabel date={date} />;
    },
  },
];

export default columns;
