import { EuiFlexItem, EuiFlexGroup, EuiTitle, EuiSpacer } from "@elastic/eui";

import OrderStats from "./OrderStats";
import BatchStats from "./BatchStats";
import AhtOrderStats from "./AhtOrderStats";
import AhtBatchStats from "./AhtBatchStats";
import CommunityCenterOrder from "./CommunityCenterOrder";
import CommunityCenterBatch from "./CommunityCenterBatch";

export default () => {
  return (
    <div>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiTitle>
            <h1>Community Center Dashboard</h1>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      <CommunityCenterOrder />
      <EuiSpacer />
      <CommunityCenterBatch />
      <EuiSpacer />

      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiTitle>
            <h1>AHT Dashboard</h1>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />

      <AhtOrderStats />
      <EuiSpacer />
      <AhtBatchStats />
      <EuiSpacer />

      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiTitle>
            <h1>DOE Dashboard</h1>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />

      <OrderStats />
      <EuiSpacer />
      <BatchStats />
    </div>
  );
};
