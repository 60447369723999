import React from 'react';

import { DateLabel, DateOfBirthLabel} from 'modules/commons/components';

import { OrderDetailModal } from './OrderModal';

function Columns({ loadData = () => { } }) {

    return [
        {
            field: 'requisitionNumber',
            name: 'Requisition Number',
            width: '20%',
            render: (value, order) => {
                return (
                    <OrderDetailModal
                        title={value}
                        orderId={order.id}
                        refreshTable={loadData}
                    />
                );
            }
        },
        {
            field: 'firstName',
            name: 'First Name',
        },
        {
            field: 'lastName',
            name: 'Last Name',
        },
        {
            field: 'phone',
            name: 'Phone',
        },
        {
            field: 'email',
            name: 'Email',
        },
        {
            field: 'dob',
            name: 'Date of birth',
            render: date => (
                <DateOfBirthLabel date={date} />
            ),
        },
        {
            field: 'testTime',
            name: 'Test Time',
            render: date => (
                <DateLabel date={date} />
            ),
        },
    ]

}

export default Columns;