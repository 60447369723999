import Handlebars from "handlebars";
import getLabelContent from "./labelFetcher";

Handlebars.registerHelper("upper", (str) => str && str.toUpperCase());

export default async function prepareLabel(model) {
  const template = await getCachedLabelTemplate();
  return template(model);
}

async function getCachedLabelTemplate() {
  const labelContent = await getLabelContent();
  const labelTemplate = Handlebars.compile(labelContent);

  return labelTemplate;
}
