import React, { useState, useEffect, useCallback, createContext } from "react";
import api from "networking";
export const Context = createContext();

export function Provider(props) {
  const [value, setValue] = useState({
    isLoading: false,
    settings: {},
  });

  const fetchSettings = useCallback(async () => {
    const response = await api.Settings.fetchSettings();
    setValue({
      isLoading: false,
      settings: response.data.results,
    });
  }, []);

  useEffect(() => {
    fetchSettings();
  }, []);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}
