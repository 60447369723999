import React, { useState, useMemo } from 'react';

import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiDataGrid,
} from '@elastic/eui';

import {
    Pagination,
} from 'modules/commons/components';

function Table({
    state = {},
    sort = null,
    columns = [],
    pagination = {},
    onPaginationChange = () => { },
    additionalControls = {},
    leadingControlColumns = [],
}) {

    const [visibleColumns, setVisibleColumns] = useState(() => {
        return columns.map(column => column.id);
    });

    const renders = useMemo(() => {
        return columns.reduce((_renders, column) => {
            _renders[column.id] = column.render ?? ((value) => value || '')
            return _renders;
        }, {});
    }, [columns]);

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId }) => {
            const columns = state?.items[rowIndex];
            const value = columns ? columns[columnId] : '';
            return renders[columnId](value, columns);
        };
    }, [state?.items, renders]);

    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem style={{ overflow: 'auto', overflowY: 'hidden' }}>
                    <EuiDataGrid
                        columns={columns}
                        columnVisibility={{
                            visibleColumns: visibleColumns,
                            setVisibleColumns: setVisibleColumns,
                        }}
                        toolbarVisibility={{
                            additionalControls,
                        }}
                        rowCount={state.items.length}
                        renderCellValue={renderCellValue}
                        sorting={sort}
                        leadingControlColumns={leadingControlColumns}
                        height="500px"
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <Pagination
                        pagination={pagination}
                        totalItems={state.total}
                        onChange={onPaginationChange}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
}

export default Table;