import { useState } from "react";

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from "@elastic/eui";

import { Show } from "modules/commons/components";

import { useAxios } from "modules/commons/hooks";

function ConfirmationModal({
  title = "Confirmation",
  message = "",
  modalTitle = "Open",
  cancelTitle = "Cancel",
  continueTitle = "Continue",
  color = "primary",
  request = {},
  onSuccess = () => {},
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const [isLoading, axiosRequest] = useAxios({
    onSuccess: () => {
      onSuccess();
      closeModal();
    },
    onFailure: (error) => {
      console.log(error);
    },
  });

  const executeAxiosRequest = () => axiosRequest(request);

  return (
    <>
      <EuiButton fill onClick={showModal} color={color}>
        <span>{modalTitle}</span>
      </EuiButton>
      <Show when={isModalVisible}>
        <EuiOverlayMask>
          <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <span>{message}</span>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButtonEmpty disabled={isLoading} onClick={closeModal}>
                <span>{cancelTitle}</span>
              </EuiButtonEmpty>
              <EuiButton
                fill
                isLoading={isLoading}
                onClick={executeAxiosRequest}>
                <span>{continueTitle}</span>
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      </Show>
    </>
  );
}

export default ConfirmationModal;
