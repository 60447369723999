import { useReducer } from 'react';

function useFormField({
    value = "",
    title = "",
    placeholder = "",
    disabled = false,
    validation = () => { },
}) {

    const initState = {
        value,
        title,
        placeholder,
        disabled,
        error: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);
    const onChange = (value) => dispatch({ type: 'onChange', payload: value });
    const onError = (error) => dispatch({ type: 'onError', payload: error });
    const setAsValid = () => dispatch({ type: 'setAsValid' });
    const disable = (payload = true) => dispatch({ type: 'disable', payload });
    const reset = () => dispatch({ type: 'reset', payload: initState });

    const validate = () => {
        try {
            validation(state.value);
            setAsValid();
        } catch (error) {
            onError(error);
            return error;
        }
    };

    return {
        state,
        value: state.value,
        onChange,
        onError,
        validate,
        setAsValid,
        disable,
        reset,
    };
}

function reducer(state, action) {
    switch (action.type) {
        case 'onChange':
            return {
                ...state,
                value: action.payload || ""
            };
        case 'onError':
            return {
                ...state,
                error: action.payload
            };
        case 'setAsValid':
            return {
                ...state,
                error: null,
            };
        case 'disable':
            return {
                ...state,
                disabled: action.payload
            };
        case 'reset':
            return { ...action.payload };
        default:
            throw new Error();
    };
}

export default useFormField;