import React, { useEffect, useState } from 'react';

import { EuiComboBox, EuiFormRow } from '@elastic/eui';

function ComboBoxField({
    state = {},
    options = [],
    onChange = () => { },
}) {
    const [selectOption, setSelectOption] = useState([]);

    useEffect(() => {

        const { id } = state?.value;
        const selected = options.find(option => option?.id === id);
        const selectedOptions = selected ? [selected] : [];

        setSelectOption(selectedOptions);

    }, [state?.value]);

    const _onChange = (values = []) => {

        if (values.length > 0) {
            const [value] = values;
            const location = options.find(option => option.label === value.label);
            onChange(location);
        } else {
            onChange({});
        }

        setSelectOption(values);
    }

    return (
        <EuiFormRow
            fullWidth
            label={state.title}
            helpText={state?.error?.message || ""}
        >
            <EuiComboBox
                fullWidth
                placeholder={state?.placeholder}
                singleSelection={{ asPlainText: true }}
                options={options}
                selectedOptions={selectOption}
                onChange={_onChange}
                isLoading={state.isLoading}
                isDisabled={state.disabled}
                isInvalid={!!state.error}
            />
        </EuiFormRow>
    );
}

export default ComboBoxField;