import axios from "utils/axios";

export async function getUrl({ file, tenant }) {
  const response = await axios({
    method: "GET",
    url: `/CardImages/url/${file}`,
    headers: {
      tenant,
    },
  });

  return response;
}
