import { ConfirmationModal } from "modules/commons/components";

const AppointmentCancelConfirmation = ({ id, onSuccess = () => {} }) => {
  const request = {
    method: "post",
    url: `/Appointment/${id}/time`,
  };

  return (
    <ConfirmationModal
      modalTitle="Cancel Appointment"
      title="Confirmation"
      onSuccess={onSuccess}
      message={`Are you sure you want to cancel this appointment?`}
      request={request}
      color="danger"
    />
  );
};

export default AppointmentCancelConfirmation;
