import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";

import * as locationUtils from "utils/location";

import {
  DateField,
  TextField,
  SelectField,
  SurveyJsQuestionnaireModalField,
  Show,
} from "modules/commons/components";

import options from "modules/commons/options";

import FormContext from "./FormContext";

function General({ testTypes }) {
  const context = useContext(FormContext);
  const [location] = context.location;

  const isDoe = locationUtils.isLocationTypeDoe(location);
  const isCommunity = locationUtils.isLocationTypeCommunityCenter(location);

  const selectedTestItem = testTypes.find(
    (x) => x.value == context.product_id.value
  );

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.requisition_number} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <Show when={isCommunity}>
        <Show when={!context.product_id.state.disabled}>
          <EuiSpacer size="m" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <SelectField {...context.product_id} options={testTypes} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </Show>

        <Show when={context.product_id.state.disabled}>
          <EuiSpacer size="m" />
          <EuiFlexGroup>
            <EuiFlexItem>
              <TextField {...context.test_type_name} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </Show>
      </Show>

      {context.requisition_number.value == "" &&
        selectedTestItem?.questionnaire && (
          <>
            <EuiSpacer size="m" />
            <EuiFlexGroup>
              <EuiFlexItem>
                <SurveyJsQuestionnaireModalField
                  {...context.questionnaire_response}
                  questionnaire={
                    selectedTestItem?.questionnaire
                  }></SurveyJsQuestionnaireModalField>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        )}
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <DateField {...context.test_time} />
        </EuiFlexItem>
        {isDoe ? (
          <EuiFlexItem>
            <SelectField {...context.id_type} options={options.occupations} />
          </EuiFlexItem>
        ) : (
          <EuiFlexItem />
        )}
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.guardian_phone} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField {...context.guardian_email} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default General;
