import React, { useState } from 'react';

import {
    EuiButton,
    EuiButtonEmpty,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
} from '@elastic/eui';

import { Show } from 'modules/commons/components';

function PhoneTypeModal({
    isModalVisible = false,
    closeModal = () => { },
    onClickCancel = () => { },
}) {

    const _onClickCancel = () => {
        onClickCancel();
        closeModal();
    };

    return (
        <>
            <Show when={isModalVisible}>
                <EuiOverlayMask>
                    <EuiModal onClose={_onClickCancel}>

                        <EuiModalHeader>
                            <EuiModalHeaderTitle>Confirmation</EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            <span>Does the patient accept to receive text messages?</span>
                        </EuiModalBody>

                        <EuiModalFooter>
                            <EuiButtonEmpty
                                onClick={_onClickCancel}
                            >
                                <span>Cancel</span>
                            </EuiButtonEmpty>
                            <EuiButton
                                fill
                                onClick={closeModal}
                            >
                                <span>Confirm</span>
                            </EuiButton>
                        </EuiModalFooter>

                    </EuiModal>
                </EuiOverlayMask>
            </ Show>
        </>
    );
};

export default PhoneTypeModal;