import { RawDateLabel, DateOfBirthLabel } from "modules/commons/components";

const columns = () => [
  {
    id: "appointmentTime",
    display: "Appointment Time",
    displayAsText: "Appointment Time",
    initialWidth: 160,
    render: (date) => {
      return <RawDateLabel date={date} />;
    },
  },
  {
    id: "patientFirstName",
    display: "First Name",
    displayAsText: "First Name",
  },
  {
    id: "patientLastName",
    display: "Last Name",
    displayAsText: "Last Name",
  },
  {
    id: "patientDob",
    display: "Date Of Birth",
    displayAsText: "Date Of Birth",
    render: (date) => {
      return <DateOfBirthLabel date={date} />;
    },
  },
  {
    id: "guardianPhone",
    display: "Phone",
    displayAsText: "Phone",
  },
  {
    id: "testingLocation",
    display: "Location Name",
    displayAsText: "Location Name",
  },
];

export default columns;
