import { useEffect, useState, useCallback, useMemo } from 'react';
import OData from 'utils/odataQuery';
import axios from 'utils/axios';

function useLocations({ locationType = "" } = {}) {
    const [isLoading, setIsLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [error, setError] = useState(null);

    const fetch = useCallback(async () => {

        try {
            setIsLoading(true);

            const query = odata();

            const response = await axios({
                method: 'get',
                url: `/location/filter/${query}`,
            });

            const results = (response?.data?.results || []);

            setLocations(results);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }

    }, []);

    const options = useMemo(() => {
        const _locations = (locations || []);

        return _locations.map(location => {

            const option = {
                id: location.id,
                label: `${location.schoolName} (${location.orgTestingCode})`
            };

            return option;
        });

    }, [locations?.length]);

    const odata = () => {
        const odata = new OData();

        odata.equals({
            name: 'locationType',
            value: locationType,
        });

        return odata.build();
    };

    useEffect(() => {
        if (locations.length < 1) {
            fetch();
        }
    }, [fetch]);

    return {
        fetch,
        error,
        isLoading,
        locations,
        options,
    }

}

export default useLocations;