import { EuiButton, EuiConfirmModal } from "@elastic/eui";
import { SelectField } from "modules/commons/components";
import { useFormField } from "modules/commons/hooks";
import downloadFile from "utils/downloadFile";
import { useState } from "react";
import useSendResults from "./useSendResults";

const options = [
  {
    value: "",
    text: "Select method",
  },
  {
    value: "email",
    text: "Email",
  },
  {
    value: "phone",
    text: "Phone",
  },
  {
    value: "download",
    text: "Download",
  },
];

interface SendResultModalProps {
  requisitionNumber: string;
}

const SendResultModal: React.FC<SendResultModalProps> = ({
  requisitionNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading, sendResults } = useSendResults();
  const field = useFormField({ title: "Send method" });

  const onClick = () => {
    if (["phone", "email"].includes(field.state.value)) {
      sendResults(field.state.value as any, requisitionNumber);
      setIsOpen(false);
    }
    if (["download"].includes(field.state.value)) {
      downloadTestResults(requisitionNumber);
      setIsOpen(false);
    } else {
      alert("Please select a valid option");
    }
  };

  const downloadTestResults = async (requisitionNumber) => {
    await downloadFile({
      url: `OrderResults/${requisitionNumber}.pdf`,
      accept: "application/pdf",
      filename: `TestResults_${requisitionNumber}.pdf`,
    });
  };

  return (
    <>
      <EuiButton fill onClick={() => setIsOpen(true)}>
        Send Results
      </EuiButton>
      {isOpen && (
        <EuiConfirmModal
          title="Send users results"
          isLoading={isLoading}
          confirmButtonText={"Confirm"}
          onConfirm={onClick}
          cancelButtonText={"Cancel"}
          onCancel={() => setIsOpen(false)}>
          <SelectField {...field} options={options} />
        </EuiConfirmModal>
      )}
    </>
  );
};

export default SendResultModal;
