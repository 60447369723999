import React, { useContext } from "react";

import { EuiIcon, EuiHeader, EuiHeaderSectionItemButton } from "@elastic/eui";

import { Logo } from "modules/commons/components";

import SelectTenant from "./components/SelectTenant";

import { MenuContext } from "./MenuContext";

function MenuButton() {
  const [, setContext] = useContext(MenuContext);

  const onClickContext = () =>
    setContext((context) => {
      return {
        ...context,
        isOpen: !context.isOpen,
        isDocked: true,
      };
    });

  return (
    <EuiHeaderSectionItemButton onClick={onClickContext}>
      <EuiIcon type="menu" size="m" />
    </EuiHeaderSectionItemButton>
  );
}

function Header() {
  const sections = [
    {
      items: [<MenuButton />],
    },
    {
      items: [<SelectTenant />, <Logo />],
    },
  ];

  return (
    <div className="Header">
      <EuiHeader position="fixed" sections={sections} />
    </div>
  );
}

export default Header;
