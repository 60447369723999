import axios from "utils/axios";

export async function UpdateTime({ id, appointmentTime }) {
  const response = await axios({
    method: "POST",
    url: `/Appointment/${id}/time?date=${appointmentTime}`,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}
