import utils from 'modules/commons/utils';

const fields = [
    "policyNumber",
    "phone",
    "phoneType",
    "email",

    "firstName",
    "lastName",
    "dob",
    "gender",
    "ethnicity",
    "race",

    "buildingNumber",
    "street",
    "apt",
    "city",
    "state",
    "zip",

    "vaccineAdministeringSite",
    "vaccineExpirationDate",
    "vaccineLotNumber"
];

function validate(form) {
    const requiredFields = [...fields];

    const results = utils.form.runValidations({
        form,
        requiredFields,
    });

    return results;
}

export default validate;