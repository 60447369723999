import React from 'react';

import {
    EuiCallOut,
} from '@elastic/eui';

function OrderModalAlert({ show = false }) {
    if (!show) { return null; }

    return (
        <>
            <EuiCallOut
                title="Alert"
                color="danger"
                iconType="alert"
            >
                <p>Please confirm with faculty that patient consent is on file.</p>
            </EuiCallOut >
        </>
    );
}

export default OrderModalAlert;