import React from 'react';

import {
    EuiFormRow,
    EuiSelect,
} from '@elastic/eui';

function SelectField({
    state = {},
    options = [],
    onChange = () => { },
} = {}) {

    return (
        <EuiFormRow
            fullWidth
            label={state.title}
            helpText={state?.error?.message || ""}
        >
            <EuiSelect
                fullWidth
                value={state.value}
                disabled={state.disabled}
                isInvalid={!!state.error}
                isLoading={state.isLoading}
                onChange={e => onChange(e.target.value)}
                options={options}
            />
        </EuiFormRow>
    );
}

export default SelectField;