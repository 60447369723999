import React from 'react';


import {
    DateLabel,
} from 'modules/commons/components';

const columns = [
    {
        id: "detected",
        display: "Detected",
        displayAsText: "Detected"
    },
    {
        id: "inconclusive",
        display: "Inconclusive",
        displayAsText: "Inconclusive"
    },
    {
        id: "locationType",
        display: "LocationT ype",
        displayAsText: "Location Type"
    },
    {
        id: "notDetected",
        display: "Not Detected",
        displayAsText: "Not Detected",
    },
    {
        id: "orgTestingCode",
        display: "Org Testing Code",
        displayAsText: "Org Testing Code"
    },
    {
        id: "tatLessThan48",
        display: "Tat Less Than 48",
        displayAsText: "Tat Less Than 48"
    },
    {
        id: "tatMoreThan48",
        display: "Tat More Than48",
        displayAsText: "Tat More Than48"
    },
    {
        id: "testTime",
        display: "Test Time",
        displayAsText: "Test Time",
        render: (date) => {
            return (
                <DateLabel date={date} format="MM/DD/YYYY" />
            )
        },
    },
  
];

export default columns;