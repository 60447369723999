import Page from "./Page";
import Header from "./Header";
import SideNav from "./SideNav";
import { MenuProvider } from "./MenuContext";
import { Provider as GlobalProvider } from "../global";

import Template from "./Template";

function Home() {
  return (
    <div className="Dashboard">
      <GlobalProvider>
        <MenuProvider>
          <Header />
          <Template
            panelled={true}
            sidebarSticky={false}
            restrictWidth={false}
            grow={true}
            content={<Page />}
            sidebar={<SideNav />}></Template>
        </MenuProvider>
      </GlobalProvider>
    </div>
  );
}

export default Home;
