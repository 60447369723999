import { useEffect } from "react";
import { Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Home from "modules/home";
import { FullPageLoader } from "modules/commons/components";
import { setTokenFactory } from "services/authentication";
import history from "utils/history";
import { QZPrintProvider } from "services/qz-label-printing";
import { EuiProvider } from "@elastic/eui";

import "./App.css";

function App() {
  const { getAccessTokenSilently } = useAuth0();
  setTokenFactory(getAccessTokenSilently);

  useEffect(() => {
    const qzProvider = QZPrintProvider.getInstance();
    qzProvider.initialize();
    return () => qzProvider.disconnect();
  }, []);

  return (
    <div className="App">
      <Router history={history}>
        <CompatRouter>
          <EuiProvider colorMode="light">
            <Home />
          </EuiProvider>
        </CompatRouter>
      </Router>
    </div>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <FullPageLoader />,
});
