import React from 'react';
import logo from 'assets/logo.png';

function Logo() {
    return (
        <img
            alt="Logo"
            className="Logo"
            style={{ height: 48, padding: 8 }}
            src={logo}
        />
    );
}

export default Logo;