import { Switch, Route } from "react-router-dom";

import {
  PrivateRoute,
  PrivateRouteV5,
  EmptyPage,
} from "modules/commons/components";
import { useAuthorize } from "modules/commons/hooks";
import { Settings } from "modules/settings";
import { ViewCardImage } from "modules/viewcardimage";
import Dashboard from "modules/dashboard";
import Orders from "modules/orders";
import OrdersReports from "modules/orders-reports";
import Vaccines from "modules/vaccines";
import VaccinesReports from "modules/vaccines-reports";
import ResumeReports from "modules/resume-reports";
import VaccineAdministration from "modules/vaccine-administration";
import Appointments from "modules/appointments";
import Prescriptions from "modules/prescriptions";
import Batch from "modules/batch";
import BatchDetail from "modules/batch-detail";
import BulkPrintLabels from "modules/bulk-print-labels";
import RepidResults from "modules/rapid-results";
import { HomeTestingTeam, HomeTestingOrders } from "modules/home-testing";

function Page() {
  const auth = useAuthorize();

  return (
    <Switch>
      <PrivateRoute exact path="/" component={Dashboard} isAuthorized={auth.canViewDashboard} />
      <PrivateRoute path="/orders" component={Orders} isAuthorized={auth.isTester} />
      <PrivateRoute path="/batch/:batchId" component={BatchDetail} />
      <PrivateRoute
        path="/bulk-print-labels"
        component={BulkPrintLabels}
        isAuthorized={auth.canBulkPrint}
      />
      <PrivateRoute
        path="/batch"
        component={Batch}
        isAuthorized={auth.isBatchSubmitToLab || auth.isBatchFinalize}
      />
      <PrivateRoute
        path="/orders-reports"
        component={OrdersReports}
        isAuthorized={auth.isReporter}
      />
      <PrivateRoute
        path="/vaccines-reports"
        component={VaccinesReports}
        isAuthorized={auth.isReporter && !auth.isElite}
      />
      <PrivateRoute
        path="/resume-reports"
        component={ResumeReports}
        isAuthorized={auth.isReporter && !auth.isElite}
      />
      <PrivateRoute
        path="/rapid-results"
        component={RepidResults}
        isAuthorized={auth.isReporter}
      />
      <PrivateRoute
        path="/vaccine"
        component={VaccineAdministration}
        isAuthorized={auth.isVaccine && !auth.isElite}
      />
      <PrivateRoute
        path="/appointments"
        component={Appointments}
        isAuthorized={auth.isVaccine && !auth.isElite}
      />
      <PrivateRoute
        path="/vaccines"
        component={Vaccines}
        isAuthorized={auth.isVaccine && !auth.isElite}
      />
      <PrivateRoute
        exact
        path="/home-testing"
        component={HomeTestingTeam}
        isAuthorized={auth.isAtHomeTesting}
      />
      <PrivateRoute
        path="/home-testing/:id"
        component={HomeTestingOrders}
        isAuthorized={auth.isAtHomeTesting}
      />
      <PrivateRoute
        path="/prescriptions"
        component={Prescriptions}
        isAuthorized={auth.isWritePrescriptions}
      />
      <PrivateRoute path="/settings" component={Settings} />
      <PrivateRouteV5
        path="/viewcardimage/:tenant/:url*"
        component={ViewCardImage}
      />
      <Route path="*" component={EmptyPage} />
    </Switch>
  );
}

export default Page;
