import axios from "utils/axios";

async function getRemoteFile({
  url,
  body,
  method = "GET",
  accept,
  responseType,
}) {
  const response = await axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json",
      Accept: accept ?? undefined,
    },
    responseType: responseType ?? undefined,
    data: body,
  });

  return response.data;
}

export default getRemoteFile;
