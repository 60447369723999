import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFlyoutFooter,
  EuiButton,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButtonEmpty,
  EuiLoadingSpinner,
  EuiButtonIcon,
  EuiCallOut,
} from "@elastic/eui";

import {
  ConfirmationModal,
  HideWhen,
  Show,
  ShowWhen,
} from "modules/commons/components";
import { useAuthorize, useDebounce } from "modules/commons/hooks";

import { useShowModal, useAxios } from "modules/commons/hooks";

import utils from "modules/commons/utils";
import * as locationUtils from "utils/location";

import { FormContext } from "./form";

import dateOfBirthDateParser from "utils/dateOfBirthDateParser";
import { RegistrationModal } from "modules/registration";

import OrderForm from "./OrderForm";
import OrderModalAlert from "./OrderModalAlert";
import ValidationModal from "./ValidationModal";
import useCreateOrder from "./useCreateOrder";
import usePrintLabel from "./usePrintLabel";
import usePathOrder from "./usePathOrder";
import SendResultModal from "./SendResultModal";
import downloadFile from "utils/downloadFile";
import { OrdersTodayWithPreregistration } from "./OrdersTodayWithPreregistration";
import useOrdersWithPreregistration from "./useOrdersWithPreregistration";
import api from "networking";
import useCheckSameDayOrdersForSamePatient from "./useCheckSameDayOrdersForSamePatient";

export function OrderDetailModal({
  isOpen = false,
  title = "",
  orderId = null,
  editing = false,
  refreshTable = () => {},
  onClose = () => {},
}) {
  const form = useContext(FormContext);
  const [, setRegistration] = form.registration;
  const { closeModal, openModal, isVisible } = useShowModal(isOpen);

  const _openModal = () => {
    utils.form.resetFields(form);
    openModal();
    setRegistration({});
  };

  const _closeModal = () => {
    utils.form.resetFields(form);
    closeModal();
    setRegistration({});
    onClose?.();
  };

  return (
    <>
      <EuiButtonEmpty onClick={_openModal}>
        <span>{title}</span>
      </EuiButtonEmpty>
      <Show when={isVisible}>
        <OrderModal
          editing={editing}
          orderId={orderId}
          closeModal={_closeModal}
          refreshTable={refreshTable}
        />
      </Show>
    </>
  );
}

export function NewOrderModal({ disabled = true, refreshTable = () => {} }) {
  const form = useContext(FormContext);
  const [, setRegistration] = form.registration;
  const { closeModal, openModal, isVisible } = useShowModal();

  const _openModal = () => {
    utils.form.resetFields(form);
    openModal();
    setRegistration({});
  };

  const _closeModal = () => {
    utils.form.resetFields(form);
    closeModal();
    setRegistration({});
  };

  return (
    <>
      <EuiButton fill disabled={disabled} onClick={_openModal}>
        <span>New Order</span>
      </EuiButton>
      <Show when={isVisible}>
        <OrderModal closeModal={_closeModal} refreshTable={refreshTable} />
      </Show>
    </>
  );
}

function OrderModal({
  editing = false,
  orderId = null,
  closeModal,
  refreshTable,
}) {
  const form = useContext(FormContext);
  const auth = useAuthorize();

  const [registration, setRegistration] = form.registration;
  const [location] = form.location;

  const {
    openModal: openValidationModel,
    closeModal: closeValidationModal,
    isVisible: isValidationModalVisible,
  } = useShowModal();

  const [showSendResults, setShowSendResults] = useState(false);

  const { pathOrder, isLoading: isPathOrderLoading } = usePathOrder();
  const [testTypes, setTestTypes] = useState([]);

  const debouncePatientInfoChanged = useDebounce(
    {
      firstName: form.patient_first_name.value?.trim(),
      lastName: form.patient_last_name.value?.trim(),
      dob: form.patient_dob.value,
    },
    3000
  );

  const [ordersForSamePatient, hasOrderForSamePatientToday] =
    useCheckSameDayOrdersForSamePatient({
      patientInfo: debouncePatientInfoChanged,
    });

  useEffect(() => {
    const fetch = async () => {
      const response = await api.Products.fetchList();

      const products = response.data.results.map((product) => {
        return {
          value: product.id.toString(),
          text: `${product.name} ($${product.price})`,
          questionnaire: product.questionnaire,
        };
      });

      setTestTypes([
        {
          value: "",
          text: "Select test type",
        },
        ...products,
      ]);
    };
    fetch();
  }, []);

  const [isFetchOrderLoading, fetchOrder] = useAxios({
    onSuccess: (response) => {
      const showSendResults = shouldShouldSendResults(
        response?.data?.testResult
      );

      setShowSendResults(showSendResults);

      orderToForm({ order: response.data, form });

      if (editing) {
        enableEditingFields(form);
      }
    },
    onFailure: (error) => {
      alert(error);
      closeModal();
    },
  });

  const fetchOrderById = () =>
    fetchOrder({
      method: "get",
      url: `/Order/${orderId}`,
    });

  const { ordersForPreRegistration, hasOrderForToday } =
    useOrdersWithPreregistration({
      preregistrationId: registration.id,
    });

  const { state: createOrderState, createOrder } = useCreateOrder({
    form,
    location: location,
    preregistrationId: registration?.id,
    onSuccess: (requisitionNumber, shouldBatch) => {
      const isCommunity = locationUtils.isLocationTypeCommunityCenter(location);

      if (isCommunity) {
        refreshTableAndClose();
        return;
      }

      if (shouldBatch) {
        form.requisition_number.onChange(requisitionNumber);
        openValidationModel();
      } else {
        refreshTableAndClose();
      }
    },
  });

  const { isLoading: isPrintLabelLoading, printLabel } = usePrintLabel({
    requisitionNumber: form.requisition_number.state.value,
    form,
  });

  useEffect(() => {
    if (!orderId) {
      return;
    }
    fetchOrderById();
  }, [orderId]);

  const showConsentAlert =
    registration?.consent === false && registration.idType == "Student";

  const onClickRow = (registration) => {
    registrationToForm({ order: registration, form, location });
    setRegistration(registration);
  };

  const refreshTableAndClose = () => {
    refreshTable();
    closeModal();
  };

  const downloadChart = async (requisitionNumber) => {
    await downloadFile({
      url: `Chart/download/${requisitionNumber}.pdf`,
      accept: "application/pdf",
      filename: `BillingChart_${requisitionNumber}.pdf`,
    });
  };

  const downloadPaymentReceipt = async (requisitionNumber) => {
    await downloadFile({
      url: `PaymentReport/download/${requisitionNumber}.pdf`,
      accept: "application/pdf",
      filename: `PaymentReceipt_${requisitionNumber}.pdf`,
    });
  };

  const voidOrder = async (orderId) => {
    await api.orders.voidOrder(orderId);
  };

  const onPathOrder = () => {
    pathOrder({
      orderId,
      firstName: form.patient_first_name.value,
      lastName: form.patient_last_name.value,
      gender: form.patient_gender.value,
      dob: dateOfBirthDateParser({
        date: form.patient_dob.value,
        format: "yyyy-MM-dd",
      }),
      phone: form.guardian_phone.value,
      email: form.guardian_email.value,
      passportNumber: form.patient_passport_number.value,
      publicNote: form.patient_public_note.value,
    });
  };

  if (isFetchOrderLoading) {
    return <EuiLoadingSpinner />;
  }

  return (
    <EuiFlyout ownFocus onClose={closeModal} aria-labelledby="flyoutTitle">
      <EuiFlyoutHeader hasBorder>
        <EuiSpacer />
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2 id="flyoutTitle">
                {orderId ? `Order Detail (${orderId})` : "New Order"}
              </h2>
            </EuiTitle>
          </EuiFlexItem>

          <Show when={!orderId}>
            <EuiFlexItem grow={false}>
              <RegistrationModal
                disabled={!location.orgTestingCode}
                onClickRow={onClickRow}
              />
            </EuiFlexItem>
          </Show>
        </EuiFlexGroup>
      </EuiFlyoutHeader>

      <EuiFlyoutBody banner={<OrderModalAlert show={showConsentAlert} />}>
        <OrdersTodayWithPreregistration
          orders={ordersForPreRegistration.items}
        />
        <HideWhen
          when={(settings) =>
            hasOrderForToday && settings.SingleOrderPerDayFromPreRegistration
          }>
          <OrderForm testTypes={testTypes} />
        </HideWhen>
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexGroup>
            <Show when={auth.isExportBilling}>
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  display="base"
                  size="s"
                  iconType="download"
                  aria-label="Download Billing Chart"
                  onClick={() =>
                    downloadChart(form.requisition_number.state.value)
                  }
                />
              </EuiFlexItem>
            </Show>
            <Show when={orderId}>
              <Show when={auth.canVoidOrder}>
                <EuiFlexItem grow={false}>
                  <ConfirmationModal
                    modalTitle="Void Order"
                    title="Void Order"
                    message="Are you sure you want to void the order?"
                    color="warning"
                    request={{
                      method: "post",
                      url: `/order/void/${orderId}`,
                    }}
                  />
                </EuiFlexItem>
              </Show>
            </Show>
          </EuiFlexGroup>
          <EuiFlexGroup justifyContent="flexEnd">
            <Show when={!orderId}>
              <HideWhen
                when={(settings) =>
                  (hasOrderForToday &&
                    settings.SingleOrderPerDayFromPreRegistration) ||
                  (hasOrderForSamePatientToday && settings.SingleOrderPerDay)
                }>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill
                    isLoading={createOrderState.isLoading}
                    disabled={!!form.requisition_number.state.value}
                    onClick={createOrder}
                    color="primary">
                    <span>Print Label &amp; Save</span>
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <ValidationModal
                    locationId={location?.id}
                    requisitionNumber={form.requisition_number.state.value}
                    onSuccess={refreshTableAndClose}
                    isVisible={isValidationModalVisible}
                    onClose={closeValidationModal}
                  />
                </EuiFlexItem>
              </HideWhen>

              <ShowWhen
                when={(settings) =>
                  hasOrderForSamePatientToday && settings.SingleOrderPerDay
                }>
                <EuiFlexItem grow={true}>
                  <EuiCallOut
                    title="Can't create order"
                    color="danger"
                    iconType="alert">
                    <p>There are other orders today for this person.</p>
                  </EuiCallOut>
                </EuiFlexItem>
              </ShowWhen>
            </Show>

            <Show when={orderId}>
              <Show when={showSendResults}>
                <EuiFlexItem grow={false}>
                  <SendResultModal
                    requisitionNumber={form.requisition_number.state.value}
                  />
                </EuiFlexItem>
              </Show>
              <Show when={editing}>
                <Show when={form.paymentType.state.value === "creditcard"}>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      fill
                      isLoading={isPrintLabelLoading}
                      onClick={() =>
                        downloadPaymentReceipt(
                          form.requisition_number.state.value
                        )
                      }
                      color="primary">
                      <span>Download Payment Receipt</span>
                    </EuiButton>
                  </EuiFlexItem>
                </Show>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill
                    isLoading={isPrintLabelLoading}
                    onClick={printLabel}
                    color="primary">
                    <span>Print Label</span>
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill
                    isLoading={isPathOrderLoading}
                    onClick={onPathOrder}
                    color="danger">
                    <span>Save</span>
                  </EuiButton>
                </EuiFlexItem>
              </Show>
              <Show when={!editing}>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill
                    isLoading={isPrintLabelLoading}
                    onClick={printLabel}
                    color="primary">
                    <span>Print Label</span>
                  </EuiButton>
                </EuiFlexItem>
              </Show>
            </Show>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
}

function orderToForm({ order, form }) {
  const dob = dateOfBirthDateParser({ date: order.dob });
  form.patient_dob.onChange(dob);

  const testTime = moment(order.testTime);
  form.test_time.onChange(testTime);

  form.test_type_id.onChange(order.testTypeId);
  form.test_type_name.onChange(order.testTypeName);

  form.id_type.onChange(order.idType);
  form.patient_first_name.onChange(order.firstName);
  form.patient_last_name.onChange(order.lastName);
  form.patient_gender.onChange(order.gender);

  form.patient_passport_number.onChange(order.passportNumber);
  form.patient_public_note.onChange(order.publicNote);

  form.patient_address1.onChange(order.street);
  form.patient_address2.onChange(order.apt);
  form.patient_state.onChange(order.state);
  form.patient_city.onChange(order.city);
  form.patient_zip.onChange(order.zip);

  form.guardian_first.onChange(order.guardianFirst);
  form.guardian_last.onChange(order.guardianLast);
  form.guardian_phone.onChange(order.phone);
  form.guardian_email.onChange(order.email);
  form.guardian_relationship.onChange(order.guardianRelationship);

  form.paymentType.onChange(order.paymentType ?? "insurance");
  form.creditCardId.onChange(order.creditCardId ?? null);

  form.insuranceProvider.onChange(order.insuranceProvider);
  form.insuranceMemberName.onChange(order.insuranceMemberName);
  form.insuranceGroupNumber.onChange(order.insuranceGroupNumber);
  form.insuranceMemberNumber.onChange(order.insuranceMemberNumber);
  form.insuranceCardPictureFileName.onChange(
    order.insuranceCardPictureFileName
  );

  form.requisition_number.onChange(order.requisitionNumber);

  disableOrderFields(form);
}

function registrationToForm({ order, form, location }) {
  const dob = dateOfBirthDateParser({ date: order.patientDob });
  form.patient_dob.onChange(dob);

  const testTime = moment(order.testTime);
  form.test_time.onChange(testTime);

  form.id_type.onChange(order.idType);
  form.patient_first_name.onChange(order.patientFirstName);
  form.patient_last_name.onChange(order.patientLastName);
  form.patient_gender.onChange(order.patientGender);

  form.patient_address1.onChange(order.patientAddress1);
  form.patient_address2.onChange(order.patientAddress2);
  form.patient_state.onChange(order.patientState);
  form.patient_city.onChange(order.patientCity);
  form.patient_zip.onChange(order.patientZip);

  form.guardian_first.onChange(order.guardianFirst);
  form.guardian_last.onChange(order.guardianLast);
  form.guardian_phone.onChange(order.guardianPhone);
  form.guardian_email.onChange(order.guardianEmail);
  form.guardian_relationship.onChange(order.guardianRelationship || "Other");

  form.paymentType.onChange(order.paymentType);
  form.creditCardId.onChange(order.creditCardId ?? null);

  form.insuranceProvider.onChange(
    order.additionalInformation.insuranceProvider
  );
  form.insuranceMemberName.onChange(
    order.additionalInformation.insuranceMemberName
  );
  form.insuranceGroupNumber.onChange(
    order.additionalInformation.insuranceGroupNumber
  );
  form.insuranceMemberNumber.onChange(
    order.additionalInformation.insuranceMemberNumber
  );
  form.insuranceCardPictureFileName.onChange(
    order.additionalInformation.insuranceCardPictureFileName
  );

  const isCommunity = locationUtils.isLocationTypeCommunityCenter(location);

  if (isCommunity) {
    form.product_id.onChange(0);
    return;
  }

  disablePreregistrationFields(form, order.idType);
}

function enableEditingFields(form) {
  form.guardian_phone.disable(false);
  form.guardian_email.disable(false);
  form.patient_dob.disable(false);
  form.patient_first_name.disable(false);
  form.patient_last_name.disable(false);
  form.patient_gender.disable(false);
  form.patient_passport_number.disable(false);
  form.patient_public_note.disable(false);
}

function disablePreregistrationFields(form, idType) {
  const fields = ["guardian_phone", "guardian_email"];

  Object.keys(form).forEach((key) => {
    const field = form[key];
    if ((idType === "Staff" && fields.includes(key)) || key === "test_time") {
      field?.disable?.(false);
    } else {
      field?.disable?.();
    }
  });
}

function disableOrderFields(form) {
  Object.keys(form).forEach((key) => {
    const field = form[key];
    field?.disable?.();
  });
}

function shouldShouldSendResults(testResults = "") {
  return ["Detected", "NotDetected", "Inconclusive"].includes(testResults);
}
