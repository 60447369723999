import { LabelPrinter } from "../label-printing";
import prepareLabel from "./labelGenerator";
import { printLabel, previewLabel } from "./labelPrinter";

export const dymoLabelPrinter: LabelPrinter = {
  printModel: async (model: any) => {
    const label = await prepareLabel(model);
    await printLabel(label);
  },
  previewModel: async (model: any) => {
    const label = await prepareLabel(model);
    await previewLabel(label);
  },
};
