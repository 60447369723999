import axios from 'utils/axios';

export async function fetchOpenOrders(params) {

    const response = await axios({
        method: 'GET',
        url: `/Order/open/${params}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;

}

export async function fetchOrders(params) {

    const response = await axios({
        method: 'GET',
        url: `/Order/${params}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;

}

export async function fetchOrder(orderId) {

    const response = await axios({
        method: 'GET',
        url: `/Order/${orderId}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;

}

export async function saveOrder(order) {

    const response = await axios({
        method: 'POST',
        url: `/Order/FromProduct`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: order,
    });

    return response;

}

export async function voidOrder(orderId) {

    const response = await axios({
        method: 'POST',
        url: `/Order/void/${orderId}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
}