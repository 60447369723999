import axios from 'utils/axios';

export async function fetchRegistrations(params) {

    const response = await axios({
        method: 'GET',
        url: `/Preregistration${params}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
}