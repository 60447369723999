import axios from 'utils/axios';

export async function fetchVaccines(query) {

    const response = await axios({
        method: 'GET',
        url: `/vaccineorder${query}`,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;

}