import React from 'react';

import { DateLabel, DateOfBirthLabel } from 'modules/commons/components';

import { VaccineDetailModal } from './VaccineModal';

function Columns() {

    return [
        {
            field: 'id',
            name: 'View',
            width: '10%',
            render: (_, vaccine) => {
                return (
                    <VaccineDetailModal
                        title="View"
                        vaccine={vaccine}
                    />
                );
            }
        },
        {
            field: 'firstName',
            name: 'First Name',
        },
        {
            field: 'lastName',
            name: 'Last Name',
        },
        {
            field: 'phone',
            name: 'Phone',
        },
        {
            field: 'email',
            name: 'Email',
        },
        {
            field: 'dob',
            name: 'Date of birth',
            render: date => (
                <DateOfBirthLabel date={date} />
            ),
        },
        {
            field: 'firstDoseDate',
            name: 'First Dose Date',
            render: date => (
                <DateLabel date={date} format="MM/DD/YYYY" />
            ),
        },
    ]

}

export default Columns;