import OData from 'utils/odataQuery';

export function registration({
    testingLocation = "1",
    patientDob = "",
    patientFirstName = "",
    patientLastName = "",
    consent = "",
    testingDate = "",
    pageIndex = 1,
    pageSize = 10,
}) {

    const odata = new OData();

    odata.params = {
        ...odata.params,
        orderBy: ['patientDob'],
    };

    if (consent && consent.length > 0) {
        odata.params.filter.push({
            consent: consent === "true"
        });
    }

    odata.params.filter.push({ active: true });

    odata
        .pagination({
            pageIndex: pageIndex,
            pageSize: pageSize
        })
        .equals({
            name: 'testingLocation',
            value: testingLocation,
        })
        .contains({
            name: 'patientFirstName',
            value: patientFirstName,
        })
        .contains({
            name: 'patientLastName',
            value: patientLastName,
        })
        .dateEquals({
            name: 'patientDob',
            value: patientDob,
        })
        .dateEquals({
            name: 'testingDate',
            value:  testingDate,
        });

    return odata.build();
}