import React from 'react';

import {
    EuiPopover,
    EuiButton,
    EuiPopoverFooter,
} from '@elastic/eui';

import { useShowModal } from 'modules/commons/hooks';

function Popover({
    title = "Open",
    buttonTitle = "Close",
    width = "300px",
    onClick = () => { },
    children
}) {
    const { closeModal, openModal, isVisible } = useShowModal(false);

    const _onClick = () => {
        onClick();
        closeModal();
    };

    const button = (
        <EuiButton onClick={openModal}>
            <span>{title}</span>
        </EuiButton>
    );

    return (
        <EuiPopover
            button={button}
            isOpen={isVisible}
            closePopover={closeModal}
            anchorPosition="upCenter"
        >
            {/* <EuiPopoverTitle>Sort By</EuiPopoverTitle> */}
            <div style={{ width }}>{children}</div>
            <EuiPopoverFooter>
                <EuiButton fill fullWidth onClick={_onClick}>
                    <span>{buttonTitle}</span>
                </EuiButton>
            </EuiPopoverFooter>
        </EuiPopover>
    );
};

export default Popover;