import * as Orders from "./orders";
import * as Vaccines from "./vaccines";
import * as Locations from "./locations";
import * as Registration from "./registration";
import * as Settings from "./settings";
import * as QzPrint from "./qz-print";
import * as CardImage from "./card-images";
import * as CreditCard from "./credit-card";
import * as Products from "./products";
import * as PatientRegistration from "./patient-registration";
import * as Appointment from "./appointment";
import * as Files from "./files";

const networking = {
  Orders,
  Vaccines,
  Locations,
  Registration,
  Settings,
  QzPrint,
  CardImage,
  CreditCard,
  Products,
  PatientRegistration,
  Appointment,
  Files,
};

export default networking;
