import { useContext, useState } from "react";
import { MenuContext } from "modules/home/MenuContext";

const useAutoCloseMenu = () => {
  const [context, setContext] = useContext(MenuContext);

  const closeMenu = () =>
    setContext((context) => {
      return {
        ...context,
        isOpen: false,
        isDocked: true,
      };
    });

  useState(() => {
      if(!context.isOpen){ return; }  
      setTimeout(() => closeMenu(), 100);
  }, []);
};

export default useAutoCloseMenu;
