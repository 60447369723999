import React from 'react';

import {
    EuiButtonEmpty,
} from '@elastic/eui';

import history from 'utils/history';

import {
    DateLabel,
} from 'modules/commons/components';

const columns = () => {

    return [
        {
            render: (batch) => {
                const url = `/batch/${batch.id}?locationId=${batch.locationId}`;
                const onClickView = () => history.push(url);

                return (
                    <EuiButtonEmpty
                        onClick={onClickView}
                    >
                        <span>View</span>
                    </EuiButtonEmpty>
                );
            }
        },
        {
            name: 'Location',
            render: (batch) => {
                return `${batch.locationName} (${batch.orgTestingCode})`;
            }
        },
        {
            field: 'numberOfOrders',
            name: 'Number Of Orders',
        },
        {
            field: 'submitted',
            name: 'Finalize',
        },
        {
            field: 'closed',
            name: 'Status',
            render: (isClose) => {
                return isClose ? "Close" : "Open";
            }
        },
        {
            field: 'createdDate',
            name: 'Created Date',
            render: date => (
                <DateLabel
                    date={date}
                />
            ),
        },
        {
            field: 'closedDate',
            name: 'Closed Date',
            render: date => (
                <DateLabel
                    date={date}
                />
            ),
        },
    ];
};

export default columns;