import React from "react";

import { EuiCallOut, EuiSpacer } from "@elastic/eui";

const PrintAlert = ({ errors = {} }) => {
  const keys = Object.keys(errors);

  if (keys.length < 1) {
    return null;
  }

  return (
    <>
      <EuiCallOut
        title="Sorry, there was an error while printing the labels."
        color="danger"
        iconType="alert"
      >
        <ul>
          {keys.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </EuiCallOut>
      <EuiSpacer />
    </>
  );
};

export default PrintAlert;
