import { useContext } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from "@elastic/eui";
import FormContext from "./FormContext";
import Insurance from "./Insurance";
import CreditCard from "./CreditCard";

function Payment() {
  const context = useContext(FormContext);

  const creditCard = context.paymentType.value === "creditcard";
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h1>Payment</h1>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      {creditCard || <Insurance />}
      {creditCard && <CreditCard />}
    </>
  );
}

export default Payment;
