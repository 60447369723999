import React, { useMemo, useEffect, useState } from 'react';

import {
    EuiTitle,
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiHorizontalRule,
    EuiButtonEmpty,
} from '@elastic/eui';

import {
    Table,
    Popover,
    TextField,
    DateField,
    ComboBoxField,
    RefreshButton,
} from 'modules/commons/components';

import {
    useSort,
    useFormField,
    usePagination,
    useExportData,
    useLocations,
    useLocationTypes,
    useDebounce,
    useItemsTotal,
} from 'modules/commons/hooks';

import columns from './columns';
import useOrders from './useOrders';
import odataFilter from './odataFilter';

function locationExportParser(values) {
    return values.map(value => {
        const location = value?.location;

        let _value = {
            ...value,
            orgTestingCode: location?.orgTestingCode,
            schoolDbn: location?.schoolDbn,
            schoolName: location?.schoolName,
        };

        delete _value.location;

        return _value;
    });
}

function valuesFromFilters(filters = {}) {
    return {
        lastName: filters?.lastName?.value,
        firstName: filters?.firstName?.value,
        dob: filters?.dob?.value,

        firstDoseDate: filters?.firstDoseDate?.value,
        secondDoseDate: filters?.secondDoseDate?.value,
        scheduledSecondDoseDate: filters?.scheduledSecondDoseDate?.value,

        phone: filters?.phone?.value,
        locationId: filters?.locationName?.value?.id,
        locationType: filters?.locationType.value?.label,
    };
}

function MoreFiltersTitle(filters = {}) {

    const values = [
        filters?.phone?.value,
        filters?.locationName?.value?.id,
        filters?.locationType?.value.label,
    ].filter(value => value);

    const title = ["More filters"];

    if (values.length > 0) {
        title.push(`(${values.length})`);
    }

    return title.join(" ");
}

function Page() {

    const filters = {
        firstName: useFormField({
            title: 'First Name',
        }),
        lastName: useFormField({
            title: 'Last Name',
        }),
        dob: useFormField({
            title: 'Dob',
            placeholder: "MM/DD/YYYY",
        }),
        phone: useFormField({
            title: 'Phone',
        }),

        firstDoseDate: useFormField({
            title: 'First Dose Date',
            placeholder: "MM/DD/YYYY",
        }),
        secondDoseDate: useFormField({
            title: 'Second Dose Date',
            placeholder: "MM/DD/YYYY",
        }),
        scheduledSecondDoseDate: useFormField({
            title: 'Scheduled Second Dose Date',
            placeholder: "MM/DD/YYYY",
        }),

        locationName: useFormField({
            title: 'Location Name',
        }),
        locationType: useFormField({
            title: 'Location Type',
        }),
    };

    const {
        total: itemsTotal,
        isLoading: isItemsTotalLoading,
    } = useItemsTotal({ url: "/vaccineorder" });

    const { 
        options: locationsOptions, 
    } = useLocations();

    const {
        options: locationTypesOptions,
    } = useLocationTypes();

    const sort = useSort();

    const { 
        pagination, 
        onChange: onPaginationChange, 
    } = usePagination();

    const filtersValues = valuesFromFilters(filters);

    const debounceFilters = useDebounce({
        ...filtersValues,
        orderBy: sort.values
    }, 1000);

    const odata = odataFilter(debounceFilters);

    const {
        state,
        loadData,
    } = useOrders({
        pagination,
        odata
    });

    const {
        progress,
        isLoading: isExportDataLoading,
        exportData,
    } = useExportData({
        url: `/vaccineorder`,
        filename: `vaccines`,
        parser: locationExportParser,
        odata,
    });

    useEffect(() => {
        loadData({ pageIndex: 0, pageSize: 10 });
    }, [JSON.stringify(debounceFilters)]);

    return (
        <EuiPanel>
            <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={false}>
                    <EuiTitle>
                        <h1>Vaccines Reports</h1>
                    </EuiTitle>
                </EuiFlexItem>

                <EuiFlexItem />

                <EuiFlexItem grow={false}>
                    <p>{state.total} / {itemsTotal}</p>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule />
            <EuiFlexGroup alignItems="flexEnd">

                <EuiFlexItem grow={false}>
                    <TextField {...filters.firstName} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <TextField {...filters.lastName} />
                </EuiFlexItem>


                <EuiFlexItem grow={false}>
                    <DateField {...filters.dob} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <DateField {...filters.firstDoseDate} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <DateField {...filters.secondDoseDate} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <DateField {...filters.scheduledSecondDoseDate} />
                </EuiFlexItem>

                <EuiFlexItem>
                    <Popover
                        width="600px"
                        title={MoreFiltersTitle(filters)}
                        buttonTitle="Close"
                    >
                        <TextField {...filters.phone} />

                        <ComboBoxField
                            {...filters.locationName}
                            options={locationsOptions}
                        />

                        <ComboBoxField
                            {...filters.locationType}
                            options={locationTypesOptions}
                        />
                    </Popover>
                </EuiFlexItem>

                <EuiFlexItem />

                <EuiFlexItem grow={false}>
                    <EuiButton
                        aria-label="Search"
                        title="Search"
                        iconType="search"
                        onClick={() => loadData({ pageIndex: 0, pageSize: 10 })}
                        isLoading={state.isLoading}
                    >
                        <span>Search</span>
                    </EuiButton>
                </EuiFlexItem>

            </EuiFlexGroup>
            <EuiHorizontalRule />
            <Table
                state={state}
                sort={sort}
                columns={columns}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                additionalControls={
                    <>
                        <RefreshButton
                            isLoading={state.isLoading}
                            loadData={() => loadData(pagination)}
                        />
                        <EuiButtonEmpty
                            size="xs"
                            iconType="download"
                            onClick={exportData}
                            isLoading={isExportDataLoading}
                        >
                            <span>{isExportDataLoading ? progress : 'Export'}</span>
                        </EuiButtonEmpty>
                    </>
                }
            />
        </EuiPanel>
    );
}

export default Page;