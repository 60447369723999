import { useState, useCallback } from 'react';
import axios from 'utils/axios';
import * as odata from './odata';

const initState = {
    results: [],
    total: 0,
    page: 1,
    size: 10,
};

function useOrders(locationId, {
    onFailure = () => { },
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState({ ...initState });
    const [ordersTotal, setOrdersTotal] = useState(0);

    const fetchOrders = useCallback(async (query) => {
        try {
            setIsLoading(true);

            const response = await axios({
                method: 'get',
                url: `/batch/current/${locationId}/orders${query}`,
            });

            setOrders({ ...initState, ...response.data });
        } catch (error) {
            onFailure(error);
        } finally {
            setIsLoading(false);
        }

    }, [locationId]);

    const fetchOrdersTotal = useCallback(async () => {
        try {
            setIsLoading(true);

            const query = odata.ordersTotal(locationId);

            const response = await axios({
                method: '',
                url: `/order/open${query}`,
            });

            setOrdersTotal(response.data.total);
        } catch (error) {
            onFailure(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const addOrder = async (requisitionNumber) => {
        try {
            setIsLoading(true);

            const response = await axios({
                method: 'post',
                url: `/batch/current/${locationId}`,
                data: { requisitionNumber },
            });

            const _orders = { ...orders };
            _orders.results.push(response.data);
            _orders.total++;
            setOrders(_orders);
        } catch (error) {
            onFailure(error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        fetchOrders,
        fetchOrdersTotal,
        addOrder,
        orders,
        ordersTotal,
    }
}

export default useOrders;