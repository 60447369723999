import { useState } from "react";
import {
  EuiButton,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import SurveyJsQuestionnaireField from "./SurveyJsQuestionnaireField";

export default ({
  questionnaire,
  state = {},
  options = [],
  onChange = () => {},
} = {}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  let modal;
  const saveAndClose = (survey) => {
    onChange(survey);
    closeModal();
  };

  if (isModalVisible) {
    modal = (
      <EuiModal style={{ width: 800 }} onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Questionnaire</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <SurveyJsQuestionnaireField
            state={state}
            options={options}
            onChange={saveAndClose}
            questionnaire={questionnaire}></SurveyJsQuestionnaireField>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            Close and Discard
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <EuiFormRow
      fullWidth
      label={state.title}
      helpText={state?.error?.message || ""}>
      <div>
        <EuiButton
          fullWidth
          onClick={showModal}
          disabled={state?.value?.SignConsent}>
          {state?.value?.SignConsent
            ? "Questionnaire Completed"
            : "Fill out the Questionnaire"}
        </EuiButton>
        {modal}
      </div>
    </EuiFormRow>
  );
};
