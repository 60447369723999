import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiTitle,
} from "@elastic/eui";
import _isEmpty from "lodash/isEmpty";

import {
  Show,
  TextField,
  SurveyJsQuestionnaireField,
  TextareaField,
} from "modules/commons/components";

import useFormField from "modules/commons/hooks/useFormField";
import useAddMedicationRequest from "./hooks/useAddMedicationRequest";

import utils from "modules/commons/utils";
import { useCallback, useContext } from "react";
import { Context } from "modules/global";

function MedicationRequest({ order, request, onSuccess = () => {} }) {
  const context = useContext(Context);
  const settings = context.settings;

  const medicationName = useFormField({
    title: "Medication Name",
    value: request?.medicationName ?? "Paxlovid",
    disabled: true,
  });

  const questionnaireResponse = useFormField({
    title: "Questionnaire",
    value: request?.questionnaireResponse,
    validation: utils.validations.isNotEmpty,
  });

  const notes = useFormField({
    title: "Follow up Notes",
    value: request?.notes ?? "",
  });

  const [isLoading, submit] = useAddMedicationRequest(onSuccess);

  const submitForm = useCallback(
    async function (data) {
      questionnaireResponse.onChange(data);
      await submit({
        orderId: order.id,
        id: request?.id,
        medicationName: medicationName.state.value,
        questionnaireResponse: data,
        notes: notes.state.value,
      });
    },
    [order, request, notes, medicationName]
  );

  const submitNotes = useCallback(
    function () {
      submit({
        orderId: order.id,
        id: request?.id,
        notes: notes.state.value,
      });
    },
    [order, request, notes]
  );

  return (
    <Show when={order}>
      <EuiPanel>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>Medication Request</h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem>
            <TextField {...medicationName} />
          </EuiFlexItem>
        </EuiFlexGroup>

        <Show when={request != null}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <TextareaField {...notes} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiButton
                iconType="save"
                fill
                isLoading={isLoading}
                onClick={() => {
                  submitNotes();
                }}>
                Save notes
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Show>

        <EuiFlexGroup>
          <EuiFlexItem>
            <SurveyJsQuestionnaireField
              {...questionnaireResponse}
              onChange={(data) => {
                submitForm(data);
              }}
              questionnaire={
                settings?.PaxlovidQuestionnaire
              }></SurveyJsQuestionnaireField>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </Show>
  );
}

export default MedicationRequest;
