import React, { useState } from 'react';

import {
    EuiButtonEmpty,
    EuiContextMenuItem,
    EuiContextMenuPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPagination,
    EuiPopover,
} from '@elastic/eui';

function useIsOpen(value) {
    const [isOpen, setIsOpen] = useState(value);

    const close = () => setIsOpen(false);
    const toggle = () => setIsOpen(!isOpen);

    return {
        isOpen,
        close,
        toggle,
    };
}

function Pagination({
    pagination = {},
    totalItems = 0,
    onChange = () => { }
}) {
    const pageCount = Math.ceil(totalItems / pagination.pageSize);

    const { isOpen, toggle, close } = useIsOpen(false);

    const _onChange = ({ index, size }) => {
        const payload = {
            page: {
                index: index ?? pagination.pageIndex,
                size: size ?? pagination.pageSize,
            }
        };

        onChange(payload);
    };

    const onClickButton = (size) => {
        close();
        _onChange({ size, index: 0 });
    };

    const button = (
        <EuiButtonEmpty
            size="s"
            color="text"
            iconType="arrowDown"
            iconSide="right"
            onClick={toggle}>
            <span>Rows per page: {pagination.pageSize}</span>
        </EuiButtonEmpty>
    );

    const items = pagination.pageSizeOptions.map(size => {
        const icon = size === pagination.pageSize ? 'check' : 'empty'

        return (
            <EuiContextMenuItem
                key={`${size} rows`}
                icon={icon}
                onClick={() => onClickButton(size)}>
                <span>{size} rows</span>
            </EuiContextMenuItem>
        );
    });

    return (
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            <EuiFlexItem grow={false}>
                <EuiPopover
                    button={button}
                    isOpen={isOpen}
                    closePopover={close}
                    panelPaddingSize="none">
                    <EuiContextMenuPanel items={items} />
                </EuiPopover>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
                <EuiPagination
                    aria-label="Custom pagination example"
                    pageCount={pageCount}
                    activePage={pagination.pageIndex}
                    onPageClick={index => _onChange({ index })}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default Pagination;