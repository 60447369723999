import moment from "moment";
import OData from "utils/odataQuery";

function odataFilter({
  appointmentCode,
  appointmentTime,
  firstName,
  lastName,
  dob,
  phone,
  locationName,
  orderBy = [],
}) {
  const odata = (pagination) => {
    const odata = new OData();
    const query = odata
      .orderBy(orderBy)
      .pagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      })
      .equals({
        name: "appointmentCode",
        value: appointmentCode,
        type: "guid",
      })
      .contains({
        name: "patientFirstName",
        value: firstName,
      })
      .contains({
        name: "patientLastName",
        value: lastName,
      })
      .dateEquals({
        name: "patientDob",
        value: dob,
      })
      .dateBetween({
        name: "appointmentTime",
        dateFrom: !appointmentTime
          ? null
          : moment(appointmentTime).format("MM/DD/YYYY"),
        dateTo: !appointmentTime
          ? null
          : moment(appointmentTime).add(1, "days").format("MM/DD/YYYY"),
      })
      .contains({
        name: "guardianPhone",
        value: phone,
      })
      .equals({
        name: "testingLocation",
        value: locationName,
      })
      .build();

    return query;
  };

  return odata;
}

export default odataFilter;
