import TenantCardImage from "./TenantCardImage";

function ViewCardImage({ match }) {
  if (!match.params.url) return null;

  return (
    <TenantCardImage url={match.params.url} tenant={match.params.tenant} />
  );
}

export default ViewCardImage;
