import axios from "axios";
import { getCachedSettings } from "modules/commons/hooks/useSettings";
import { getToken } from "services/authentication";

const _axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

_axios.interceptors.request.use(
  (config) => {
    const tenant = getCachedSettings()?.tenant;

    if (tenant && !config.headers["tenant"]) {
      config.headers["tenant"] = tenant;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

_axios.interceptors.request.use(
  async function (config) {
    const token = await getToken();
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default _axios;
