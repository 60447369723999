import React, { useEffect, useMemo, useState } from 'react';

import { EuiComboBox, EuiFormRow } from '@elastic/eui';

import options from 'modules/commons/options';

const _options = options.insurances.map((label, id) => {
    return { id, label, };
});

function InsuranceSelect({
    state,
    onChange = () => { },
}) {

    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (!state?.value) { return; }
        const selected = _options.find(option => option?.label === state?.value);
        const selectedOptions = selected ? [selected] : [];
        setSelectedOptions(selectedOptions)
    }, [state?.value]);

    const _onChange = (options = []) => {
        setSelectedOptions(options);

        if (options?.length > 0) {
            const [option] = options;
            onChange(option?.label);
        } else {
            onChange('');
        }
    };

    return (
        <EuiFormRow
            fullWidth
            label={state?.title}
            helpText={state?.error?.message || ""}
        >
            <EuiComboBox
                fullWidth
                placeholder="Select Option"

                singleSelection={{ asPlainText: true }}
                options={_options}

                selectedOptions={selectedOptions}
                onChange={_onChange}
                isInvalid={!!state.error}
                isDisabled={state.disabled}
            />
        </EuiFormRow>
    );
}

export default InsuranceSelect;