import React, { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from "@elastic/eui";

import { TextField, SelectField } from "modules/commons/components";

import options from "modules/commons/options";

import FormContext from "./FormContext";

function Address() {
  const context = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Address</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.patient_address1} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.patient_address2} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.patient_city} />
        </EuiFlexItem>
        <EuiFlexItem>
          <SelectField {...context.patient_state} options={options.usaStates} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField {...context.patient_zip} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default Address;
