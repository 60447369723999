import { useCallback } from "react";
import useAxios from "modules/commons/hooks/useAxios";

function useAdministerVaccine(onSuccess = () => {}) {
  const [isLoading, fetch] = useAxios({
    onSuccess,
    onFailure: () => alert("Error while submitting the order"),
  });

  const submitOrder = useCallback(
    async ({
      requisitionNumber,
      lotNumber,
      expirationDate,
      dateGiven,
      administeringSite,
      dose,
      ndcCode,
      manufacturer,
      vaccinatorName,
    }) => {
      if (!requisitionNumber) return;

      await fetch({
        method: "POST",
        url: `/CompleteVaccineOrder`,
        data: {
          requisitionNumber,
          lotNumber,
          expirationDate,
          dateGiven,
          administeringSite,
          dose,
          ndcCode,
          manufacturer,
          vaccinatorName,
        },
      });
    },
    [onSuccess]
  );

  return { isLoading, submitOrder };
}

export default useAdministerVaccine;
