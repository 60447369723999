import { useEffect, useState } from "react";
import api from "networking";
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import { TextField } from "modules/commons/components";

function CreditCardPayment({ creditCardId }) {
  const [creditCard, setCreditCard] = useState();

  useEffect(() => {
    const loadData = async (id) => {
      const response = await api.CreditCard.get({
        id,
      });

      setCreditCard(response.data);
    };

    if (creditCardId) loadData(creditCardId);
    else setCreditCard(null);
  }, [creditCardId]);

  if (!creditCard) return null;

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField
            state={{
              title: "Card Name",
              value: creditCard.name,
              disabled: true,
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField
            state={{
              title: "Card Number Last 4",
              value: `**** **** ${creditCard.cardNumberLast4}`,
              disabled: true,
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField
            state={{
              title: "Card Expiration",
              value: creditCard.expiration,
              disabled: true,
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField
            state={{
              title: "Billing Zip Code",
              value: creditCard.zipCode,
              disabled: true,
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default CreditCardPayment;
