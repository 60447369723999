import React, { useEffect, useState } from 'react';

import {
    EuiPanel,
    EuiBasicTable,
    EuiTitle,
    EuiHorizontalRule,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
} from '@elastic/eui';

import {
    DateField,
    Pagination,
    SelectField,
    LocationSelect,
} from 'modules/commons/components';

import {
    useAxios,
    useFormField,
    useLocations,
    usePagination,
} from 'modules/commons/hooks';

import * as odata from './odata';
import columns from './columns';

const closedStatus = [
    { value: '', text: 'All' },
    { value: 'false', text: 'Open' },
    { value: 'true', text: 'Close' },
];

function useFilters(locationId) {
    const filters = {
        createdDate: useFormField({
            title: "Created Date",
            placeholder: "MM/DD/YYYY",
        }),
        closedDate: useFormField({
            title: "Closed Date",
            placeholder: "MM/DD/YYYY",
        }),
        closed: useFormField({
            title: 'Status',
        })
    };

    const query = odata.orders({
        locationId,
        createdDate: filters.createdDate.value,
        closedDate: filters.closedDate.value,
        closed: filters.closed.value,
        pageIndex: 0,
        pageSize: 10,
    });

    return [filters, query];
}

function Batch() {
    const [location, setLocation] = useState([]);

    const [orders, setOrders] = useState({
        results: [],
        total: 0,
        page: 1,
        size: 10,
    });

    const {
        locations,
        isLoading: isFetchLocationsLoading
    } = useLocations();

    const [filters, query] = useFilters(location.id);

    const {
        pagination,
        onChange: onPaginationChange,
    } = usePagination();

    const [isGetOrdersLoading, getOrders] = useAxios({
        onSuccess: (response) => {
            const data = response.data;
            setOrders(data);
        },
        onFailure: (error) => alert(error),
    });

    const getOrdesRequest = (query) => getOrders({
        method: 'get',
        url: `/batch${query}`,
    });

    useEffect(() => {
        getOrdesRequest(query);
    }, [query]);

    useEffect(() => {
        const query = odata.orders({
            locationId: location.id,
            createdDate: filters.createdDate.value,
            closedDate: filters.closedDate.value,
            closed: filters.closed.value,
            ...pagination,
        });

        getOrdesRequest(query);
    }, [pagination.pageIndex, pagination.pageSize]);

    const _columns = columns();

    return (
        <>
            <EuiPanel>
                <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiTitle>
                            <h1>Batch</h1>
                        </EuiTitle>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiHorizontalRule />

                <EuiFlexGroup alignItems="flexEnd">
                    <EuiFlexItem >
                        <LocationSelect
                            onChange={setLocation}
                            options={locations}
                            isLoading={isFetchLocationsLoading}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <DateField {...filters.createdDate} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <DateField {...filters.closedDate} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <SelectField {...filters.closed} options={closedStatus} />
                    </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer />

                <EuiHorizontalRule />

                <EuiBasicTable
                    loading={isGetOrdersLoading}
                    columns={_columns}
                    items={orders.results}
                />

                <EuiSpacer />

                <Pagination
                    totalItems={orders.total}
                    pagination={pagination}
                    onChange={onPaginationChange}
                />

            </EuiPanel>
        </>
    );
}

export default Batch;