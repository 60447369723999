import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CompatRoute } from "react-router-dom-v5-compat";

function PrivateRoute({
    component: Component,
    path,
    isAuthorized = true,
    ...rest
}) {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: path }
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path]);

    const render = props => {
        return isAuthenticated === true && isAuthorized === true
            ? <Component {...props} />
            : null;
    };

    return (
        <CompatRoute
            path={path}
            render={render}
            {...rest}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func
    ]).isRequired,
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    isAuthorized: PropTypes.oneOfType([
        PropTypes.bool,
    ])
};

export default withRouter(PrivateRoute);
