import { useState, useCallback } from 'react';
import axios from 'utils/axios';
import OData from 'utils/odataQuery';

function useBatch(batchId) {
    const [isLoading, setIsLoading] = useState(false);
    const [batch, setBatch] = useState({});
    const [error, setError] = useState(null);

    const fetchBatch = useCallback(async () => {

        try {
            setIsLoading(true);

            const query = odata();

            const response = await axios({
                method: 'get',
                url: `/batch${query}`,
            });

            const [batch] = response.data.results;
            setBatch(batch);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }

    }, []);

    const odata = () => {

        const odata = new OData();

        const query = odata
            .pagination({ pageSize: 1, pageIndex: 0 })
            .equals({
                name: 'id',
                value: +batchId,
            })
            .build();

        return query;
    };

    return {
        fetchBatch,
        error,
        isLoading,
        batch,
    }

}

export default useBatch;