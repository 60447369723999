import { useEffect, useState } from "react";
import api from "networking";
import { EuiImage } from "@elastic/eui";

function TenantCardImage({ url, tenant, size = "xl" }) {
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    const loadData = async (url, tenant) => {
      const response = await api.CardImage.getUrl({
        file: url,
        tenant: tenant,
      });

      setImageUrl(response.data.url);
    };

    if (url) loadData(url, tenant);
  }, [url, tenant]);

  if (!imageUrl) return null;

  return (
    <>
      <EuiImage allowFullScreen alt="Image" src={imageUrl} size={size} />
    </>
  );
}

export default TenantCardImage;
