import { DateTime } from "luxon";

function parse({ date = DateTime.now(), format = "MM/dd/yyyy", zone = "utc" }) {
  if (date == "0001-01-01T00:00:00Z") return "";

  var luxDate = DateTime.fromISO(date);

  if (!luxDate.isValid) {
    luxDate = DateTime.fromFormat(date, "MM/dd/yyyy");
  }

  return luxDate.setZone(zone).toFormat(format);
}

export default parse;
