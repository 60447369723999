import { useCallback, useState } from "react";
import _get from "lodash/get";

import useAxios from "modules/commons/hooks/useAxios";

import OData from "utils/odataQuery";

function useOrder(onSuccess = () => {}) {
  const [isLoading, fetch] = useAxios({
    onSuccess: (data) => {
      const _order = _get(data, "data.results[0]");
      onSuccess(_order);
    },
    onFailure: () => alert("Error while getting the order"),
  });

  const fetchOrder = useCallback((requisitionNumber) => {
    if (!requisitionNumber) return;

    const odata = new OData();

    const query = odata
      .equals({ name: "requisitionNumber", value: requisitionNumber })
      .build();

    fetch({
      url: `/order${query}`,
    });
  }, []);

  return { isLoading, fetchOrder };
}

export default useOrder;
