import OData from 'utils/odataQuery';

function odataFilters({
    locationId,
    firstName,
    lastName,
    firstDoseDate,
}) {

    const odata = ({
        pageIndex = 0,
        pageSize = 10,
    } = {}) => {
        const odata = new OData();

        const query = odata
            .pagination({
                pageIndex,
                pageSize,
            })
            .equals({
                name: 'location/Id',
                value: locationId,
            })
            .contains({
                name: 'firstName',
                value: firstName,
            })
            .contains({
                name: 'lastName',
                value: lastName,
            })
            .dateEquals({
                name: 'firstDoseDate',
                value: firstDoseDate,
            })
            .build();

        return query;
    };

    return odata;

}

export default odataFilters;