import React from "react";
import './FullPageLoader.css';

import { EuiLoadingSpinner } from '@elastic/eui';

const FullPageLoader = () => {
    return (
        <div className="full-page-container">
            <div className="full-page-loader">
                <EuiLoadingSpinner size="xl"/>
            </div>
        </div>
    );
};

export default FullPageLoader;