import axios from "utils/axios";

async function downloadFile({
  filename,
  url,
  accept,
  body = null,
  method = "GET",
}) {
  const response = await axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json",
      Accept: accept ?? undefined,
    },
    responseType: "blob",
    data: body,
  });

  const blob = response.data;
  const blobUrl = URL.createObjectURL(blob);

  const tempLink = document.createElement("a");
  tempLink.setAttribute("download", `${filename}`);
  tempLink.href = blobUrl;
  tempLink.click();
}

export default downloadFile;
