import { useReducer } from 'react';
import axios from 'utils/axios';

const initState = {
    items: [],
    itemsTotal: 0,
    isLoading: false,
    error: null,
};

function useOrders({ isBatch = false, locationId = "", odata = () => { } }) {
    const [state, dispatch] = useReducer(reducer, initState);
    const onFetch = () => dispatch({ type: 'fetch' });
    const onError = (payload) => dispatch({ type: 'error', payload });
    const onPopulate = (payload) => dispatch({ type: 'populate', payload });

    const loadData = async (pagination) => {
        if (!locationId) { return; }

        try {
            onFetch();

            let response;

            if (isBatch) {
                const query = odata.batch(pagination);
                response = await axios({
                    method: 'get',
                    url: `/batch/current/${locationId}/orders${query}`,
                });
            } else {
                const query = odata.open({ locationId, ...pagination });
                response = await axios({
                    method: 'get',
                    url: `/order${query}`,
                });
            }

            onPopulate(response.data);
        } catch (error) {
            onError(error);
        }
    };

    return {
        state,
        loadData,
    };
}

function reducer(state, action) {
    switch (action.type) {
        case "fetch":
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case "error":
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case "populate":
            return {
                ...state,
                items: action.payload.results,
                itemsTotal: action.payload.total,
                isLoading: false,
                error: null,
            };
        default:
            throw new Error();
    }
}

export default useOrders;