import OData from 'utils/odataQuery';

export function orders({
    pageIndex = 0,
    pageSize = 10,
    orderBy,
}) {

    const odata = new OData();

    const query = odata
        .pagination({
            pageIndex,
            pageSize,
        })
        .orderBy(orderBy)
        .build();

    return query;
}

export function ordersTotal(locationId) {

    const odata = new OData();

    const query = odata
        .pagination({
            pageIndex: 0,
            pageSize: 1,
        })
        .equals({
            name: 'Location/Id',
            value: +locationId,
        })
        .build();

    return query;
}

