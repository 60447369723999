import { useEffect, useReducer, useRef, useCallback } from "react";

import api from "networking";

import * as odata from "./odataFilters";

const initState = {
  items: [],
  itemsTotal: 0,
  isLoading: false,
  error: null,
};

function useRegistration({ pagination, filters }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const onFetch = () => dispatch({ type: "fetch" });
  const onError = (payload) => dispatch({ type: "error", payload });
  const onPopulate = (payload) => dispatch({ type: "populate", payload });

  const firstRef = useRef(true);

  const loadData = useCallback(
    async (pagination) => {
      try {
        onFetch();

        const params = odata.registration({
          ...filters,
          ...pagination,
        });

        const response = await api.Registration.fetchRegistrations(params);

        response.data.results = response.data.results.map((result) => {
          return {
            ...result,
            name: `${result.patientFirstName} ${result.patientLastName}`,
          };
        });

        onPopulate(response.data);
      } catch (error) {
        onError(error);
      }
    },
    [JSON.stringify(filters)]
  );

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
    } else {
      loadData({ pageIndex: 0, pageSize: 10 });
    }
  }, [loadData]);

  useEffect(() => {
    loadData(pagination);
  }, [pagination.pageIndex, pagination.pageSize]);

  return state;
}

function reducer(state, action) {
  switch (action.type) {
    case "fetch":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "error":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "populate":
      return {
        ...state,
        items: action.payload.results,
        itemsTotal: action.payload.total,
        isLoading: false,
        error: null,
      };
    default:
      throw new Error();
  }
}

export default useRegistration;
