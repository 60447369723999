import React, { useMemo, useEffect, useState } from "react";

import {
  EuiTitle,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiHorizontalRule,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiSpacer,
} from "@elastic/eui";

import {
  Table,
  Popover,
  TextField,
  DateField,
  ComboBoxField,
  RefreshButton,
} from "modules/commons/components";

import {
  useSort,
  useFormField,
  usePagination,
  useExportData,
  useLocations,
  useDebounce,
  useItemsTotal,
  useAuthorize,
} from "modules/commons/hooks";

import columns from "./columns";
import odataFilter from "./odataFilter";

import { FormProvider } from "modules/orders/form";

import dateOfBirthDateParser from "utils/dateOfBirthDateParser";
import usePreregistration from "./usePreregistration";
import moment from "moment";
import ViewAppointment from "./ViewAppointment";

function locationExportParser(values) {
  return values.map((value) => {
    let _value = {
      AppointmentTime: value.appointmentTime,
      LocationName: value.testingLocation,
      FirstName: value.patientFirstName,
      LastName: value.patientLirstName,
      Phone: value.guardianPhone,
      Email: value.guardianEmail,
      Address1: value.patientAddress1,
      Address2: value.patientAddress2,
      City: value.patientCity,
      State: value.patientState,
      Zip: value.patientZip,
      Dob: dateOfBirthDateParser({ date: value.patientDob }),
    };

    return _value;
  });
}

function valuesFromFilters(filters = {}) {
  return {
    dob: filters?.dob?.value,
    lastName: filters?.lastName?.value,
    firstName: filters?.firstName?.value,
    appointmentTime: filters?.appointmentTime.value,
    appointmentCode: filters?.appointmentCode?.value,
    phone: filters?.phone?.value,
    locationName: filters?.locationName?.value?.label,
  };
}

function MoreFiltersTitle(filters = {}) {
  const values = [
    filters?.phone?.value,
    filters?.locationName?.value?.id,
  ].filter((value) => value);

  const title = ["More filters"];

  if (values.length > 0) {
    title.push(`(${values.length})`);
  }

  return title.join(" ");
}

function Page() {
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const auth = useAuthorize();

  const filters = {
    appointmentCode: useFormField({
      title: "Appointment Code",
    }),
    firstName: useFormField({
      title: "First Name",
    }),
    lastName: useFormField({
      title: "Last Name",
    }),
    dob: useFormField({
      title: "Date Of Birth",
      placeholder: "MM/DD/YYYY",
    }),
    appointmentTime: useFormField({
      title: "Appointment Time",
      placeholder: "MM/DD/YYYY",
      value: moment().format("MM/DD/YYYY"),
    }),
    phone: useFormField({
      title: "Phone",
    }),
    locationName: useFormField({
      title: "Location Name",
    }),
  };

  const { total: itemsTotal } = useItemsTotal({
    url: "/preregistration",
  });

  const locationsState = useLocations();

  const sort = useSort([{ id: "appointmentTime", direction: "asc" }]);

  const { pagination, onChange: onPaginationChange } = usePagination();

  const filtersValues = valuesFromFilters(filters);

  const debounceFilters = useDebounce(
    {
      ...filtersValues,
      orderBy: sort.values,
    },
    1000
  );

  const odata = odataFilter(debounceFilters);

  const { state, loadData } = usePreregistration({
    pagination,
    odata,
  });

  const {
    progress,
    isLoading: isExportDataLoading,
    exportData,
  } = useExportData({
    url: `/preregistration`,
    filename: `appointments`,
    pageSize: 100,
    parser: locationExportParser,
    odata,
  });

  useEffect(() => {
    loadData({ pageIndex: 0, pageSize: 10 });
  }, [JSON.stringify(debounceFilters)]);

  const _locations = useMemo(() => {
    return locationsState.locations.map((location) => {
      return {
        id: location.id,
        label: `${location.orgTestingCode}`,
      };
    });
  }, [locationsState?.locations?.length]);

  return (
    <EuiPanel>
      {selectedAppointment != null && (
        <ViewAppointment
          locationId={1}
          appointment={selectedAppointment}
          onClose={() => setSelectedAppointment(null)}
        />
      )}

      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiTitle>
            <h1>Appointments</h1>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem />

        <EuiFlexItem grow={false}>
          <p>
            {state.total} / {itemsTotal}
          </p>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />

      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <ComboBoxField {...filters.locationName} options={_locations} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <DateField {...filters.appointmentTime} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem grow={false}>
          <TextField {...filters.appointmentCode} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <TextField {...filters.firstName} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <TextField {...filters.lastName} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <DateField {...filters.dob} />
        </EuiFlexItem>

        <EuiFlexItem>
          <Popover
            width="600px"
            title={MoreFiltersTitle(filters)}
            buttonTitle="Close">
            <TextField {...filters.phone} />
          </Popover>
        </EuiFlexItem>

        <EuiFlexItem />

        <EuiFlexItem grow={false}>
          <EuiButton
            aria-label="Search"
            title="Search"
            iconType="search"
            onClick={() => loadData({ pageIndex: 0, pageSize: 10 })}
            isLoading={state.isLoading}>
            <span>Search</span>
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />
      <Table
        state={state}
        sort={sort}
        columns={columns()}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        additionalControls={
          <>
            <RefreshButton
              isLoading={state.isLoading}
              loadData={() => loadData(pagination)}
            />

            {auth.isExportReports && (
              <EuiButtonEmpty
                size="xs"
                iconType="download"
                onClick={exportData}
                isLoading={isExportDataLoading}>
                <span>{isExportDataLoading ? progress : "Export"}</span>
              </EuiButtonEmpty>
            )}
          </>
        }
        leadingControlColumns={[
          {
            id: "View",
            width: 36,
            headerCellRender: () => null,
            rowCellRender: ({ rowIndex }) => (
              <EuiButtonIcon
                color="text"
                iconType="eye"
                iconSize="s"
                aria-label="View details"
                onClick={() => setSelectedAppointment(state?.items[rowIndex])}
              />
            ),
          },
        ]}
      />
    </EuiPanel>
  );
}

export default () => {
  return (
    <FormProvider>
      <Page />
    </FormProvider>
  );
};
