import React, { useEffect } from "react";

import { EuiStat, EuiFlexItem, EuiFlexGroup, EuiPanel } from "@elastic/eui";

import { useFetch } from "modules/commons/hooks";

export default () => {
  const { fetch, payload, isLoading } = useFetch({
    url: "/dashboard/gettests",
  });

  useEffect(() => {
    fetch();

    const id = setInterval(() => {
      fetch();
    }, 5000);

    return () => clearInterval(id);
  }, [fetch]);

  return (
    <div>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${payload.tests || 0}`}
              description="Tests"
              isLoading={isLoading}></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${payload.scanned || 0}`}
              description="Scanned"
              isLoading={isLoading}></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${payload.notScanned || 0}`}
              description="Not Scanned"
              isLoading={isLoading}></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${payload.submitted || 0}`}
              description="Submitted"
              isLoading={isLoading}></EuiStat>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};
