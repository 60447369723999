import { useContext } from "react";
import { Context } from "modules/global";

function ShowWhen({ when, children }) {
  const context = useContext(Context);
  const settings = context.settings;

  if (when(settings)) {
    return children;
  }

  return null;
}

export default ShowWhen;
