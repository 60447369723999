import { useCallback } from "react";
import _get from "lodash/get";

import useAxios from "modules/commons/hooks/useAxios";

function useMedicalRequests(onSuccess = () => {}) {
  const [isLoading, fetch] = useAxios({
    onSuccess: (data) => {
      onSuccess(data.data.requests);
    },
    onFailure: () => alert("Error while getting the Medical Requests"),
  });

  const fetchMedicalRequests = useCallback((orderId) => {
    if (!orderId) return;

    fetch({
      url: `/MedicationRequest/order/${orderId}`,
    });
  }, []);

  return [isLoading, fetchMedicalRequests];
}

export default useMedicalRequests;
