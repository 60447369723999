import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";

import {
  EuiBasicTable,
  EuiPanel,
  EuiTitle,
  EuiHorizontalRule,
  EuiFlexItem,
  EuiFlexGroup,
  EuiButton,
} from "@elastic/eui";

import { useParams } from "react-router-dom";

import {
  DateOfBirthLabel,
  Show,
  ShowWhen,
  DateField,
} from "modules/commons/components";

import {
  usePrintLabel,
  useAutoCloseMenu,
  useScreenSize,
} from "modules/commons/hooks";

import PrintAlert from "./PrintAlert";
import OrderDetail from "./OrderDetail";
import usePagination from "./usePagination";
import useAtHomeTestingTeam from "./useHomeTestingOrders";

const isPc = (width) => width > 768;

const getColumns = (loadData = () => {}) => {
  return [
    {
      field: "requisitionNumber",
      name: "Requisition Number",
      render: (_, order) => <OrderDetail order={order} onSuccess={loadData} />,
    },
    {
      field: "fullName",
      name: "Full Name",
      render: (_, order) => `${order.firstName} ${order.lastName}`,
    },
    {
      field: "dob",
      name: "Date of birth",
      render: (date) => <DateOfBirthLabel date={date} />,
    },
    {
      field: "address",
      name: "Address",
    },
  ];
};

const TeamOrders = () => {
  useAutoCloseMenu();

  const { id } = useParams();
  const [testTime, setTestTime] = useState(
    DateTime.now().toFormat("MM/dd/yyyy")
  );
  const { state, loadData } = useAtHomeTestingTeam({
    locationId: id,
    testTime,
  });
  const { pagination, onTableChange } = usePagination(state.itemsTotal);
  const { printLabels, isLoading, errors } = usePrintLabel();
  const size = useScreenSize();

  useEffect(() => {
    loadData(pagination);
  }, [pagination.pageIndex, pagination.pageSize, testTime]);

  const onClickPrintLabels = () => printLabels(state.items);

  return (
    <EuiPanel>
      <PrintAlert errors={errors} />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle>
            <h1>Orders</h1>
          </EuiTitle>
        </EuiFlexItem>
        <ShowWhen when={(settings) => settings.AHTChangeDate}>
          <EuiFlexItem grow={false}>
            <DateField
              state={{ value: testTime }}
              onChange={(x) => setTestTime(x)}
            />
          </EuiFlexItem>
        </ShowWhen>
        <Show when={isPc(size.width)}>
          <EuiFlexItem grow={false}>
            <EuiButton isLoading={isLoading} onClick={onClickPrintLabels}>
              <span>Print Labels</span>
            </EuiButton>
          </EuiFlexItem>
        </Show>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={loadData}>Refresh</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />

      <EuiBasicTable
        pagination={pagination}
        items={state.items}
        columns={getColumns(loadData)}
        onChange={onTableChange}
      />
    </EuiPanel>
  );
};

export default TeamOrders;
