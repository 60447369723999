import {
  EuiBasicTable,
  EuiButtonEmpty,
  EuiTitle,
  EuiPanel,
  EuiHorizontalRule,
} from "@elastic/eui";

import { useLocations, useAutoCloseMenu } from "modules/commons/hooks";

import { useHistory, useRouteMatch } from "react-router";

const Table = ({ locations }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const columns = [
    {
      field: "schoolName",
      name: "Name",
      render: (name, row) => {
        return (
          <EuiButtonEmpty onClick={() => history.push(`${url}/${row.id}`)}>
            <span>{name}</span>
          </EuiButtonEmpty>
        );
      },
    },
  ];

  return <EuiBasicTable items={locations} columns={columns} />;
};

const Teams = () => {
  const state = useLocations({ locationType: "AHT" });

  useAutoCloseMenu();

  if (state.isLoading) {
    return "Loading...";
  }

  return (
    <EuiPanel>
      <EuiTitle>
        <h1>Teams</h1>
      </EuiTitle>
      <EuiHorizontalRule />
      <Table locations={state.locations} />
    </EuiPanel>
  );
};

export default Teams;
