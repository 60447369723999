import React, { useMemo, useEffect, useState } from "react";

import {
  EuiTitle,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiHorizontalRule,
  EuiButtonEmpty,
  EuiButtonIcon,
} from "@elastic/eui";

import {
  Table,
  Popover,
  TextField,
  DateField,
  SelectField,
  ComboBoxField,
  RefreshButton,
  DateRangeField,
} from "modules/commons/components";

import {
  useSort,
  useFormField,
  usePagination,
  useExportData,
  useLocations,
  useLocationTypes,
  useDebounce,
  useItemsTotal,
  useAuthorize,
} from "modules/commons/hooks";

import columns from "./columns";
import useOrders from "./useOrders";
import odataFilter from "./odataFilter";
import testResults from "./testResults";

import { FormProvider } from "modules/orders/form";

import { OrderDetailModal } from "modules/orders/OrderModal";
import dateOfBirthDateParser from "utils/dateOfBirthDateParser";
import dateParser from "utils/dateParser";

const testTypes = [
  { value: "", text: "All" },
  { value: "PCR", text: "PCR" },
  { value: "Antigen", text: "Antigen" },
];

function formatDateWithTime(date) {
  return dateParser({ date, format: "MM/dd/yyyy h:mm a" });
}

function locationExportParser(values) {
  return values.map((value) => {
    const location = value?.location;

    let _value = {
      ...value,
      dob: dateOfBirthDateParser({ date: value.dob }),
      testTime: formatDateWithTime(value.testTime),
      testTime: formatDateWithTime(value.testTime),
      batchedClosed: formatDateWithTime(value.batchedClosed),
      labReceived: formatDateWithTime(value.labReceived),
      resultTime: formatDateWithTime(value.resultTime),
      orgTestingCode: location?.orgTestingCode,
      schoolDbn: location?.schoolDbn,
      schoolName: location?.schoolName,
    };

    delete _value.location;

    return _value;
  });
}

function valuesFromFilters(filters = {}) {
  return {
    dob: filters?.dob?.value,
    lastName: filters?.lastName?.value,
    firstName: filters?.firstName?.value,
    testResult: filters?.testResult?.value,
    requisitionNumber: filters?.requisitionNumber?.value,
    // testTime
    testTimeTo: filters?.testTimeTo?.value,
    testTimeFrom: filters?.testTimeFrom?.value,
    //
    phone: filters?.phone?.value,
    locationId: filters?.locationName?.value?.id,
    locationType: filters?.locationType.value?.label,
    batchedClosed: filters?.batchedClosed?.value,
    resultTime: filters?.resultTime?.value,

    testTypeName: filters?.testTypeName?.value,

    vaccineLotNumber: filters?.vaccineLotNumber?.value,
  };
}

function MoreFiltersTitle(filters = {}) {
  const values = [
    filters?.phone?.value,
    filters?.locationName?.value?.id,
    filters?.locationType?.value.label,
    filters?.batchedClosed?.value,
    filters?.resultTime?.value,
    filters?.vaccineLotNumber?.value,
  ].filter((value) => value);

  const title = ["More filters"];

  if (values.length > 0) {
    title.push(`(${values.length})`);
  }

  return title.join(" ");
}

function Page() {
  const [orderId, setOrderId] = useState("");

  const auth = useAuthorize();

  const filters = {
    requisitionNumber: useFormField({
      title: "Requisition Number",
    }),
    firstName: useFormField({
      title: "First Name",
    }),
    lastName: useFormField({
      title: "Last Name",
    }),
    dob: useFormField({
      title: "Date Of Birth",
      placeholder: "MM/DD/YYYY",
    }),
    testTimeFrom: useFormField({
      title: "Test Time From",
      placeholder: "MM/DD/YYYY",
    }),
    testTimeTo: useFormField({
      title: "Test Time to",
      placeholder: "MM/DD/YYYY",
    }),
    testResult: useFormField({
      title: "Test Result",
    }),
    testTime: useFormField({
      title: "Test Time",
      placeholder: "MM/DD/YYYY",
    }),
    phone: useFormField({
      title: "Phone",
    }),
    locationName: useFormField({
      title: "Location Name",
    }),
    locationType: useFormField({
      title: "Location Type",
    }),
    batchedClosed: useFormField({
      title: "Batched Close",
      placeholder: "MM/DD/YYYY",
    }),
    resultTime: useFormField({
      title: "Result Time",
      placeholder: "MM/DD/YYYY",
    }),
    testTypeName: useFormField({
      title: "Test Type",
    }),
    vaccineLotNumber: useFormField({
      title: "Vaccine Lot Number",
    }),
  };

  const { total: itemsTotal } = useItemsTotal({
    url: "/order",
  });

  const locationsState = useLocations();

  const locationTypesState = useLocationTypes();

  const sort = useSort();

  const { pagination, onChange: onPaginationChange } = usePagination();

  const filtersValues = valuesFromFilters(filters);

  const debounceFilters = useDebounce(
    {
      ...filtersValues,
      orderBy: sort.values,
    },
    1000
  );

  const odata = odataFilter(debounceFilters);

  const { state, loadData } = useOrders({
    pagination,
    odata,
    autoPageAfterLoad: true,
  });

  const {
    progress,
    isLoading: isExportDataLoading,
    exportData,
  } = useExportData({
    url: `/Order`,
    filename: `orders`,
    pageSize: 100,
    parser: locationExportParser,
    odata,
  });

  useEffect(() => {
    loadData({ pageIndex: 0, pageSize: 10 });
  }, [JSON.stringify(debounceFilters)]);

  const _locations = useMemo(() => {
    return locationsState.locations.map((location) => {
      return {
        id: location.id,
        label: `${location.schoolName} (${location.orgTestingCode})`,
      };
    });
  }, [locationsState?.locations?.length]);

  const _locationTypes = useMemo(() => {
    return locationTypesState.locationTypes.map((label, id) => {
      return {
        id,
        label,
      };
    });
  }, [locationTypesState?.locationTypes?.length]);

  return (
    <EuiPanel>
      {!!orderId && (
        <OrderDetailModal
          editing={true}
          isOpen={!!orderId}
          orderId={orderId}
          onClose={() => setOrderId("")}
        />
      )}

      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiTitle>
            <h1>Orders Reports</h1>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem />

        <EuiFlexItem grow={false}>
          <p>
            {state.total} / {itemsTotal}
          </p>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem grow={false}>
          <TextField {...filters.requisitionNumber} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <TextField {...filters.firstName} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <TextField {...filters.lastName} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <SelectField {...filters.testResult} options={testResults} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <DateField {...filters.dob} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <DateRangeField
            title="Test Time"
            startDate={filters.testTimeFrom}
            endDate={filters.testTimeTo}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <Popover
            width="600px"
            title={MoreFiltersTitle(filters)}
            buttonTitle="Close">
            <TextField {...filters.phone} />

            <ComboBoxField {...filters.locationName} options={_locations} />

            <ComboBoxField {...filters.locationType} options={_locationTypes} />

            <DateField {...filters.batchedClosed} />

            <DateField {...filters.resultTime} />

            <SelectField {...filters.testTypeName} options={testTypes} />

            <TextField {...filters.vaccineLotNumber} />
          </Popover>
        </EuiFlexItem>

        <EuiFlexItem />

        <EuiFlexItem grow={false}>
          <EuiButton
            aria-label="Search"
            title="Search"
            iconType="search"
            onClick={() => loadData({ pageIndex: 0, pageSize: 10 })}
            isLoading={state.isLoading}>
            <span>Search</span>
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />
      <Table
        state={state}
        sort={sort}
        columns={columns()}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        additionalControls={
          <>
            <RefreshButton
              isLoading={state.isLoading}
              loadData={() => loadData(pagination)}
            />

            {auth.isExportReports && (
              <EuiButtonEmpty
                size="xs"
                iconType="download"
                onClick={exportData}
                isLoading={isExportDataLoading}>
                <span>{isExportDataLoading ? progress : "Export"}</span>
              </EuiButtonEmpty>
            )}
          </>
        }
        leadingControlColumns={[
          {
            id: "View",
            width: 36,
            headerCellRender: () => null,
            rowCellRender: ({ rowIndex }) => (
              <EuiButtonIcon
                color="text"
                iconType="eye"
                iconSize="s"
                aria-label="View details"
                onClick={() => setOrderId(state?.items[rowIndex].id)}
              />
            ),
          },
        ]}
      />
    </EuiPanel>
  );
}

export default () => {
  return (
    <FormProvider>
      <Page />
    </FormProvider>
  );
};
