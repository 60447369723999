import React from 'react';

function Progress({
    isLoading = false,
    total = 0,
    current = 0
}) {

    const title = isLoading
        ? "Loading..."
        : `${current ?? 0} verified / ${total ?? 0} orders`;

    return (
        <span style={{ textAlign: "right" }}>
            {title}
        </span>
    )
}

export default Progress;