const dymo = window?.dymo?.label?.framework || {};

let defaultPrinter;

export async function printLabel(labelContent) {
  const printer = await getCachedPrinterName();

  await dymo.printLabelAsync(printer, "", labelContent, "");
}

export async function previewLabel(labelContent) {
  await dymo.renderLabelAsync(labelContent);
}

async function getCachedPrinterName() {
  if (!defaultPrinter) {
    defaultPrinter = await getPrinterName();
  }

  return defaultPrinter;
}

async function getPrinterName() {
  const printers = await dymo.getPrintersAsync();

  if (printers.length === 0) {
    throw new Error("no printer connected");
  }

  //const [printer] = printers.filter((printer) => printer.isConnected);
  const [printer] = printers;

  return printer.name;
}
