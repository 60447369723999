import { useEffect } from "react";
import { DateTime } from "luxon";

import useOrders from "modules/orders-reports/useOrders";
import odataFilter from "modules/orders-reports/odataFilter";
import { usePagination } from "modules/commons/hooks";

function useCheckSameDayOrdersForSamePatient({ patientInfo }) {
  const now = DateTime.now().setZone("America/New_York");
  const { pagination } = usePagination();
  const odata = odataFilter({
    exactFirstName: patientInfo?.firstName,
    exactLastName: patientInfo?.lastName,
    dob: patientInfo?.dob,
    voided: false,

    testTimeFrom: now.startOf("day").toFormat("MM/dd/yyyy"),
    testTimeTo: now.plus({ days: 1 }).toFormat("MM/dd/yyyy"),
  });

  const { state, loadData } = useOrders({
    pagination,
    odata,
  });

  useEffect(() => {
    if (
      patientInfo?.firstName?.length > 0 &&
      patientInfo?.lastName?.length > 0 &&
      patientInfo?.dob?.length > 4
    ) {
      loadData({ pageIndex: 0, pageSize: 10 });
    }
  }, [patientInfo]);

  return [state, state.items?.length > 0];
}

export default useCheckSameDayOrdersForSamePatient;
