import { useCallback, useReducer } from 'react';
import validate from './form/validate';
import axios from 'utils/axios';

const initState = {
    isLoading: false,
    error: null,
};

function useCreateVaccine({
    form,
    location,
    onSuccess = () => { }
}) {
    const [state, dispatch] = useReducer(reducer, initState);
    const onLoadingState = () => dispatch({ type: 'onLoading' });
    const onSuccessState = (payload) => dispatch({ type: 'onSuccess', payload });
    const onErrorState = (error) => dispatch({ type: 'onError', payload: error });

    const createVaccine = useCallback(async () => {

        try {
            onLoadingState();

            const validations = validate(form);

            if (validations?.length > 0) { throw new Error('Invalid form fields'); }

            const model = formToModel(form);
            const data = { ...model, location };

            const response = await axios({
                method: 'post',
                url: 'vaccineorder',
                data,
            });

            onSuccess(response.data);
            onSuccessState();
        } catch (error) {
            onErrorState(error);
            alert(error);
        }

    }, [JSON.stringify(form), location?.id]);

    return {
        state,
        createVaccine,
    };
}

function reducer(state, action) {
    switch (action.type) {
        case 'onLoading':
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case 'onSuccess':
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case 'onError':
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            throw new Error();
    }
}

function formToModel(form) {
    let model = {};

    for (let key of Object.keys(form)) {
        const field = form[key];
        model[key] = field.value;
    }

    return model;
}

export default useCreateVaccine;