import { useContext } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from "@elastic/eui";

import { TextField } from "modules/commons/components";

import FormContext from "./FormContext";
import { CardImage } from "modules/viewcardimage";

function Insurance() {
  const context = useContext(FormContext);

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>Insurance</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.insuranceProvider} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.insuranceMemberName} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField {...context.insuranceMemberNumber} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <TextField {...context.insuranceGroupNumber} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <EuiFlexGroup>
        <EuiFlexItem>
          <CardImage
            url={context.insuranceCardPictureFileName.value}
            size="l"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

export default Insurance;
