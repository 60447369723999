import { useCallback,  useState } from 'react';
import axios from 'utils/axios';

export function useAxios({ onSuccess, onFailure }) {
    const [isLoading, setIsLoading] = useState(false);

    const request = useCallback(async (config) => {

        try {
            setIsLoading(true);
            const response = await axios(config);
            onSuccess(response);
        } catch (error) {
            onFailure(error);
        } finally {
            setIsLoading(false);
        }

    }, [onSuccess, onFailure]);


    return [isLoading, request];
}

export default useAxios;