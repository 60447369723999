import { useEffect, useState } from "react";
import api from "networking";
import { EuiImage } from "@elastic/eui";
import { getCachedSettings } from "modules/commons/hooks/useSettings";

function CardImage({ url, tenant, size = "l" }) {
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    const loadData = async (url) => {
      const tenant = getCachedSettings()?.tenant;
      const response = await api.CardImage.getUrl({
        file: url.replace(`${tenant}/`, ""),
      });

      setImageUrl(response.data.url);
    };

    if (url) loadData(url);
    else setImageUrl(null);
  }, [url, tenant]);

  if (!imageUrl) return null;

  return (
    <>
      <EuiImage allowFullScreen alt="Image" src={imageUrl} size={size} />
    </>
  );
}

export default CardImage;
